@import '../../../../../styles/variables.less';

.profile-tag {
  width: auto;
  max-width: 100%;

  margin-right: 10px;
  margin-bottom: 10px;

  span {
    text-transform: uppercase;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    margin-left: 4px !important;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .delete-tag-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
    outline: none;
    border: none;
    background: none;
  }

  .special-tag {
    flex-shrink: 0;
  }
}

#pop-confirm-profile-tag {
  .ant-popover-message {
    > span {
      display: none;
    }

    .ant-popover-message-title {
      padding-left: 0px;
    }
  }

  .ant-popover-buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 5px;

    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-btn:not(.ant-btn-circle) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .ant-btn:first-of-type:hover {
      border-color: #d9d9d9;
      color: #828282;

      opacity: 0.6;
    }
  }
}
