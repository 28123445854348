@import '../../../../../styles/variables.less';

#custom-upload-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;

  margin-bottom: 30px;
  padding: 15px !important;

  border-radius: 8px;
  border: 1px dashed #bdbdbd !important;
  background-color: transparent;

  &.document-send-btn {
    border: 1px solid @primary-color !important;
    background-color: @inverse-primary-color;

    .custom-upload-btn-icon {
      background-color: @primary-color;
    }
  }

  &.document-resend-btn {
    border: 1px solid @danger-color !important;
    background-color: @inverse-danger-color;

    .custom-upload-btn-icon {
      background-color: @danger-color;
    }

    strong {
      color: @danger-color;
    }
  }

  .custom-upload-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;

    width: 55px;
    height: 55px;
    margin-right: 18px;

    border-radius: 50%;
    background-color: #f2f2f2;
  }

  span {
    font-size: 15px;
    line-height: 20px;

    text-align: start;
    color: @text-dark;

    white-space: normal;
    word-break: break-word;

    strong {
      color: @primary-color;
    }
  }

  i {
    color: #828282;
    font-size: 45px;

    &.caf-ic_processing_line {
      color: #ffffff;
      font-size: 38px;
    }
  }
}
