@import '../../../../../../../../../../styles/variables.less';

.address-information {
  button {
    cursor: pointer;
    transition: filter 0.2s ease-in-out;

    &:hover {
      filter: brightness(0.8);
    }
  }
}

.address-type-tag {
  width: 90px;
  padding: 2.5px;
  border-radius: 12px;
  border: 1.5px solid @primary-color;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  small {
    font-weight: bold;
    color: @primary-color;
    font-size: 12px;
  }
}
