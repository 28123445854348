@import '../../../../../../../styles/variables.less';

#card-people-component {
  .ant-card {
    margin-bottom: 14px;
    overflow: hidden;

    .ant-card-body {
      padding: 0 !important;
      position: relative;
    }
  }

  .loading-tags-container {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 5px;

    .ant-spin {
      margin: 0px;
    }
  }

  &.document-card {
    &:hover {
      > div {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
        border-color: #fff !important;
      }
    }
  }

  .container-card-body {
    display: flex;
    align-items: center;
  }

  .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
    padding: 20px;

    .card-body-infos {
      width: 100%;

      .profile-template-name {
        color: @gray-color-1;
        text-transform: uppercase;
      }

      > div p {
        width: fit-content;
        word-break: break-word;
      }
    }
  }

  .card-image-profile {
    display: flex;
    width: 110px;
    height: 100%;
    min-height: 110px;

    overflow: hidden;

    -o-object-fit: cover;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .card-title {
    margin-bottom: 4px;
    padding-right: 10px;

    flex: 1;

    color: @text-dark;
    line-height: 1.4;

    font-size: 18px;
    font-weight: 700;

    text-transform: uppercase;
  }

  .card-infos {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .card-info {
    font-size: 13px;
    color: @text-dark;
    line-height: 1;

    text-overflow: ellipsis;

    display: flex;
    align-items: center;
    flex-shrink: 0;

    margin-right: 10px;

    i {
      font-size: 16px;
      height: 18px;
      color: @gray-color-2;
      margin-right: 5px;
    }
  }

  .card-info-status {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 250px;

    @media screen and (min-width: 992px) {
      width: 210px !important;
    }

    @media screen and (min-width: 1200px) {
      width: 250px !important;
    }

    .card-info-status-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin-left: 15px;

      strong {
        color: @text-dark;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 2px;
      }
    }

    .copy-link-button {
      font-size: 12px;
      max-height: 14px;
      min-height: 14px;
      padding-left: 0;
      margin-left: 0;
      padding-right: 0;
      outline: 0;
      border: 0;
      background-color: transparent;
      color: #2e70f0;
      cursor: pointer !important;
      font-weight: 500;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .card-tags-wrapper {
    border-top: 1px solid @border-color;
  }

  #card-tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .profile-tag {
      margin-bottom: 0 !important;
    }
  }

  .card-footer {
    display: flex;
    position: relative;
    padding: 3px 20px;
    background-color: transparent;
    border-radius: 0 !important;
    border-top: 1px solid @border-color !important;
    justify-content: space-between;
    align-items: center;

    span {
      line-height: 1.2;
    }

    .separator {
      font-style: normal;
      margin: 0 4px;
      line-height: 1;
      color: @text-color-secondary;
    }

    .btn-open {
      border-radius: 50%;
      height: 26px;
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      margin-left: 15px;

      i {
        color: @text-color-secondary;
      }

      &:hover {
        transform: scale(1.3);
        cursor: pointer;
        i {
          color: @text-color;
        }
      }
    }
  }
}
