.users-table-container {
  margin-top: 1rem;
  width: 100%;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .button-table-eye {
    border: none;

    span {
      margin-left: 0;
    }
  }

  th {
    border-bottom: 1px solid #dddddd;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
  }
}
