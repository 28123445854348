@import '../../../../../../styles/variables.less';

#profile-model-subheader {
  .profile-model-name {
    color: @header-text-color;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 2px;
    padding: 0;
    width: fit-content;
    word-break: break-word;
  }

  .btn-recurrence-cancel {
    color: @primary-color;
    background: transparent;
    border-color: @primary-color;
  }

  .container-switch {
    margin-right: 30px;
    height: 36;
  }
  .content-switch {
    border-right: 1px solid gray;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .mrg-row-btm {
    margin-bottom: 6px;
  }
  .tag-type {
    font-weight: bold;
    color: @primary-color;
    background-color: @inverse-primary-color;
  }
  .ant-breadcrumb-separator {
    color: @text-dark;
  }
  .breadcrumbs-color {
    color: @text-dark;
  }
}
