@import '../../../../../../../styles/variables.less';

.kyb-company-riskfactors-main {
  .KYB-tab-title {
    margin-bottom: 32px;
  }
}
.kyb-company-riskfactors-title {
  text-transform: capitalize;
}
.kyb-company-riskfactors-content,
.kyb-company-riskfactors-level,
.kyb-company-riskfactors-group,
.kyb-company-riskfactors-card {
  display: flex;
}
.kyb-company-riskfactors-content {
  flex-direction: column;
  gap: 32px;
}
.kyb-company-riskfactors-level {
  flex-direction: column;
  gap: 32px;
}
.kyb-company-riskfactors-group {
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}
.kyb-company-riskfactors-card,
.kyb-company-riskfactors-group {
  width: 100%;
}
.kyb-company-riskfactors-card {
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid @color-neutral-400;
  .kyb-company-summary-tag {
    text-transform: capitalize;
  }
}
@media (min-width: @screen-lg-min) {
  .kyb-company-riskfactors-group {
    flex-direction: row;
    > div {
      flex: 1;
      &:first-child {
        flex: 3;
      }
      &:last-child {
        flex: 2;
      }
    }
  }
}

.kyb-company-sanctions-row:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.kyb-company-sanctions-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 12px;
  padding: 10px 0;
}

.kyb-company-sanctions-column {
  display: flex;
  flex-direction: column;
}

.kyb-company-column-title {
  font-weight: bold;
  color: #000;
  margin-bottom: 4px;
}

.kyb-company-sanctions-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: #000;
}

.kyb-company-column-value {
  color: #000;
}
