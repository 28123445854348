@import '../../../../../styles/variables.less';

#profile-global-subheader {
  .gx-header-horizontal-top {
    padding: 0;
  }

  .custom-horizontal-pdd {
    padding-top: 0px;

    &.retract {
      padding-bottom: 20px;
    }
  }

  .profile-subheader-content {
    display: flex;
    align-items: center;
  }

  .profile-img {
    border-radius: 8px;

    object-fit: cover;
    width: 130px;
    height: 130px;
  }

  .breadcrumbs-color {
    color: @text-dark;
  }

  .ant-breadcrumb-separator {
    color: @text-dark;
  }

  .profile-info {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    transition: all 0.2s ease-in-out;
  }

  .profile-name {
    color: @text-dark;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 2px;

    width: fit-content;
    word-break: break-word;
  }

  .profile-more-infos {
    display: flex;
    flex-direction: row;
    color: @text-color;
    font-weight: 400;
    align-items: center;
  }
  .actions-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @text-dark !important;

    padding: 0px 20px !important;

    background: transparent;
    border: 1px solid rgba(@text-dark, 0.8);

    strong {
      color: @text-dark;
      font-weight: 500;
      font-size: 15px;
    }

    &:hover,
    &:focus {
      border-color: @primary-color;
      background: rgba(@text-dark, 0.05);
    }

    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active,
    &.ant-btn::before {
      background: transparent !important;
    }

    &.ant-btn-loading {
      border: none;
      padding: 0px 25px !important;

      .ant-btn-loading-icon {
        color: @text-dark;

        .anticon {
          margin-top: 3px;
          font-size: 25px;
        }
      }
    }
  }
}

#actions-dropdown-menu-content {
  .ant-divider {
    width: 82%;
    min-width: 82%;
    margin: 7px auto;
  }
}
