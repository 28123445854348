@import '../../../../../../../styles/variables.less';

.parent-execution {
  margin-left: 10px;

  .icon {
    position: relative;
    top: 2px;
    margin-right: 3px;
    font-size: 16px;
  }

  .company-name {
    font-weight: bold;
    text-decoration: underline;
  }

  .loading-icon {
    display: inline-block;
  }
}
