@import '../../../../../styles/variables.less';

#confirm-data-zoop {
  .confirm-data-cards {
    background-color: #f8bd19;
    display: flex;
    flex-direction: column;

    width: 100%;

    border-radius: 6px;
    cursor: pointer;
    padding: 10px 20px;
    margin-bottom:20px;
  }

  .confirm-data-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .confirm-data-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #fff;

    strong {
      display: block;
      font-weight: 500;
      font-size: 14px;
    }

    ol {
      padding-left: 15px;
      padding-top: 4px;

      li {
        font-size: 12px;
      }
    }
  }
}