@import '../../../../../styles/variables.less';

#executions-relation-and-phone-card {
  display: flex;
  // align-items: center;

  .relation-and-phone-card-info {
    display: flex;
    flex-direction: column;

    color: @text-dark;

    strong {
      font-size: 15px;
    }

    span {
      display: block;
      margin-top: 2px;
    }
  }

  .custom-relation {
    margin-left: 5px;

    .custom-title {
      width: fit-content;
      word-break: break-word;
    }
  }
}
