@import '../../../../../../styles/variables.less';

.execution-confirm-data-modal {
  .confirm-data-title {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .confirm-data-description {
    display: inline-block;

    color: @text-color;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;

    strong {
      color: @gray-color-1;
    }
  }

  .confirm-data-btns {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-top: 30px;
  }
}
