@import '../../../../../../styles/variables.less';

#credit-score-pj {
  h4 {
    font-weight: 600;
  }
  h3 {
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  h1 {
    font-weight: 600;
    font-size: 50px;
    padding: 0px;
    margin: 0px;
  }
}
.good-tag {
  background-color: @color-approved-light;
  color: @color-approved-main;
}
.regular-tag {
  background-color: @color-pending-light;
  color: @color-pending-main;
}
.bad-tag {
  background-color: @color-warning-main;
  color: @color-warning-main;
}
