@import '../../../../styles/variables.less';

.wrapper-page {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.wrapper-page-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-bottom: 60px;
  max-width: 100%;

  .ant-tabs .ant-tabs-content-holder {
    margin-top: 30px;
  }
}

.card-content {
  width: 100%;
  min-height: 279px;
  padding: 25px 25px;
  background-color: @secondary-color;
  border: 1px solid @gray-color-3;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.247) !important;

  .ant-col-24 {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.wrapper-page-2 {
  min-width: 310px;
  max-width: 400px;
  height: max-content;
  margin-left: 2%;
}
