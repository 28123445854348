@import '../../../../../styles/variables.less';

#profile-model-create-success-component {
  display: flex;
  flex: 1;

  .page-title {
    .title {
      margin-bottom: 15px;
    }
  }

  .link-btn {
    text-shadow: none;

    &:hover,
    &:active,
    &:focus {
      color: @info-color;

      span {
        text-decoration: underline;
      }
    }
  }

  img {
    width: 75%;
    min-width: 250px;
  }
}
