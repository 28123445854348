@import '../../../../../../../../../styles/variables.less';

.company-link-tree-table {
  padding: 20px;
  margin: 40px 0px;
  border: 1px solid @gray-color-3;
  border-radius: 8px;

  button {
    background: none;
    border: none;
  }

  .nested {
    display: none;
  }

  .active {
    display: block;
  }

  .remove-padding {
    padding: 0;
  }

  .company-link-tree-table-header {
    margin-bottom: 30px !important;
    font-weight: 700;
    color: @text-dark;
    text-align: left;
  }

  .row {
    padding: 20px 0;
    border-bottom: 0.5px solid #d9d9d9;
    color: @text-dark;
    line-height: 1.5em;

    .ant-row {
      align-items: center;
    }

    .folder {
      margin-right: 10px;
      display: none;

      &.active {
        display: inline-block;
      }
    }

    .company-name {
      color: @primary-color;
      cursor: pointer;
      text-align: left;
      display: flex;
      margin: auto;

      div {
        margin: auto;
      }
    }
  }
}
