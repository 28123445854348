@import '../../../../../../../styles/variables.less';

#to-print-contacts {
  #basic-infos-card,
  #basic-infos-contacts {
    .basic-infos-content {
      .contact-item {
        margin-right: 15px;

        color: @text-dark;
        font-size: 12px;

        i {
          font-size: 13px;
        }
      }

      .ant-divider-horizontal {
        margin-top: 20px !important;
      }

      .contact-section-title {
        margin-top: 0px;
        font-size: 13px;
      }
    }
  }
}
