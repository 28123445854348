@import '../../../../../styles/variables.less';

#profiles-list-component {
  .ant-col-button {
    padding: 0 10px;
  }

  .button-new-register {
    display: flex;
    align-items: center;
    margin-right: 20px !important;

    color: #ffffff;

    border: none;
    background: @primary-color;

    i {
      margin-right: 5px;
    }
  }

  .button-new-register:hover {
    opacity: 0.9;
  }

  .export-list-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background: transparent;

    transition: color 0.2s ease-in-out;

    i {
      font-size: 28px;
      margin-left: 1.25px;

      color: @gray-color-1;
    }

    &:hover,
    &.ant-dropdown-open {
      i {
        color: @primary-color;
      }
    }

    &.danger {
      i {
        color: @danger-color;
      }
    }

    .ant-btn-loading-icon {
      color: @primary-color;
    }
  }
}

.menu-export-list {
  padding: 4px 0px;

  .ant-menu-item {
    padding: 0px !important;
  }

  .ant-dropdown-menu-item {
    margin: 2px 0px;
    padding: 0px !important;
  }

  .menu-export-list-btn {
    display: flex;
    align-items: center;

    width: 100%;
    padding: 0px 20px 0px 12px;

    cursor: pointer;

    i {
      font-size: 24px;
      margin-right: 8px !important;
    }

    span {
      font-weight: 500;
    }
  }
}

.menu-export-list-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.invalid-document-field {
  display: block;
  color: @danger-color;
  font-size: 12px;

  &.custom-top {
    margin-top: -15px;
  }
}
