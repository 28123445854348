@import '../../../../../../styles/variables.less';

.KYB-execution-card-status {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  color: @white-color;
  margin-bottom: 20px;

  .status-loading-overlay {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    span {
      font-size: 24px;
      color: white;
    }
  }
}

.execution-card-status-wrapper-infos {
  display: flex;
  align-items: center;
  padding: 25px 20px;

  .execution-card-status-description {
    font-weight: bold;
    font-size: 16px;
    margin-left: 15px;
  }
}

.execution-card-status-wrapper-action {
  display: flex;
  align-items: center;
  border-top: 1px solid @white-color;

  .action-execution-status-button {
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    background: none;
    flex: 1;
    padding: 15px 0;
    border: none;
    transition: 0.3s ease-out;
    height: 100%;

    &.approve {
      border-right: 1px solid @white-color;
      &:hover {
        background: @success-color;
        border-bottom-left-radius: 8px;
      }
    }

    &.reprove {
      &:hover {
        background: @danger-color;
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.execution-card-status-approved {
  background-color: @success-color;
}

.execution-card-status-reproved {
  background-color: @danger-color;
}

.execution-card-status-pending {
  background-color: @warning-color;
}
