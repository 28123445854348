#to-print-legal-information {
  .dossier-card-container {
    .dossier-card-content {
      #legal-information-processes-container {
        .legal-information-processes-title {
          padding-bottom: 10px;

          strong {
            font-size: 12px;
          }
        }

        .legal-information-processes-btn {
          padding: 15px 0px;

          .processes-row-data {
            strong {
              font-size: 11.5px;
            }
          }

          .processes-row-details {
            margin-top: 10px;

            .process-data-detail {
              strong {
                font-size: 11.5px;
              }

              span {
                font-size: 11px;
              }
            }
          }

          .processes-row-tags {
            margin-top: 10px;

            #custom-process-tag {
              padding: 0px 5px;

              font-size: 9px;
              line-height: 20px;
            }
          }
        }

        .legal-information-processes-row {
          .legal-information-processes-data {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
