#public-wrapper-component {
  .right-panel {
    background-image: linear-gradient(180deg, #000000 30%, rgba(0, 0, 0, 0.0001) 80%),
    url('../../assets/images/login-background.png');
  }

  .ant-space-item .links-with-divider {
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: -10px !important;
  }
}
