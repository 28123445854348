@import '../../../styles/variables.less';

.image-card-container {
  position: relative;
  display: inline-block;

  & + .image-card-container {
    margin-left: 20px;
  }

  .image {
    max-height: 300px;
    margin-bottom: 20px;

    border-radius: 10px;
  }

  &:hover .content-wrapper {
    visibility: visible;
    opacity: 1;
  }

  .content-wrapper {
    position: absolute;
    top: 0;
    bottom: 20px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 8%,
      rgba(255, 255, 255, 0) 100%
    );
    color: @white-color;
    visibility: hidden;
    opacity: 0;
    border-radius: 10px;
    padding: 10px;

    transition: opacity 0.2s, visibility 0.2s;

    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .content-wrapper-nohover {
    p {
      font-size: 12px;
      color: #fff;
      letter-spacing: 0;
      padding: 2px 12px;
      background: rgba(0, 0, 0, 0.6);
      border-bottom-right-radius: 8px;
      top: 0;
      left: 0;
      position: absolute;
    }
  }
}
