@import '../../../../../../../../../../../styles/variables.less';
#validations-list {
  .rule-title {
    font-size: 15px;
    word-wrap: break-word;
    color: @text-dark;
  }
  .tag-background-profiles {
    background: #004af733;
    color: #00257a;
    font-weight: 400 !important;
    font-size: 13.5px;
    padding: 3px 10px 3px 5px;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .compliance-rules-status-profiles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    padding: 0px;

    & + .compliance-rules-status {
      border-top: 1px solid @border-color;
    }
  }
  .line {
    flex: 1;
    height: 1px;
    background-color: @border-color;
    margin-left: 10px;
    margin-right: 10px;
  }
}
