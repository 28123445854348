#countdown-to-update {
  font-size: 12px;

  .ant-statistic {
    display: inline;

    .ant-statistic-content {
      display: inline;

      .ant-statistic-content-value {
        color: white;
        font-size: 12px;
        display: inline;
      }
    }
  }
}
