@import '../../../../../../styles/variables.less';

.execution-pending-status-modal {
  .pending-status-title {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .pending-status-description {
    display: inline-block;

    color: @text-color;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;

    strong {
      color: @gray-color-1;
    }
  }

  .pending-status-btns {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-top: 30px;
  }

  .disapprove-report-form {
    margin-top: 30px;

    .ant-form-item {
      margin: 0px !important;

      .ant-input {
        min-height: 100px;

        &:focus,
        &:hover {
          border-color: @gray-color-1 !important;
          box-shadow: none !important;
        }
      }
    }
  }
}
