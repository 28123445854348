@import '../../../styles/variables.less';

.quick-access__container {
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 0px 20px 0px #00000029;

  padding: 1.5rem;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.quick-access__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: @text-dark;
  margin: 0px;
}

.iframe {
  width: 100%;
  height: 98%;
  border: none;
}
