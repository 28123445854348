@import '../../../../../../../styles/variables.less';
#sports-and-related-card {
  display: flex;
  // align-items: center;

  .sports-and-related-card-info {
    display: flex;
    flex-direction: column;

    color: @text-dark;

    strong {
      font-size: 15px;
    }

    span {
      display: block;
      margin-top: 2px;
    }
  }
}
