@import '../../../../../../../styles/variables.less';

.KYB-people {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .select-sources {
    margin-top: 27px;
    display: flex;
    width: 100%;
    flex-direction: column;

    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: @text-dark;
    }

    button {
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      padding: 10px 13px;
      gap: 10px;
      margin-right: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
    }

    .wrapper-button-sources {
      margin-top: 13px;

      .source-selected {
        background: rgba(0, 74, 247, 0.1);
        border: 1px solid @primary-color;
        border-radius: 4px;
        color: @primary-color;
      }

      .source-unselected {
        background: #f3f3f3;
        border: 1px solid @gray-color-1;
        border-radius: 4px;
      }
    }
  }
}

.KYB-people-sources {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 0px;
    right: -93%;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    padding-left: 0;
  }

  .ant-row {
    margin: 0px !important;
    padding: 0px !important;
  }

  .wrapper-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 0px !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: @text-dark;
    }
  }

  .columns {
    margin-top: 45px !important;

    strong {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: @text-dark;
    }
  }

  .header-panel {
    margin-left: 20px;
    height: 88px;
    display: flex;
    align-items: center;

    .name-style {
      font-size: 12px;
      line-height: 14px;
      color: @primary-color;
      height: 100%;
      align-self: center;
      width: 100%;
    }

    .position-style {
      font-size: 12px;
      line-height: 14px;
      color: @text-dark;
    }

    .birth-date-style {
      font-size: 12px;
      line-height: 14px;
      color: @text-dark;
    }

    .as-of-style {
      font-size: 12px;
      line-height: 14px;
      color: @text-dark;
    }

    .wrapper-identifiers {
      display: flex;
      flex-direction: column;
      width: 100%;

      .identifier-style {
        font-size: 12px;
        line-height: 14px;
        color: @text-dark;
        margin-bottom: 5px;
      }
    }
  }

  .wrapper-content {
    .ant-col-12 {
      padding: 0;
      margin: 0;
    }

    a {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 30px;

      span {
        line-height: 14px;
        letter-spacing: 0.5px;
        color: @primary-color;
        width: 100%;
      }

      img {
        margin-right: 10px;
      }
    }

    .ant-col-24 {
      padding: 0;
      margin: 0;
    }

    .ant-col-6 {
      padding: 0;
      margin: 0;
    }

    .side-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: @text-dark;
      margin-bottom: 18px;
    }

    .description {
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.5px;
      color: @text-dark;
      margin-bottom: 8px;
    }

    .details {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      color: @text-dark;
      margin-bottom: 8px;
    }
  }

  .current-tab-infos {
    display: flex;
    width: 100%;
    margin-top: 30px;

    h4 {
      margin: 0;
      padding: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
      color: @text-dark;
    }
  }

  .previous-director-list {
    width: 100%;
    border: 1px solid @gray-color-2;
    border-radius: 8px;
    margin-top: 20px;
    padding: 15px 8px;

    .ant-col-9 {
      padding: 0;
      margin: 0;
    }

    .ant-col-4 {
      padding: 0;
      margin: 0;
    }

    .ant-col-5 {
      padding: 0;
      margin: 0;
    }

    .ant-col-1 {
      padding: 0;
      margin: 0;
      max-width: 25px;
    }

    .previous-director-column {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: @text-dark;
    }

    .current {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #39c560;
    }

    .previous {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #bdbdbd;
    }

    .dissolved {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #e21b45;
    }

    .line {
      border-bottom: 1px solid @gray-color-2;
      padding: 17px 0px !important ;

      .director-name {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: @primary-color;
      }

      .director-infos {
        font-size: 12px;
        line-height: 14px;
        color: #253132;
      }
    }
  }

  .credit-safe-shareholders {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-col-4 {
      padding: 0;
      margin: 0;

      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      color: @text-dark;
    }
  }

  .wrapper-person-control-cards {
    display: flex;
    width: 100%;
    margin-top: 33px;
    align-items: center;
    gap: 35px;
    margin-bottom: 35px;

    .card-person-control {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      min-height: 112px;
      max-width: 208px;
      width: 100%;
      border: 1px solid #bdbdbd;
      border-radius: 8px;
      padding: 14px;
      text-align: center;

      strong {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: @text-dark;
      }

      .wrapper-status {
        margin-top: 15px;
        min-height: 40px;
        display: flex;
        gap: 10px;

        .content-status {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 11px;
            line-height: 12px;
            color: @text-dark;
          }

          .group-content-and-status {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 5px;

            .active-status {
              height: 10px;
              width: 10px;
              background: #39c560;
              border-radius: 100%;
              margin-right: 5px;
            }

            .active-ceased {
              height: 10px;
              width: 10px;
              background: #e21b45;
              border-radius: 100%;
              margin-right: 5px;
            }

            .active-total {
              height: 10px;
              width: 10px;
              background: #bdbdbd;
              border-radius: 100%;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .announces {
    width: 100%;
    margin-top: 40px !important;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: @text-dark;

    .ant-col-24 {
      padding: 0;
      margin: 0;
    }

    strong {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
