@import '../../../styles/variables.less';

.header-adornment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -1rem;
}

.content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0px 2rem 2rem;
  align-items: flex-start;

  .flow-buttons-container {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: -1.4rem;
    z-index: 4;
  }

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h5 {
        font-weight: 800;
        font-size: 18px;
        color: @text-dark;
        position: relative;

        span {
          position: absolute;
          right: -20px;
          top: 13px;
        }
      }

      span {
        font-weight: 700;
        margin-top: -10px;
        font-size: 22px;
        color: @primary-color;
      }
    }
  }

  main {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 300px;
    align-items: flex-start;
    justify-content: space-between;

    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
