@import '../../../../styles/variables.less';

#profile-model-content {
  .page-title {
    margin-bottom: 25px !important;

    .subtitle {
      display: block;
      margin-top: 2px;
    }
  }

  .ant-select-disabled {

    .ant-select-selector{
      color: @text-color;
    }

    .ant-select-arrow {
      display: none;
    }
  }


  .profile-model-content-header {
    display: flex;
    margin-top: 15px;

    .profile-model-basic-info {
      flex: none;
    }
  }

  div.ant-collapse:nth-child(1) {
    overflow: hidden;
  }

  .recurrences-selector-btn {
    display: flex;
    align-items: center;

    // min-width: 260px;
    padding: 10px 15px;

    cursor: pointer;
    border-radius: 8px;

    span {
      margin-right: 15px;
      flex: 1;

      font-size: 16px;
    }

    i {
      color: @text-color;

      &.caf-ic_processing_line {
        margin-right: 10px;
        font-size: 20px;
      }

      &.caf-ic_arrow_down {
        font-size: 22px;
      }

      &.caf-ic_arrow_up {
        font-size: 22px;
      }
    }

    &.danger {
      padding: 9px 14px;
      border: 1px solid @danger-color;

      span {
        color: @danger-color;
      }

      i {
        &.caf-ic_processing_line,
        &.caf-ic_arrow_down {
          color: @danger-color;
        }
      }
    }

    &.active {
      background-color: rgba(@gray-color-1, 0.1);
    }

    &:hover {
      background: rgba(@gray-color-1, 0.1);
    }
  }

  #profile-model-onboarding-services-component {
    // margin-top: 40px;
    margin-bottom: -12px;
  }

  // #profile-model-compliance-rules-component {
  //   margin-top: 40px;
  // }

  form.disabled input:disabled{
    background-color: #f5f5f5;
    color: @text-color;
    border: 1px solid #f5f5f5;
  }
}

.recurrences-selector-menu {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;

  > button {
    width: 325px;
    padding: 20px 25px !important;
  }

  .recurrences-selector-title {
    h1 {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;

    margin-left: 0px;
    margin-right: 0px;

    .ant-form-item-label {
      display: flex;

      > label {
        color: @text-color;

        &::after {
          display: none;
        }
      }
    }
  }

  .switch-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .ant-form-item-control {
      flex-grow: initial;
    }

    .ant-form-item-label {
      display: flex;
      margin-left: 15px;

      > label {
        color: @text-dark;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &::after {
          display: none;
        }
      }
    }
  }
}

.custom-empty-container {
  svg {
    width: 95px;
  }

  .ant-empty-description {
    color: @text-color;
    margin-bottom: 8px;
  }
}

.custom-profile-model-dropdown {
  overflow: initial !important;
  box-shadow: none !important;
}

.reset-visibility {
  visibility: hidden;
  opacity: 0;
}

.remove-template-validations {
  .remove-validations-list {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;

    i {
      font-size: 12px;
      color: @danger-color;
      padding-right: 5px;
      vertical-align: text-bottom;
    }

    span {
      font-weight: 500;
      vertical-align: middle;
    }

    > div {
      margin-bottom: 3px;
    }
  }
}
