.container-timing-options {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  gap: 0.9rem;
  margin-top: 1.5rem;

  .text {
    padding-left: 3rem;
    font-size: 14px;
    line-height: 1.2rem;
    color: #323232;
  }
}

.option-title {
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.option-subtitle {
  font-weight: 400;
}

.option-description {
  font-weight: 400;
}

.padding-left {
  padding-left: 2rem;
}

.wrapper-timing-options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 1rem;

  label {
    span {
      color: #323232;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
