@import '../../../styles/variables.less';

#registration-info{
    ::placeholder{
        font-weight: bold;
        color: @text-dark-secondary;
    }
    .text-subtitles{
        font-weight: bold;
        color: @text-dark;
        font-size: 16px;
    }
    .row-spaced{
        margin-top: 40px;
        margin-right: 25px;
    }
    .spin-position{
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

#files-download-card-component {
    .documents-list-item {
      border: 1px solid @gray-color-3;
      border-radius: 12px;
      padding: 10px;

      transition: 0.3s all;

      img {
        margin-right: 10px;
      }

      & + .documents-list-item {
        margin-top: 10px;
      }

      &.contract {
        background: @info-color;

        i,
        span {
          color: @white-color;
        }

        span:hover {
          transition: 0.3s color;
          color: @gray-color-4;
        }

        .btn-more-icon:hover {
          transition: 0.3s color;
          background: lighten(@info-color, 8%);
        }
      }

      .ant-col.documents-list-item-content {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;

        aside {
          display: flex;
          flex-direction: row;
          overflow: hidden;
        }

        .ant-spin {
          display: flex !important;
          justify-content: center;
          align-items: center;
          margin: 8px 13px;
        }

        .ant-dropdown-trigger.btn-more-icon {
          margin-left: 10px;
        }

        button.ant-btn.ant-btn-link {
          padding: 0;
          font-weight: 400;
          font-size: 13px;
          height: auto;
          color: @text-color;

          text-align: left;
          white-space: normal !important;
          word-wrap: break-word !important;
          line-height: normal !important;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .style-text{
      margin-top: 15px;
      display: flex;
      flex: 1;
      text-align: center;
      justify-content: center;
      font-size: 15px;
    }
    .spin-position{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
