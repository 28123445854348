@import '../../styles/variables.less';

#standard-button-component {
  &.ant-btn-primary:disabled {
    color: @white-color !important;
  }

  &.ant-btn-warning {
    background-color: @warning-color !important;
    border-color: @warning-color !important;
    color: @white-color !important;

    &:hover,
    &:focus {
      background-color: @warning-color !important;
      border-color: @warning-color !important;
      color: @white-color !important;
    }
  }
}
