@import '../../../../styles/variables.less';

#attempts-list-component {
  #filter {
    width: 280px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    margin-top: -30px;
    padding-top: 30px;
    max-height: calc(100vh - 200px);
    padding-bottom: 100px;
    @media screen and (min-width: 992px) {
      position: sticky !important;
    }
  }

  #list {
    flex: 1;
    padding-right: 40px;
    max-height: calc(100vh - 180px);
    max-width: calc(100vw - 580px);
    .list-content {
      max-height: calc(100vh - 280px);
      overflow-y: scroll;
      padding-right: 5px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: @secondary-color;
      }
    }
  }
}
