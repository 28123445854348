@import '../../../../../../styles/variables.less';

#profile-widget-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 100%;

  padding: 0px;
  border-top: 1px solid @border-color;

  left: 0;
  right: 0;
  bottom: 0px;

  // position: absolute;

  button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    text-shadow: none;
  }

  span {
    font-size: 15px;
  }
}
