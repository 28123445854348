@import '../../../../../../../styles/variables.less';

#card-companies-component {
  .ant-card {
    margin-bottom: 14px;
    overflow: hidden;

    .ant-card-body {
      padding: 0 !important;
      position: relative;
    }
  }

  .loading-tags-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-spin {
      margin: 0px;
    }
  }

  &.document-card {
    &:hover {
      > div {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15) !important;
        border-color: #fff !important;
      }
    }
  }

  .card-body {
    display: flex;
    justify-content: center;
    flex: 1;
    gap: 10px;
    padding: 20px;

    .card-body-infos {
      width: 100%;

      .profile-template-name {
        color: @gray-color-1;
        text-transform: uppercase;
      }
    }
  }

  .card-title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
    line-height: 1.4;
    color: @text-dark;
    flex: 1;
    word-break: break-word;
    text-transform: uppercase;
  }

  .card-infos {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .card-info {
    font-size: 13px;
    color: @text-dark;
    line-height: 1;

    text-overflow: ellipsis;

    display: flex;
    align-items: center;

    flex-shrink: 0;

    margin-right: 20px;

    i {
      font-size: 16px;
      height: 18px;
      color: @gray-color-2;
      margin-right: 5px;
    }
  }

  .card-info-status {
    display: flex;
    align-items: center;

    max-width: 265px;

    .card-info-status-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      margin-left: 15px;

      strong {
        margin-bottom: 2px;
        color: @text-dark;
      }
    }
  }

  .card-tags-wrapper {
    border-top: 1px solid @border-color;
  }

  #card-tags {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    width: 100%;

    .profile-tag {
      margin-bottom: 0 !important;
    }

    .missing-onboarding-label{
      display: flex !important;
      align-items: center;
      justify-content: center;

      .div-dot{
        margin: -2px 6px 0px 0px !important
      }
    }
  }

  .card-footer {
    display: flex;
    position: relative;
    padding: 3px 20px;
    background-color: transparent;
    border-radius: 0 !important;
    border-top: 1px solid @border-color !important;
    justify-content: space-between;
    align-items: center;

    span {
      line-height: 1.2;
    }

    .separator {
      font-style: normal;
      margin: 0 4px;
      line-height: 1;
      color: @text-color-secondary;
    }

    .btn-open {
      border-radius: 50%;
      height: 26px;
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      margin-left: 15px;
      i {
        color: @text-color-secondary;
      }

      &:hover {
        transform: scale(1.3);
        cursor: pointer;
        i {
          color: @text-color;
        }
      }
    }
  }
}
