@import '../../styles/variables.less';
#input-info {
  .input-phone {
    border-radius: 8px;
    display: flex;
    align-items: center;
    color: @text-dark;
  }
  .react-tel-input .flag-dropdown {
    border: 1px solid #e0e0e0;
    border-radius: 8px 0px 0px 8px;
  }
  .react-tel-input .form-control {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }
}
.tooltip-docs {
  white-space: pre-line !important;
  text-align: start !important;
}
