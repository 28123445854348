@import '../../../styles/variables.less';

#global-profiles-list {
  .profile-content-grid {
    display: grid;
    grid-template-columns: 4fr;
    grid-column-gap: 20px;
  }

  .profile-content-grid-1 {
    min-width: 0px;
  }
  .row-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    color: @text-dark;
  }
  .card-info-status {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-width: 120px;
    display: flex;

    .wrapper-icon-text {
      justify-content: center;
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        font-size: 11px !important;
      }

      span {
        font-weight: 700;
        font-size: 11px;
        text-align: center;
      }
    }
  }
  .profile-content-grid-2 {
    min-width: 300px;
  }

  .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
    padding-left: 0px !important;
    background-color: #fff;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    margin-left: 20px;
  }

  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
