@import '../../../../../styles/variables.less';

.card-new-activity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .content {
    padding: 24px;
  }

  .ant-card-body {
    width: 100%;
    padding: 0 !important;
  }

  .card-new-activity-header {
    width: 100%;
    margin-bottom: 20px;

    .title {
      display: block;
      margin-bottom: 2px;

      font-size: 12px;
    }

    .subtitle {
      color: @text-dark;
      font-size: 18px;
    }
  }

  .card-new-activity-table-infos {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .ant-col {
      font-size: 14px;
    }

    .ant-col:first-child {
      display: flex;
      align-items: center;
      justify-content: space-between;

      strong {
        color: @text-dark;
      }

      i {
        font-size: 18px;

        &.caf-ic_checkmark {
          color: @primary-color;
        }

        &.caf-ic_close {
          color: @danger-color;
        }
      }
    }

    .ant-col:last-child {
      padding-left: 0 !important;
    }
  }

  .action-description {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    width: 100%;
    margin-top: -10px;

    .subtitle {
      display: inline-flex;
      margin: 10px 10px 0px 0px;
    }

    .card-execution-tag {
      margin: 10px 0px 0px !important;
      max-width: initial !important;

      white-space: normal !important;

      span {
        display: -webkit-box;
        max-width: initial !important;

        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;

        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .action-tag {
      margin: 10px 0px 0px !important;
      background: rgba(@gray-color-2, 0.1);

      white-space: normal !important;

      span {
        font-size: 14px;
      }

      i {
        margin-right: 2px;
        font-size: 15px;
      }
    }
    .action-tag-danger {
      margin: 10px 0px 0px !important;
      background: @inverse-danger-color;
      color: @danger-color;

      white-space: normal !important;

      span {
        font-size: 14px;
      }

      i {
        margin-right: 2px;
        font-size: 15px;
      }
    }
  }

  .added-span {
    color: @success-color;
    line-height: 1.7;
  }
  .removed-span {
    color: @error-color;
    line-height: 1.7;
    span {
      text-decoration: line-through !important;
    }
  }
  .updated-span {
    //color: @info-color;
    line-height: 1.7;
  }
  .subtitle-span {
    font-weight: 700;
    font-size: 16px;
    line-height: 2;
  }
  .info-link {
    color: @info-color;
    a {
      color: @info-color;
    }
  }

  .create-link {
    color: @success-color;
    a {
      color: @success-color;
    }
  }

  .type-danger {
    color: @error-color;
  }

  .type-info {
    color: @info-color;
    a {
      color: @info-color;
    }
  }

  .type-primary {
    color: @success-color;
    a {
      color: @success-color;
    }
  }
  .type-warning {
    color: @warning-color;
    a {
      color: @warning-color;
    }
  }
  .type-default {
    color: #bdbdbd;
    a {
      color: #bdbdbd;
    }
  }

  .removed-link {
    color: @error-color;
  }

  .border-divider {
    border-top: 1px solid #e0e0e08f;
  }

  .link-transaction-template {
    color: @text-dark;
    span {
      text-decoration: underline;
      font-style: italic;
    }
    span:hover {
      color: @info-color !important;
    }
  }
}
.card-info-status-manual-rule {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-width: 120px;
  display: flex;

  .wrapper-icon-text-manual-rule {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      font-size: 11px !important;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      text-align: center;
    }
  }
}
.card-justification{
  margin-top: 20px;

  color: @text-dark;
  span{
    font-weight: 600;
  }
}