@import '../../styles/variables.less';

.ant-alert.alert-newness {
  border: none;
  > .ant-alert-message {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: @color-caf-blue-dark;
  }
  > .ant-alert-description {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    letter-spacing: 0.5px;
    color: @color-neutral-600;
  }
  > .ant-alert-close-icon {
    top: 18px;
    right: 16px;
  }
}
