@import '../../../styles/variables.less';

#title-divider-container {
  flex: 1;
  width: 100%;

  .title-divider-tag-wrapper {
    border-radius: 100px;
    background-color: #fff;
  }

  .title-divider-tag {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;

    padding: 5px 12px;

    background: @gray-color-2;
  }

  &.default {
    position: relative;

    .title-divider-tag-wrapper {
      position: absolute;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.to-print {
    display: flex;
    align-items: center;
    justify-content: center;

    .divider-line {
      width: 100%;
      height: 1px;
      background: @border-color;
    }
  }
}
