@import '../../../../../../../../../styles/variables.less';

#custom-process-tag {
  border-radius: 4px !important;

  font-weight: 500;
  font-size: 14px;
  line-height: 26px;

  // text-transform: capitalize;

  cursor: inherit;

  &.default {
    color: @gray-color-1;
    background: @gray-color-4;
  }

  &.danger {
    color: @danger-color;
    background: @inverse-danger-color;
  }

  & + & {
    margin-left: 10px;
  }
}
