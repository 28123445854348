@import '../../../../../styles/variables.less';

#email-id {
  .col-ant {
    padding: 5;
  }
  .list-subtitle {
    color: @text-dark;
    font-size: 15px;
  }
  .text-style {
    color: @black-color;
  }
  .span-style {
    margin-right: 10px;
  }
  .title {
    padding-left: 15px;
    font-size: 15px;
  }
  .titleApp {
    font-size: 17px;
    color: #323232;
    padding-top: 20px;
    margin-top: 80px;
  }
  .default {
    font-size: 13.5px;
    color: #323232;
  }
  .col-2 {
    padding-left: 0;
    margin-left: 0;
  }
  img {
    width: 35px;
    height: 35px;
    padding-bottom: 5px;
  }
  strong {
    color: @black-color;
  }
}
