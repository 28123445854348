@import '../../../../../../styles/variables.less';

.white-list-card {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    .card-info-content {
      display: flex;
      .card-info {
        margin: 15px;
        flex: 1 1;
        display: flex;
        justify-content: space-between;
        .card-info-status {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          justify-content: space-between;
          .card-info-status-content {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
          }
          .tag-expiration-white-list {
            color: @primary-color;
            background-color: @inverse-primary-color;
          }
          .btn-remove {
            color: @danger-color;
            background: transparent;
            padding: 0;
            &:hover {
              background: transparent;
            }
          }
        }
        .card-info-data {
          display: flex;
          flex-direction: column;
          gap: 8px;
          max-width: 340px;
        }
        .card-info-simple {
          display: flex;
          align-items: center;
          flex-direction: row;
          max-width: 100%;
          span {
            white-space: nowrap;
            color: @black-color;
          }
          .card-info-simple-content {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;

            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
