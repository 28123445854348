@import '../../../../../../../../../styles/variables.less';


.suspects-carousel{
  width: 240px !important;
  height: 400px;
}


.suspects-carousel .slick-dots{
  height: 20px !important;
}

.suspects-carousel .slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  margin-top: 50px;

  background-color: @success-color !important;
}
.suspects-carousel .slick-dots li.slick-active button {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;

  background-color: @success-color !important;
}
