@import '../../../../../styles/variables.less';

#checkpoint-model-container {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 5px 0px;

  .checkpoint-model-infos {
    flex: 1;
    padding-right: 15px;

    strong {
      display: block;
      margin-bottom: 2px;

      color: @text-dark;
      font-size: 16px;
      font-weight: 500;
    }

    span {
      color: @text-color;
      font-size: 13px;

      word-break: break-word !important;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    i {
      font-size: 22px;

      &.rotate {
        color: @info-color;
        animation: rotate 2s linear infinite;

        @keyframes rotate {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  button + button {
    margin-left: 15px;
  }

  .tag-inactive {
    margin-right: 15px;
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
  }
}
