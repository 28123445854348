@import '../../../../../../../styles/variables.less';

#executions-spc-row-infos {
  margin: 20px 0px;

  .spc-col-info {
    display: flex;
    flex-direction: column;

    color: @text-dark;

    strong {
      font-size: 16px;
    }

    span {
      font-size: 14px;
    }

    .spc-class-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 0px;
      padding-right: 40px;

      span {
        margin-top: 0px !important;
      }
    }
  }

  .no-left {
    padding-left: 0px !important;
  }

  .no-right {
    padding-right: 0px !important;
  }

  .end {
    text-align: end;
  }
}
