/*---------------------------------
--------- Helper Style ---------
---------------------------------*/

/*Margin*/

.no-mrg {
  margin: 0 !important;
}

.no-mrg-top {
  margin-top: 0 !important;
}

.no-mrg-btm {
  margin-bottom: 0 !important;
}

.no-mrg-right {
  margin-right: 0 !important;
}

.no-mrg-left {
  margin-left: 0 !important;
}

.no-mrg-vertical {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-mrg-horizon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.margin-5 {
  margin: 5px !important;
}

.margin-10 {
  margin: 10px !important;
}

.margin-15 {
  margin: 15px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-25 {
  margin: 25px !important;
}

.margin-20 {
  margin: 20px !important;
}

.margin-25 {
  margin: 25px !important;
}

.margin-30 {
  margin: 30px !important;
}

.margin-35 {
  margin: 35px !important;
}

.margin-40 {
  margin: 40px !important;
}

.margin-45 {
  margin: 45px !important;
}

.margin-50 {
  margin: 50px !important;
}

.margin-55 {
  margin: 55px !important;
}

.margin-60 {
  margin: 60px !important;
}

.margin-65 {
  margin: 65px !important;
}

.margin-70 {
  margin: 70px !important;
}

.margin-75 {
  margin: 75px !important;
}

.margin-80 {
  margin: 80px !important;
}

.margin-85 {
  margin: 85px !important;
}

.margin-90 {
  margin: 90px !important;
}

.margin-95 {
  margin: 95px !important;
}

.margin-100 {
  margin: 100px !important;
}

.margin-105 {
  margin: 105px !important;
}

.margin-110 {
  margin: 110px !important;
}

.margin-115 {
  margin: 115px !important;
}

.margin-120 {
  margin: 120px !important;
}

.margin-125 {
  margin: 125px !important;
}

.margin-130 {
  margin: 130px !important;
}

.margin-135 {
  margin: 135px !important;
}

.margin-140 {
  margin: 140px !important;
}

.margin-145 {
  margin: 145px !important;
}

.margin-150 {
  margin: 150px !important;
}

.mrg-horizon-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mrg-left-auto {
  margin-left: auto !important;
}

.mrg-right-auto {
  margin-right: auto !important;
}

.mrg-vertical-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mrg-vertical-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mrg-vertical-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mrg-vertical-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mrg-vertical-25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mrg-vertical-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mrg-vertical-35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mrg-vertical-40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mrg-vertical-45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mrg-vertical-50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mrg-vertical-55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mrg-vertical-60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mrg-vertical-65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mrg-vertical-70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mrg-vertical-75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mrg-vertical-80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mrg-vertical-85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mrg-vertical-90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mrg-vertical-95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mrg-vertical-100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mrg-vertical-105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.mrg-vertical-110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.mrg-vertical-115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.mrg-vertical-120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mrg-vertical-125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important;
}

.mrg-vertical-130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.mrg-vertical-135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important;
}

.mrg-vertical-140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.mrg-vertical-145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important;
}

.mrg-vertical-150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.mrg-horizon-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mrg-horizon-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mrg-horizon-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mrg-horizon-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mrg-horizon-25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mrg-horizon-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mrg-horizon-35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mrg-horizon-40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mrg-horizon-45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.mrg-horizon-50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mrg-horizon-55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.mrg-horizon-60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.mrg-horizon-65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.mrg-horizon-70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.mrg-horizon-75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.mrg-horizon-80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.mrg-horizon-85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.mrg-horizon-90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.mrg-horizon-95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.mrg-horizon-100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.mrg-horizon-105 {
  margin-left: 105px !important;
  margin-right: 105px !important;
}

.mrg-horizon-110 {
  margin-left: 110px !important;
  margin-right: 110px !important;
}

.mrg-horizon-115 {
  margin-left: 115px !important;
  margin-right: 115px !important;
}

.mrg-horizon-120 {
  margin-left: 120px !important;
  margin-right: 120px !important;
}

.mrg-horizon-125 {
  margin-left: 125px !important;
  margin-right: 125px !important;
}

.mrg-horizon-130 {
  margin-left: 130px !important;
  margin-right: 130px !important;
}

.mrg-horizon-135 {
  margin-left: 135px !important;
  margin-right: 135px !important;
}

.mrg-horizon-140 {
  margin-left: 140px !important;
  margin-right: 140px !important;
}

.mrg-horizon-145 {
  margin-left: 145px !important;
  margin-right: 145px !important;
}

.mrg-horizon-150 {
  margin-left: 150px !important;
  margin-right: 150px !important;
}

.mrg-top-0 {
  margin-top: 0px !important;
}

.mrg-top-5 {
  margin-top: 5px !important;
}

.mrg-top-8 {
  margin-top: 8px !important;
}

.mrg-top-10 {
  margin-top: 10px !important;
}

.mrg-top-13 {
  margin-top: 13px !important;
}

.mrg-top-14 {
  margin-top: 14px !important;
}

.mrg-top-15 {
  margin-top: 15px !important;
}

.mrg-top-20 {
  margin-top: 20px !important;
}

.mrg-top-25 {
  margin-top: 25px !important;
}

.mrg-top-30 {
  margin-top: 30px !important;
}

.mrg-top-35 {
  margin-top: 35px !important;
}

.mrg-top-40 {
  margin-top: 40px !important;
}

.mrg-top-45 {
  margin-top: 45px !important;
}

.mrg-top-50 {
  margin-top: 50px !important;
}

.mrg-top-55 {
  margin-top: 55px !important;
}

.mrg-top-60 {
  margin-top: 60px !important;
}

.mrg-top-65 {
  margin-top: 65px !important;
}

.mrg-top-70 {
  margin-top: 70px !important;
}

.mrg-top-75 {
  margin-top: 75px !important;
}

.mrg-top-80 {
  margin-top: 80px !important;
}

.mrg-top-85 {
  margin-top: 85px !important;
}

.mrg-top-90 {
  margin-top: 90px !important;
}

.mrg-top-95 {
  margin-top: 95px !important;
}

.mrg-top-100 {
  margin-top: 100px !important;
}

.mrg-top-105 {
  margin-top: 105px !important;
}

.mrg-top-110 {
  margin-top: 110px !important;
}

.mrg-top-115 {
  margin-top: 115px !important;
}

.mrg-top-120 {
  margin-top: 120px !important;
}

.mrg-top-125 {
  margin-top: 125px !important;
}

.mrg-top-130 {
  margin-top: 130px !important;
}

.mrg-top-135 {
  margin-top: 135px !important;
}

.mrg-top-140 {
  margin-top: 140px !important;
}

.mrg-top-145 {
  margin-top: 145px !important;
}

.mrg-top-150 {
  margin-top: 150px !important;
}

.mrg-btm-5 {
  margin-bottom: 5px !important;
}

.mrg-btm-8 {
  margin-bottom: 8px !important;
}

.mrg-btm-10 {
  margin-bottom: 10px !important;
}

.mrg-btm-15 {
  margin-bottom: 15px !important;
}

.mrg-btm-20 {
  margin-bottom: 20px !important;
}

.mrg-btm-25 {
  margin-bottom: 25px !important;
}

.mrg-btm-30 {
  margin-bottom: 30px !important;
}

.mrg-btm-35 {
  margin-bottom: 35px !important;
}

.mrg-btm-40 {
  margin-bottom: 40px !important;
}

.mrg-btm-45 {
  margin-bottom: 45px !important;
}

.mrg-btm-50 {
  margin-bottom: 50px !important;
}

.mrg-btm-55 {
  margin-bottom: 55px !important;
}

.mrg-btm-60 {
  margin-bottom: 60px !important;
}

.mrg-btm-65 {
  margin-bottom: 65px !important;
}

.mrg-btm-70 {
  margin-bottom: 70px !important;
}

.mrg-btm-75 {
  margin-bottom: 75px !important;
}

.mrg-btm-80 {
  margin-bottom: 80px !important;
}

.mrg-btm-85 {
  margin-bottom: 85px !important;
}

.mrg-btm-90 {
  margin-bottom: 90px !important;
}

.mrg-btm-95 {
  margin-bottom: 95px !important;
}

.mrg-btm-100 {
  margin-bottom: 100px !important;
}

.mrg-btm-105 {
  margin-bottom: 105px !important;
}

.mrg-btm-110 {
  margin-bottom: 110px !important;
}

.mrg-btm-115 {
  margin-bottom: 115px !important;
}

.mrg-btm-120 {
  margin-bottom: 120px !important;
}

.mrg-btm-125 {
  margin-bottom: 125px !important;
}

.mrg-btm-130 {
  margin-bottom: 130px !important;
}

.mrg-btm-135 {
  margin-bottom: 135px !important;
}

.mrg-btm-140 {
  margin-bottom: 140px !important;
}

.mrg-btm-145 {
  margin-bottom: 145px !important;
}

.mrg-btm-150 {
  margin-bottom: 150px !important;
}

.mrg-left-5 {
  margin-left: 5px !important;
}

.mrg-left-10 {
  margin-left: 10px !important;
}

.mrg-left-15 {
  margin-left: 15px !important;
}

.mrg-left-20 {
  margin-left: 20px !important;
}

.mrg-left-25 {
  margin-left: 25px !important;
}

.mrg-left-30 {
  margin-left: 30px !important;
}

.mrg-left-35 {
  margin-left: 35px !important;
}

.mrg-left-40 {
  margin-left: 40px !important;
}

.mrg-left-45 {
  margin-left: 45px !important;
}

.mrg-left-50 {
  margin-left: 50px !important;
}

.mrg-left-55 {
  margin-left: 55px !important;
}

.mrg-left-60 {
  margin-left: 60px !important;
}

.mrg-left-65 {
  margin-left: 65px !important;
}

.mrg-left-70 {
  margin-left: 70px !important;
}

.mrg-left-75 {
  margin-left: 75px !important;
}

.mrg-left-80 {
  margin-left: 80px !important;
}

.mrg-left-85 {
  margin-left: 85px !important;
}

.mrg-left-90 {
  margin-left: 90px !important;
}

.mrg-left-95 {
  margin-left: 95px !important;
}

.mrg-left-100 {
  margin-left: 100px !important;
}

.mrg-left-105 {
  margin-left: 105px !important;
}

.mrg-left-110 {
  margin-left: 110px !important;
}

.mrg-left-115 {
  margin-left: 115px !important;
}

.mrg-left-120 {
  margin-left: 120px !important;
}

.mrg-left-125 {
  margin-left: 125px !important;
}

.mrg-left-130 {
  margin-left: 130px !important;
}

.mrg-left-135 {
  margin-left: 135px !important;
}

.mrg-left-140 {
  margin-left: 140px !important;
}

.mrg-left-145 {
  margin-left: 145px !important;
}

.mrg-left-150 {
  margin-left: 150px !important;
}

.mrg-right-5 {
  margin-right: 5px !important;
}

.mrg-right-10 {
  margin-right: 10px !important;
}

.mrg-right-15 {
  margin-right: 15px !important;
}

.mrg-right-20 {
  margin-right: 20px !important;
}

.mrg-right-25 {
  margin-right: 25px !important;
}

.mrg-right-30 {
  margin-right: 30px !important;
}

.mrg-right-35 {
  margin-right: 35px !important;
}

.mrg-right-40 {
  margin-right: 40px !important;
}

.mrg-right-45 {
  margin-right: 45px !important;
}

.mrg-right-50 {
  margin-right: 50px !important;
}

.mrg-right-55 {
  margin-right: 55px !important;
}

.mrg-right-60 {
  margin-right: 60px !important;
}

.mrg-right-65 {
  margin-right: 65px !important;
}

.mrg-right-70 {
  margin-right: 70px !important;
}

.mrg-right-75 {
  margin-right: 75px !important;
}

.mrg-right-80 {
  margin-right: 80px !important;
}

.mrg-right-85 {
  margin-right: 85px !important;
}

.mrg-right-90 {
  margin-right: 90px !important;
}

.mrg-right-95 {
  margin-right: 95px !important;
}

.mrg-right-100 {
  margin-right: 100px !important;
}

.mrg-right-105 {
  margin-right: 105px !important;
}

.mrg-right-110 {
  margin-right: 110px !important;
}

.mrg-right-115 {
  margin-right: 115px !important;
}

.mrg-right-120 {
  margin-right: 120px !important;
}

.mrg-right-125 {
  margin-right: 125px !important;
}

.mrg-right-130 {
  margin-right: 130px !important;
}

.mrg-right-135 {
  margin-right: 135px !important;
}

.mrg-right-140 {
  margin-right: 140px !important;
}

.mrg-right-145 {
  margin-right: 145px !important;
}

.mrg-right-150 {
  margin-right: 150px !important;
}

/*Padding*/

.no-pdd {
  padding: 0 !important;
}

.no-pdd-top {
  padding-top: 0 !important;
}

.no-pdd-btm {
  padding-bottom: 0 !important;
}

.no-pdd-left {
  padding-left: 0 !important;
}

.no-pdd-right {
  padding-right: 0 !important;
}

.no-pdd-vertical {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-pdd-horizon {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-25 {
  padding: 25px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-35 {
  padding: 35px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-45 {
  padding: 45px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-55 {
  padding: 55px !important;
}

.padding-60 {
  padding: 60px !important;
}

.padding-65 {
  padding: 65px !important;
}

.padding-70 {
  padding: 70px !important;
}

.padding-75 {
  padding: 75px !important;
}

.padding-80 {
  padding: 80px !important;
}

.padding-85 {
  padding: 85px !important;
}

.padding-90 {
  padding: 90px !important;
}

.padding-95 {
  padding: 95px !important;
}

.padding-100 {
  padding: 100px !important;
}

.padding-105 {
  padding: 105px !important;
}

.padding-110 {
  padding: 110px !important;
}

.padding-115 {
  padding: 115px !important;
}

.padding-120 {
  padding: 120px !important;
}

.padding-125 {
  padding: 125px !important;
}

.padding-130 {
  padding: 130px !important;
}

.padding-135 {
  padding: 135px !important;
}

.padding-140 {
  padding: 140px !important;
}

.padding-145 {
  padding: 145px !important;
}

.padding-150 {
  padding: 150px !important;
}

.pdd-vertical-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pdd-vertical-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pdd-vertical-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pdd-vertical-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pdd-vertical-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pdd-vertical-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pdd-vertical-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pdd-vertical-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pdd-vertical-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pdd-vertical-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pdd-vertical-55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pdd-vertical-60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pdd-vertical-65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pdd-vertical-70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pdd-vertical-75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pdd-vertical-80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pdd-vertical-85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pdd-vertical-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pdd-vertical-95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pdd-vertical-100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pdd-vertical-105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.pdd-vertical-110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pdd-vertical-115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.pdd-vertical-120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pdd-vertical-125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.pdd-vertical-130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.pdd-vertical-135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.pdd-vertical-140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.pdd-vertical-145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.pdd-vertical-150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pdd-horizon-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pdd-horizon-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pdd-horizon-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.pdd-horizon-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pdd-horizon-21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.pdd-horizon-25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.pdd-horizon-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.pdd-horizon-35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.pdd-horizon-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pdd-horizon-45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.pdd-horizon-50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.pdd-horizon-55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.pdd-horizon-60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.pdd-horizon-65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.pdd-horizon-70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.pdd-horizon-75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.pdd-horizon-80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.pdd-horizon-85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.pdd-horizon-90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.pdd-horizon-95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.pdd-horizon-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.pdd-horizon-105 {
  padding-left: 105px !important;
  padding-right: 105px !important;
}

.pdd-horizon-110 {
  padding-left: 110px !important;
  padding-right: 110px !important;
}

.pdd-horizon-115 {
  padding-left: 115px !important;
  padding-right: 115px !important;
}

.pdd-horizon-120 {
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.pdd-horizon-125 {
  padding-left: 125px !important;
  padding-right: 125px !important;
}

.pdd-horizon-130 {
  padding-left: 130px !important;
  padding-right: 130px !important;
}

.pdd-horizon-135 {
  padding-left: 135px !important;
  padding-right: 135px !important;
}

.pdd-horizon-140 {
  padding-left: 140px !important;
  padding-right: 140px !important;
}

.pdd-horizon-145 {
  padding-left: 145px !important;
  padding-right: 145px !important;
}

.pdd-horizon-150 {
  padding-left: 150px !important;
  padding-right: 150px !important;
}

.pdd-top-5 {
  padding-top: 5px !important;
}

.pdd-top-10 {
  padding-top: 10px !important;
}

.pdd-top-15 {
  padding-top: 15px !important;
}

.pdd-top-20 {
  padding-top: 20px !important;
}

.pdd-top-25 {
  padding-top: 25px !important;
}

.pdd-top-30 {
  padding-top: 30px !important;
}

.pdd-top-35 {
  padding-top: 35px !important;
}

.pdd-top-40 {
  padding-top: 40px !important;
}

.pdd-top-45 {
  padding-top: 45px !important;
}

.pdd-top-50 {
  padding-top: 50px !important;
}

.pdd-top-55 {
  margin-top: 55px !important;
}

.pdd-top-60 {
  padding-top: 60px !important;
}

.pdd-top-65 {
  padding-top: 65px !important;
}

.pdd-top-70 {
  padding-top: 70px !important;
}

.pdd-top-75 {
  padding-top: 75px !important;
}

.pdd-top-80 {
  padding-top: 80px !important;
}

.pdd-top-85 {
  padding-top: 85px !important;
}

.pdd-top-90 {
  padding-top: 90px !important;
}

.pdd-top-95 {
  padding-top: 95px !important;
}

.pdd-top-100 {
  padding-top: 100px !important;
}

.pdd-top-105 {
  padding-top: 105px !important;
}

.pdd-top-110 {
  padding-top: 110px !important;
}

.pdd-top-115 {
  padding-top: 115px !important;
}

.pdd-top-120 {
  padding-top: 120px !important;
}

.pdd-top-125 {
  padding-top: 125px !important;
}

.pdd-top-130 {
  padding-top: 130px !important;
}

.pdd-top-135 {
  padding-top: 135px !important;
}

.pdd-top-140 {
  padding-top: 140px !important;
}

.pdd-top-145 {
  padding-top: 145px !important;
}

.pdd-top-150 {
  padding-top: 150px !important;
}

.pdd-btm-5 {
  padding-bottom: 5px !important;
}

.pdd-btm-10 {
  padding-bottom: 10px !important;
}

.pdd-btm-15 {
  padding-bottom: 15px !important;
}

.pdd-btm-20 {
  padding-bottom: 20px !important;
}

.pdd-btm-25 {
  padding-bottom: 25px !important;
}

.pdd-btm-30 {
  padding-bottom: 30px !important;
}

.pdd-btm-35 {
  padding-bottom: 35px !important;
}

.pdd-btm-40 {
  padding-bottom: 40px !important;
}

.pdd-btm-45 {
  padding-bottom: 45px !important;
}

.pdd-btm-50 {
  padding-bottom: 50px !important;
}

.pdd-btm-55 {
  margin-bottom: 55px !important;
}

.pdd-btm-60 {
  padding-bottom: 60px !important;
}

.pdd-btm-65 {
  padding-bottom: 65px !important;
}

.pdd-btm-70 {
  padding-bottom: 70px !important;
}

.pdd-btm-75 {
  padding-bottom: 75px !important;
}

.pdd-btm-80 {
  padding-bottom: 80px !important;
}

.pdd-btm-85 {
  padding-bottom: 85px !important;
}

.pdd-btm-90 {
  padding-bottom: 90px !important;
}

.pdd-btm-95 {
  padding-bottom: 95px !important;
}

.pdd-btm-100 {
  padding-bottom: 100px !important;
}

.pdd-btm-105 {
  padding-bottom: 105px !important;
}

.pdd-btm-110 {
  padding-bottom: 110px !important;
}

.pdd-btm-115 {
  padding-bottom: 115px !important;
}

.pdd-btm-120 {
  margin-bottom: 120px !important;
}

.pdd-btm-125 {
  padding-bottom: 125px !important;
}

.pdd-btm-130 {
  padding-bottom: 130px !important;
}

.pdd-btm-135 {
  padding-bottom: 135px !important;
}

.pdd-btm-140 {
  padding-bottom: 140px !important;
}

.pdd-btm-145 {
  padding-bottom: 145px !important;
}

.pdd-btm-150 {
  padding-bottom: 150px !important;
}

.pdd-left-5 {
  padding-left: 5px !important;
}

.pdd-left-10 {
  padding-left: 10px !important;
}

.pdd-left-15 {
  padding-left: 15px !important;
}

.pdd-left-20 {
  padding-left: 20px !important;
}

.pdd-left-25 {
  padding-left: 25px !important;
}

.pdd-left-30 {
  padding-left: 30px !important;
}

.pdd-left-35 {
  padding-left: 35px !important;
}

.pdd-left-40 {
  padding-left: 40px !important;
}

.pdd-left-45 {
  padding-left: 45px !important;
}

.pdd-left-50 {
  padding-left: 50px !important;
}

.pdd-left-55 {
  margin-left: 55px !important;
}

.pdd-left-60 {
  padding-left: 60px !important;
}

.pdd-left-65 {
  padding-left: 65px !important;
}

.pdd-left-70 {
  padding-left: 70px !important;
}

.pdd-left-75 {
  padding-left: 75px !important;
}

.pdd-left-80 {
  padding-left: 80px !important;
}

.pdd-left-85 {
  padding-left: 85px !important;
}

.pdd-left-90 {
  padding-left: 90px !important;
}

.pdd-left-95 {
  padding-left: 95px !important;
}

.pdd-left-100 {
  padding-left: 100px !important;
}

.pdd-left-105 {
  padding-left: 105px !important;
}

.pdd-left-110 {
  padding-left: 110px !important;
}

.pdd-left-115 {
  padding-left: 115px !important;
}

.pdd-left-120 {
  margin-left: 120px !important;
}

.pdd-left-125 {
  padding-left: 125px !important;
}

.pdd-left-130 {
  padding-left: 130px !important;
}

.pdd-left-135 {
  padding-left: 135px !important;
}

.pdd-left-140 {
  padding-left: 140px !important;
}

.pdd-left-145 {
  padding-left: 145px !important;
}

.pdd-left-150 {
  padding-left: 150px !important;
}

.pdd-right-5 {
  padding-right: 5px !important;
}

.pdd-right-10 {
  padding-right: 10px !important;
}

.pdd-right-15 {
  padding-right: 15px !important;
}

.pdd-right-20 {
  padding-right: 20px !important;
}

.pdd-right-25 {
  padding-right: 25px !important;
}

.pdd-right-30 {
  padding-right: 30px !important;
}

.pdd-right-35 {
  padding-right: 35px !important;
}

.pdd-right-40 {
  padding-right: 40px !important;
}

.pdd-right-45 {
  padding-right: 45px !important;
}

.pdd-right-50 {
  padding-right: 50px !important;
}

.pdd-right-55 {
  margin-right: 55px !important;
}

.pdd-right-60 {
  padding-right: 60px !important;
}

.pdd-right-65 {
  padding-right: 65px !important;
}

.pdd-right-70 {
  padding-right: 70px !important;
}

.pdd-right-75 {
  padding-right: 75px !important;
}

.pdd-right-80 {
  padding-right: 80px !important;
}

.pdd-right-85 {
  padding-right: 85px !important;
}

.pdd-right-90 {
  padding-right: 90px !important;
}

.pdd-right-95 {
  padding-right: 95px !important;
}

.pdd-right-100 {
  padding-right: 100px !important;
}

.pdd-right-105 {
  padding-right: 105px !important;
}

.pdd-left-110 {
  padding-left: 110px !important;
}

.pdd-right-115 {
  padding-right: 115px !important;
}

.pdd-right-120 {
  margin-right: 120px !important;
}

.pdd-right-125 {
  padding-right: 125px !important;
}

.pdd-right-130 {
  padding-right: 130px !important;
}

.pdd-right-135 {
  padding-right: 135px !important;
}

.pdd-right-140 {
  padding-right: 140px !important;
}

.pdd-right-145 {
  padding-right: 145px !important;
}

.pdd-right-150 {
  padding-right: 150px !important;
}

.width-0 {
  width: 0px;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-20 {
  width: 20%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-35 {
  width: 35%;
}

.width-40 {
  width: 40%;
}

.width-45 {
  width: 45%;
}

.width-50 {
  width: 50%;
}

.width-55 {
  width: 55%;
}

.width-60 {
  width: 60%;
}

.width-65 {
  width: 65%;
}

.width-70 {
  width: 70%;
}

.width-75 {
  width: 75%;
}

.width-80 {
  width: 80%;
}

.width-85 {
  width: 85%;
}

.width-90 {
  width: 90%;
}

.width-95 {
  width: 95%;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.full-height {
  min-height: 100vh;
}

.ls-0 {
  letter-spacing: 0px !important;
}

.ls-0-5 {
  letter-spacing: 0.5px !important;
}

.ls-1 {
  letter-spacing: 1px !important;
}

.ls-1-5 {
  letter-spacing: 1.5px !important;
}

.ls-2 {
  letter-spacing: 2px !important;
}

.ls-2-5 {
  letter-spacing: 2.5px !important;
}

.ls-3 {
  letter-spacing: 3px !important;
}

.ls-3-5 {
  letter-spacing: 3.5px !important;
}

.ls-4 {
  letter-spacing: 4px !important;
}

.ls-4-5 {
  letter-spacing: 4.5px !important;
}

.ls-5 {
  letter-spacing: 5px !important;
}

.ls-5-5 {
  letter-spacing: 5.5px !important;
}

.ls-6 {
  letter-spacing: 6px !important;
}

.ls-6-5 {
  letter-spacing: 6.5px !important;
}

.ls-7 {
  letter-spacing: 7px !important;
}

.ls-7-5 {
  letter-spacing: 7.5px !important;
}

.ls-8 {
  letter-spacing: 8px !important;
}

.ls-8-5 {
  letter-spacing: 8.5px !important;
}

.ls-9 {
  letter-spacing: 9px !important;
}

.ls-9-5 {
  letter-spacing: 9.5px !important;
}

.ls-10 {
  letter-spacing: 10px !important;
}

.ls-11 {
  letter-spacing: 11px !important;
}

.ls-12 {
  letter-spacing: 12px !important;
}

.ls-13 {
  letter-spacing: 13px !important;
}

.ls-14 {
  letter-spacing: 14px !important;
}

.ls-15 {
  letter-spacing: 15px !important;
}

/*Line-Height*/
.lh-0 {
  line-height: 0 !important;
}

.lh-0-5 {
  line-height: 0.5 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-1-1 {
  line-height: 1.1 !important;
}

.lh-1-2 {
  line-height: 1.2 !important;
}

.lh-1-3 {
  line-height: 1.3 !important;
}

.lh-1-4 {
  line-height: 1.4 !important;
}

.lh-1-5 {
  line-height: 1.5 !important;
}

.lh-1-6 {
  line-height: 1.6 !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-1-8 {
  line-height: 1.8 !important;
}

.lh-1-9 {
  line-height: 1.9 !important;
}

.lh-2 {
  line-height: 2 !important;
}

.lh-2-1 {
  line-height: 2.1 !important;
}

.lh-2-2 {
  line-height: 2.2 !important;
}

.lh-2-3 {
  line-height: 2.3 !important;
}

.lh-2-4 {
  line-height: 2.4 !important;
}

.lh-2-5 {
  line-height: 2.5 !important;
}

.lh-3 {
  line-height: 3 !important;
}

.lh-4 {
  line-height: 4 !important;
}

/*Font Size*/

.font-size-8 {
  font-size: 8px !important;
}

.font-size-9 {
  font-size: 9px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-21 {
  font-size: 21px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-23 {
  font-size: 23px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-25 {
  font-size: 25px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-27 {
  font-size: 27px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.font-size-29 {
  font-size: 29px !important;
}

.font-size-30 {
  font-size: 30px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-35 {
  font-size: 35px !important;
}

.font-size-40 {
  font-size: 40px !important;
}

.font-size-45 {
  font-size: 45px !important;
}

.font-size-50 {
  font-size: 50px !important;
}

.font-size-55 {
  font-size: 55px !important;
}

.font-size-60 {
  font-size: 60px !important;
}

.font-size-65 {
  font-size: 65px !important;
}

.font-size-70 {
  font-size: 70px !important;
}

.font-size-75 {
  font-size: 75px !important;
}

.font-size-80 {
  font-size: 80px !important;
}

.font-size-85 {
  font-size: 85px !important;
}

.font-size-90 {
  font-size: 90px !important;
}

.font-size-95 {
  font-size: 95px !important;
}

.font-size-100 {
  font-size: 100px !important;
}

.font-size-105 {
  font-size: 105px !important;
}

.font-size-110 {
  font-size: 110px !important;
}

.font-size-115 {
  font-size: 115px !important;
}

.font-size-120 {
  font-size: 120px !important;
}

.font-size-125 {
  font-size: 125px !important;
}

.font-size-130 {
  font-size: 130px !important;
}

.font-size-135 {
  font-size: 135px !important;
}

.font-size-140 {
  font-size: 140px !important;
}

.font-size-145 {
  font-size: 145px !important;
}

.font-size-150 {
  font-size: 150px !important;
}

.font-size-155 {
  font-size: 155px !important;
}

.font-size-160 {
  font-size: 160px !important;
}

.font-size-165 {
  font-size: 165px !important;
}

.font-size-170 {
  font-size: 170px !important;
}

.font-size-175 {
  font-size: 175px !important;
}

.font-size-180 {
  font-size: 180px !important;
}

.font-size-185 {
  font-size: 185px !important;
}

.font-size-190 {
  font-size: 190px !important;
}

.font-size-195 {
  font-size: 195px !important;
}

.font-size-200 {
  font-size: 200px !important;
}

/* Font Weight */

.text-light {
  font-weight: 300 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-semibold {
  font-weight: 500 !important;
}

.text-bold {
  font-weight: 700 !important;
}

/* Display */

.display-block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.pointer {
  cursor: pointer;
}

/* Position */

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.static {
  position: static;
}

/* Overflow */

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

/* Text Align */
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

/* Image */
.img-circle {
  border-radius: 50% !important;
}

/* Border */
.border {
  border: 1px solid @border-color;

  &.top {
    border: 0px !important;
    border-top: 1px solid @border-color !important;
  }

  &.right {
    border: 0px !important;
    border-right: 1px solid @border-color !important;
  }

  &.bottom {
    border: 0px !important;
    border-bottom: 1px solid @border-color !important;
  }

  &.left {
    border: 0px !important;
    border-left: 1px solid @border-color !important;
  }
}

.no-border {
  border: 0px !important;
}

.no-shadow {
  box-shadow: none !important;
}

/* Vertical Align */
.vertical-align {
  display: table;
  height: 100%;
  width: 100%;

  .table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.vertical-align-super {
  vertical-align: super;
}

/* border-radius */

.radius-0 {
  border-radius: 0 !important;
}

.radius-2 {
  border-radius: 2px !important;
}

.radius-4 {
  border-radius: 4px !important;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-round {
  border-radius: 50px !important;
}

/* max text lines */

.max-lines-1 {
  -webkit-line-clamp: 1;
}

.max-lines-2 {
  -webkit-line-clamp: 2;
}

.max-lines-3 {
  -webkit-line-clamp: 3;
}

.max-lines-4 {
  -webkit-line-clamp: 4;
}

.max-lines-5 {
  -webkit-line-clamp: 5;
}

.max-lines-1,
.max-lines-2,
.max-lines-3,
.max-lines-4,
.max-lines-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  word-break: break-all;
}

.fd-row {
  flex-direction: row !important;
}

.fd-column {
  flex-direction: column !important;
}

.flex-jc-center {
  display: flex;
  justify-content: center;
}

/*
 *  Flexbox helper
 */

.flex {
  display: flex !important;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-1 {
  flex: 1 !important;
}

.center {
  align-items: center !important;
  justify-content: center !important;
}

.end {
  align-items: flex-end !important;
  justify-content: flex-end !important;
}

.start {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.center-start {
  align-items: flex-start !important;
  justify-content: center !important;
}

.center-end {
  align-items: flex-end !important;
  justify-content: center !important;
}

.start-center {
  align-items: center !important;
  justify-content: flex-start !important;
}

.end-center {
  align-items: center !important;
  justify-content: flex-end !important;
}

.start-end {
  align-items: flex-end !important;
  justify-content: flex-start !important;
}

.end-start {
  align-items: flex-start !important;
  justify-content: flex-end !important;
}

.space-between {
  justify-content: space-between !important;
}

/* end Flexbox helper */

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.hide-scroll {
  -ms-overflow-style: none;
}

.display-none {
  display: none;
}

.warning-color {
  color: @warning-color;
}

.collapse-label {
  color: @text-color !important;
  font-weight: bold;
}

.width-50px {
  width: 50px;
}
