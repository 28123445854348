@import '../../../../styles/variables.less';

.filter-title {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  color: #ffffff;
  margin-right: 5px;
}
.header-datepicker.ant-picker.ant-picker-range {
  visibility: hidden;
  width: 0;
  margin: 0px;
  padding: 0px;
}
.ant-radio-group {
  .ant-radio-button-wrapper {
    align-items: center;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;

    color: #ffffff;

    padding: 0px 5px;
    width: 100px;

    border: none;
    border-radius: 0px;
    background-color: @primary-color;
    margin-bottom: 0px;

    img {
      margin-left: 10px;
    }
    &:active,
    &:focus {
      outline: none;
    }

    &::before {
      background-color: #0b40ba;
    }

    @media screen and (max-width: 1300px) {
      width: auto !important;
      padding: 0px 0.5rem !important;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: #ffffff;
    color: @primary-color;

    &::before,
    &::after {
      transition: background-color, color, padding 0.3s ease-in-out;
      display: none;
    }
  }
}

.footer-datepicker {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem 0px;
}
