@import '../../../styles/variables.less';

#floating-action-button-component {
  position: absolute;
  display: flex;
  align-items: center;
  place-content: center;

  margin: 0;

  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 30px !important;

  background: @primary-color;
  color: @white-color;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);

  &.right-bottom {
    bottom: 6px;
    right: 6px;

    &.num-2 {
      right: 54px;
    }
  }

  &.left-bottom {
    bottom: 6px;
    left: 6px;

    &.num-2 {
      left: 54px;
    }
  }
}
