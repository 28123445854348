@import '../../../styles/variables.less';

#onboarding-links-page {
  width: 100%;
  .header-onboarding-links {
    min-height: 200px;
    background: @white-color;
    border-bottom: 3px solid @primary-color;
    border-radius: 9px 9px 0px 0px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    padding: 2.5rem 1.9rem 2rem 2.2rem;

    .ant-col-8 {
      display: flex;
      border-left: 1px solid @gray-color-3;
      border-right: 1px solid @gray-color-3;
      height: 65px;
    }

    .ant-col-4 {
      align-items: center;
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: -6px;
      }
    }
  }

  .section-left {
    font-size: 16px;
    line-height: 28px;
    color: @text-dark;

    strong {
      font-weight: 800;
    }
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .border-top {
    flex-direction: column;
    display: flex;
    border: 1px solid @gray-color-3;
    width: 100%;
    margin: 1.5rem 1rem;
  }

  .subtitle-footer {
    font-weight: 800;
    font-size: 18px;
    line-height: 28px;
    color: @text-dark;
    padding-right: 20px;
  }

  .flex-align-center {
    display: flex;
    align-self: center;
    justify-content: flex-end;
  }

  .links-onboarding {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;

    label {
      span {
        color: @text-dark;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
    }

    .ant-form-item-control-input {
      margin-top: 10px;
    }
  }

  .listing-by-status {
    display: flex;
    align-items: center;
    margin-right: -15px;
  }

  .select-items {
    width: 80px;

    .ant-select-selection-item {
      background: @primary-color;
      border-radius: 17px;
      font-weight: 800;
      font-size: 12px;
      line-height: 14px;
      color: @white-color;
      justify-content: center;
      padding: 6px 10px 6px 10px;
      min-width: 80px;
      flex: 0;
      overflow: none;
      white-space: none;
      text-overflow: none;
      color: @white-color;
    }

    .ant-select-arrow {
      padding-left: 150px;
      font-size: 12px;
      font-weight: bold;
      color: @white-color;
    }
  }

  .list-wrapper {
    background: @white-color;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    width: 100%;
    min-height: 300px;
  }
}
