@import '../../../../../../styles/variables.less';

#executions-media-exposure-card {
  display: flex;
  flex-direction: column;

  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 15px;
  border-bottom: 1px solid @border-color;

  &:last-child {
    border-bottom: none;
  }

  .media-exposure-link {
    font-size: 16px;
    font-weight: 500;
  }

  span {
    display: block;
    margin-top: 4px;

    color: @text-color;
    font-size: 14px;
  }

  &:not(:first-of-type) {
    padding-top: 15px;
  }
}
