@import '../../../../../../../../../styles/variables.less';

#executions-generic-progress-container {
  position: relative;
  margin: 5px 0px;

  .ant-progress-line {
    margin: 0px !important;

    .ant-progress-outer {
      margin-right: 0px !important;
      padding-right: 0px !important;

      .ant-progress-bg {
        height: 12px !important;
        border-radius: 0px !important;
      }

      .ant-progress-inner {
        border-radius: 0px !important;
      }
    }
  }

  .ant-progress-line.first-progress-mark {
    margin: 0px !important;

    .ant-progress-outer {
      margin-right: 0px !important;
      padding-right: 0px !important;

      .ant-progress-bg {
        height: 12px !important;
        border-top-left-radius: 100px !important;
        border-bottom-left-radius: 100px !important;
      }
    }
  }

  .ant-progress-line.last-progress-mark {
    margin: 0px !important;

    .ant-progress-outer {
      margin-right: 0px !important;
      padding-right: 0px !important;

      .ant-progress-bg {
        height: 12px !important;
        border-top-right-radius: 100px !important;
        border-bottom-right-radius: 100px !important;
      }
    }
  }

  .generic-progress-marker {
    height: 40px;
    cursor: default;
    bottom: 35px;
    position: absolute;
  }

  .generic-progress-indicators {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 2px;

    span {
      color: @text-dark;
      font-weight: 500;
    }
  }
}
