@import '../../../../../../../styles/variables.less';

.pending-review-action-dropdown {
  .ant-select-item-option-content {
    color: @text-dark;

    i {
      font-size: 16px;
      font-weight: bold;
      margin-right: 12px;
      color: @text-dark;
    }
  }
}

#pending-review-modal-content {
  #pending-review-modal-form {
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;

    .pending-review-items {
      .ant-divider {
        &:last-child {
          display: none !important;
        }
      }
    }

    #card-recurrent-event {
      margin-bottom: 0 !important;

      > .ant-card-body {
        padding: 0;

        .ant-timeline-item .ant-timeline-item-content {
          margin: 25px 0 10px 25px;
        }
      }
    }

    #card-review {
      padding-top: 18px;
      padding-bottom: 18px;

      .approve {
        .ant-select-selector {
          background-color: @primary-color;
          border-color: @primary-color;

          .ant-select-selection-item {
            color: #fff !important;
            font-weight: 700;

            i {
              color: #fff !important;
            }
          }
        }

        .ant-select-arrow {
          color: rgba(#fff, 0.8);
          font-weight: 700;
        }
      }

      .reprove {
        .ant-select-selector {
          background-color: @danger-color;
          border-color: @danger-color;

          .ant-select-selection-item {
            color: #fff !important;
            font-weight: 700;

            i {
              color: #fff !important;
            }
          }
        }

        .ant-select-arrow {
          color: rgba(#fff, 0.8);
          font-weight: 700;
        }
      }

      .reprocess {
        .ant-select-selector {
          background-color: @info-color;
          border-color: @info-color;

          .ant-select-selection-item {
            color: #fff !important;
            font-weight: 700;

            i {
              color: #fff !important;
            }
          }
        }

        .ant-select-arrow {
          color: rgba(#fff, 0.8);
          font-weight: 700;
        }
      }

      .ant-form-item {
        width: 100%;
        display: inline-block !important;
        margin: 0;
        margin-bottom: 15px;

        .ant-form-item-label {
          label {
            font-size: 14px !important;
          }
        }

        &.select-reason {
          display: inline-block !important;
          width: 100% !important;

          label {
            font-size: 13px;
            font-weight: 400;
            line-height: 14px;

            &::after {
              display: none;
            }
          }

          .ant-form-item-control {
            max-width: 265px !important;

            .ant-select-selector {
              span {
                font-weight: 600;
                color: @black-color;
              }

              i {
                font-size: 18px;
                margin-right: 12px;
                color: @gray-color-2;
              }
            }
          }
        }

        &.reproval-reason {
          .ant-form-item-label {
            label {
              margin: 22px 0 8px;
            }
          }

          .ant-form-item-control {
            textarea {
              background: @gray-color-4;
              resize: vertical;
            }
          }
        }
      }

      .compliance-rules-status {
        span {
          font-weight: 700;
          font-size: 14.5px;
          color: @text-dark;
        }

        i {
          font-size: 20px;
          font-weight: bold;
        }

        div.ant-col-14 {
          font-size: 15px;
          padding-left: 8px;
        }
      }

      .ant-alert {
        padding: 8px 12px;

        .ant-alert-description {
          font-size: 12px;
          line-height: 14px;
          color: @info-color;
        }
      }

      .ant-divider {
        margin: 15px 0;
      }
    }
  }
}
