@import '../../../../../../../styles/variables.less';

.load-modal-infos {
  .ant-modal-content {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.compliance-rules-modal {
  .ant-modal-content {
    max-height: 85vh;
    overflow-y: auto;
  }

  .compliance-rules-modal-content {
    .page-title {
      margin-bottom: 40px !important;
    }
  }
  #compliance-rules-collapse {
    .ant-collapse-content {
      background-color: @gray-color-4;
    }
  }

  #profile-rules {
    margin-bottom: 32px;

    .rules {
      margin-top: 10px;
    }

    .items {
      color: @color-neutral-600;
      padding: 12px 16px;
      margin-top: 4px;
      background-color: #fafafa;
      border: 1px solid #eee;
      border-radius: 4px;
      font-size: medium;
    }
  }

  footer {
    margin-top: 30px;
    margin-bottom: 20px;

    i {
      font-size: 15px;
      font-weight: 600;
      color: @gray-color-1;
    }

    span {
      color: @gray-color-2;
      font-size: 14px;
      line-height: 25px;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  .history-consult-date {
    i {
      font-size: 15px;
      font-weight: 600;
      color: @gray-color-1;
      margin-left: 5px;
    }

    span {
      color: @gray-color-1;
      font-size: 14px;
      font-style: italic;
      margin-left: 5px;
    }
  }

  section {
    margin-top: 25px;
    .compliance-rules-status {
      span {
        font-weight: 700;
        font-size: 15.5px;
        color: @text-dark;
      }

      i {
        font-size: 20px;
        font-weight: bold;
      }

      div.ant-col-15 {
        font-size: 15px;
        padding-left: 20px;
      }
    }
  }
}
