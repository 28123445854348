#to-print-labor-information {
  #cndt-debts-items {
    margin-top: 10px;
    padding: 15px 30px;

    #page-title-component {
      margin-bottom: 20px !important;

      .title {
        font-size: 13.5px;
      }

      .subtitle {
        display: flex;
        margin-bottom: 0px;

        font-size: 10px;
      }
    }

    .cndt-debts-items-title {
      strong {
        font-size: 12px;
      }
    }

    .cndt-debts-data {
      #column-label-container {
        margin-bottom: 20px !important;

        .custom-label {
          .column-label-description {
            font-size: 11px !important;
          }
        }
      }
    }

    .cndt-debts-observation {
      font-size: 11px;
    }

    .ant-divider-horizontal {
      margin: 15px 0px;
    }
  }

  #personal-labor-and-financial-information-items {
    padding: 15px 30px;

    #page-title-component {
      margin-bottom: 15px !important;

      .title {
        font-size: 13.5px !important;
      }
    }

    .personal-labor-and-financial-information-data {
      padding: 15px 0px !important;

      > .ant-col {
        strong {
          font-size: 11.5px;
        }

        span {
          margin-top: 5px !important;
          font-size: 10.5px;
        }
      }

      .personal-labor-and-financial-information-type-infos {
        > span {
          margin-top: 0px !important;
          margin-bottom: 5px !important;
        }

        .status-div {
          font-size: 11.5px !important;

          .div-dot {
            width: 5.5px;
            height: 5.5px;
            min-width: 5.5px;
          }
        }
      }
    }
  }
}
