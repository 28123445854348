#to-print-qsa {
  #basic-infos-card {
    .basic-infos-content {
      #column-title-value-container {
        .title {
          margin-bottom: 0.4em !important;
        }

        .card-partners-content {
          .legal-representative-container {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            margin-bottom: 0.4em;

            span {
              font-size: 10px;
            }

            .legal-representative-tag {
              margin-left: 4px;
              padding: 0px 8px !important;

              font-size: 9px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
