@import '../../../../../../../styles/variables.less';

.titleModal {
  img {
    margin-right: 18px;
    margin-bottom: 5px;
    width: 25px;
    height: 25px;
  }
}
.iconLinkMedia {
  margin-top: 10px;
}
.showMoreAssociates {
  color: #004af7;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.linkModal {
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color: #004af7;
  font-style: normal;
  font-family: 'Roboto';
  text-decoration: underline;
}
.snippet {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
  padding-top: 10px;
  color: @text-dark;
}
.date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.titleMoreDetails {
  color: @text-dark;
  font-size: 17px;
  padding-bottom: 12px;
}
.labelMoreDetails {
  color: @text-dark;
  font-size: 14px;
  padding-bottom: 8px;
  white-space: nowrap;

  strong {
    margin-right: 5px;
  }
}
.apply-white-space-wrap {
  white-space: normal;
}

.content-overflow-modal {
  height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0px;
  padding-right: 15px;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 0px 6px 6px 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #9b9b9b;
    border-radius: 0px 6px 6px 6px;
  }
}
