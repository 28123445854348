@import '../../../../../../../styles/variables.less';

.validation-list-item {
  margin-left: 24px;
  padding-bottom: 8px;

  &:first-of-type {
    margin-top: 14px;
  }

  &:not(:first-of-type) {
    padding-top: 8px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid @border-color !important;
  }

  #trust-item header {
    cursor: default !important;
  }
}

.pending-button {
  background: @warning-color !important;
  color: #fff !important;
  margin: 20px auto 5px !important;

  &:hover {
    opacity: 0.8;
  }

  i {
    font-size: 19px;
  }
}

// @media (min-width: 1370px) {
//   .validation-list-item button {
//     font-size: 14px !important;
//   }
// }
