@import '../../../../../styles/variables.less';

#profile-model-compliance-rules-component {
  display: flex;
  flex-direction: column;

  .ant-card-body {
    padding: 30px;
    padding-left: 38px;
    padding-right: 25px;

    > * {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .ant-select-selection-item {
    color: @gray-color-1;
    font-weight: bold;
  }

  #empty-message-component .ant-empty {
    padding: 25px 15%;
    margin-bottom: 60px;
  }

  .ant-form-item-label {
    > label {
      display: flex;
      width: 100%;
    }
    padding-bottom: 12px;
    .custom-label-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      a:hover {
        border-bottom: 1px solid @primary-color;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .remove-button {
    height: 34px;
    width: 34px;
    padding: 0;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .first-row {
    .last-column {
      padding-top: 30px;
    }
  }

  #title-divider-container {
    .title-divider-tag {
      background: @gray-color-4;
      color: @text-dark;
      font-weight: 400;
      font-size: 13px;
    }
    .ant-divider {
      border-top-color: @gray-color-4;
    }
  }

  .ant-input.validation-title {
    color: @text-dark;
    cursor: default;
    background-color: #f5f5f5;

    &:disabled {
      border-color: #f5f5f5;
    }
  }

  .ant-select.validation-title {
    .ant-select-selection-item {
      color: @text-dark;
    }
  }

  .ant-switch {
    width: 72px;
  }

  .empty-custom {
    margin-bottom: 0 !important;
  }

  .REPROVE {
    // .ant-select-selector {
    //   background-color: @inverse-danger-color;
    //   border-color: rgba(@danger-color, 0.4);
    // }
    .ant-select {
      &:not(.ant-select-disabled) {
        &:hover {
          .ant-select-selector {
            border-color: lighten(@danger-color, 10%);
          }
        }
      }
    }
    .ant-select-selection-item {
      color: @danger-color;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: 0 0 0 2px rgba(@danger-color, 0.2);
      border-color: lighten(@danger-color, 10%);
    }
    .ant-select-clear {
      border-radius: 50%;
    }
  }
  .PROCESSING {
    // .ant-select-selector {
    //   background-color: @inverse-warning-color;
    //   border-color: rgba(@warning-color, 0.4);
    // }
    .ant-select {
      &:not(.ant-select-disabled) {
        &:hover {
          .ant-select-selector {
            border-color: lighten(@info-color, 10%);
          }
        }
      }
    }
    .ant-select-selection-item {
      color: darken(@info-color, 5%);
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: 0 0 0 2px rgba(@info-color, 0.2);
      border-color: lighten(@info-color, 10%);
    }
    .ant-select-clear {
      border-radius: 50%;
    }
  }

  .PENDING {
    // .ant-select-selector {
    //   background-color: @inverse-warning-color;
    //   border-color: rgba(@warning-color, 0.4);
    // }
    .ant-select {
      &:not(.ant-select-disabled) {
        &:hover {
          .ant-select-selector {
            border-color: lighten(@warning-color, 10%);
          }
        }
      }
    }
    .ant-select-selection-item {
      color: darken(@warning-color, 5%);
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: 0 0 0 2px rgba(@warning-color, 0.2);
      border-color: lighten(@warning-color, 10%);
    }
    .ant-select-clear {
      border-radius: 50%;
    }
  }
  .active-onboarding-qsa {
    color: @primary-color;
    font-weight: bold;
  }
}

.validations-action-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: @text-color;
    background-color: #fff;
  }
}

.switch-compliance-rules {
  width: 40px !important;
}

.custom-rule-border {
  border-bottom: 1px solid #e0e0e0;
}
