.left-side-dropdown {
  border: none;
  border-bottom: 1px solid #fff9;
  background: transparent;
}
.select-options {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dropdowns-container {
    display: inherit;

    .dropdown-menu {
      margin: 0 8px;
    }
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-weight: bold;
  }
}
.see-more-content {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.export-options {
  justify-content: space-between;
  padding: 5px 15px 5px 15px;
  margin-bottom: 1rem;
}
