.pgfn-remark-details {
  .ant-btn {
    height: auto;
    line-height: 20px;
  }

  .ant-collapse-header {
    display: none;
  }

  .ant-collapse-content-box {
    padding: 0px;
  }
}
