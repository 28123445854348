#validations-images-card {
  margin-bottom: 25px !important;

  &.custom-top {
    margin-top: 20px !important;
  }

  .validation-content-image-container {
    .ant-card-body {
      padding: 0 !important;
    }

    .ant-card {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;
      margin: 0px !important;

      overflow: hidden;
      position: relative;

      span {
        font-size: 12px;
        color: #fff;
        letter-spacing: 0;

        padding: 2px 12px;
        background: rgba(0, 0, 0, 0.6);
        border-bottom-right-radius: 8px;

        top: 0;
        left: 0;
        position: absolute;
      }

      img {
        width: 100%;
        height: 100%;
        max-height: 450px;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }
  }
}
