@import '../../../styles/variables.less';

.dashboard-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  break-inside: avoid;
}
#dashboard-component {
  display: flex;
  flex-direction: column;

  padding-top: 24px !important;
  .dashboard-cards {
    .ant-card {
      margin: 10px;
    }

    .ant-card-body {
      width: 100%;
    }

    .ant-row {
      > .ant-col {
        display: flex;

        > .ant-card {
          flex: 1;
          overflow: hidden;

          > .ant-card-body {
            display: flex;
            flex: 1;
            flex-direction: column;
          }
        }
      }
    }

    .dashboard-card-header {
      margin-bottom: 25px;

      h1.ant-typography {
        margin-bottom: 0px;

        color: @text-dark;
        font-size: 21px;
      }
    }

    .loading-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin: auto;
      height: 150px;

      .loading-description {
        display: inline-flex;
        margin-top: 4px;

        font-size: 15px;
      }
    }

    #empty-message-component {
      margin: auto !important;

      .ant-empty {
        padding: 15px 0px !important;
      }

      .ant-empty-image {
        margin-bottom: 12px;
      }

      .ant-empty-description {
        font-size: 15px;
      }
    }

    a {
      &:hover {
        background-color: @hover-color-default;

        span {
          color: @text-dark;
        }
      }
    }

    .ant-pagination {
      .ant-pagination-prev,
      .ant-pagination-next,
      .ant-pagination-item {
        min-width: 12px;
        height: 12px;
        line-height: 12px;
      }

      .ant-pagination-item {
        margin: 0 2px;

        a {
          padding: 0;
          color: transparent !important;
        }

        &:not(.ant-pagination-item-active) {
          background-color: darken(@gray-color-4, 5%);

          &:hover {
            background-color: rgba(@primary-color, 0.3);
          }
        }
      }

      a {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}

.query-status-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.query-status-wrapper h5 {
  padding: 0 10px;
  color: @text-dark;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  margin-top: -15px;
  display: flex;

  span {
    color: @primary-color;
    margin-left: 5px;
  }
}

@media (max-width: 1200px) {
  .query-status-wrapper h5 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
  }
}

.onboarding-links-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  aside {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    height: 80%;

    div {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h5 {
        font-weight: 800;
        font-size: 12px;
        color: @text-dark;
      }

      span {
        font-weight: 700;
        margin-top: -10px;
        font-size: 20px;
        color: @primary-color;
      }
    }
  }
}

.funnel-adornment-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .funnel-template-config {
    display: flex;
    align-items: center;

    .funnel-template-config-select {
      padding-left: 15px;
      margin-left: 15px;
      border-left-width: 2px;
      border-left-style: solid;
      border-left-color: @primary-color;

      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin: 0px;
        font-size: 14px;
        font-weight: 600;
        color: @primary-color;
      }

      @media screen and (max-width: 1040px) {
        padding-left: 5px;
        margin-left: 5px;

        p {
          font-size: 12px;
        }
      }
    }
  }
}

.more-info {
  color: @primary-color;
  font-size: medium;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  p {
    margin: 0px;
  }

  svg {
    font-size: 22px;
  }

  @media screen and (max-width: 1040px) {
    font-size: small;

    svg {
      font-size: 18px;
    }
  }
}

.buttons-adornment {
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    font-size: 16px;
    cursor: pointer;
    color: @text-dark;
  }
}

.onboarding-links-adornment-tooltip-title {
  color: @text-dark;
  margin: 0px;
}
