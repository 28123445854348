@import '../../../../../styles/variables.less';

.column-information {
  .column-information-title {
    font-size: 15px;
    margin-bottom: 22px;
    color: @gray-color-1;
  }
  .column-information-fields {
    color: @text-dark;

    .fields-col {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
    }

    .column-information-label {
      font-size: 16px;
      margin-bottom: 8px;
    }

    .column-information-value {
      font-size: 18px;
    }
  }
}
.render-sports-background {
  color: @text-dark;
  background-color: @color-neutral-100;
  border-radius: 8px;
  border: 1px solid @color-neutral-300;
  padding: 16px;
  margin: 5px;
  margin-bottom: 20px;

  .related-sports-title {
    color: @gray-color-1;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 24px;
    margin-top: 8px;

    display: flex;
    align-items: center;
  }
}
