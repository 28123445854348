@import '../../styles/variables.less';

.confirm-action {
  .activate-confirm-title {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .activate-confirm-description {
    display: inline-block;
    color: @text-color;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;

    strong {
      color: @gray-color-1;
    }
  }

  .activate-confirm-btns {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin-top: 30px;
  }

  .disabled-onboarding-links {
    margin: 20px auto;
    max-width: 90%;
  }
}
