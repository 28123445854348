@import '../../styles/variables.less';

.card-skeleton {
  background: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;

  &.no-data {
    background: #f1f5ff;
  }
}

.heading-skeleton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 30px;
  justify-content: space-between;
  z-index: 4;
}

.card-info-skeleton {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .title {
    white-space: nowrap;
    color: @text-dark;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    margin: 0;
    margin-right: 0.5rem;
  }
}

.padding-children {
  padding-left: 27px;
}

.custom-heading {
  width: 100%;
  height: 100%;
}

.custom-heading-visible {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.select-graph-content-options {
  border: none;
  background: none;
  font-weight: 400;
  font-size: 14px;
  color: @text-dark;
  cursor: pointer;
  border-bottom: 1px solid #bdbdbd;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding: 0;
  white-space: nowrap;
}

.wrapper-button-and-icon {
  margin-left: 15px;
  display: flex;
  margin-top: 2.5px;
  justify-content: flex-end;

  img {
    margin-left: 5px;
  }
}

@media (max-width: 1200px) {
  .card-info-skeleton {
    white-space: normal;
  }
  .heading-skeleton {
    padding: 10px;
  }
}

.card-children {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  // margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 16px;
    cursor: pointer;
    color: @text-dark;
  }
}

.tooltip-message {
  color: white;
  margin: 0;
}
