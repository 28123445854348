@import '../../../../../../../styles/variables.less';

.kyb-company-info-main {
  .KYB-tab-title {
    margin-bottom: 32px;
  }
  .kyb-company-popover-button {
    margin-left: 12px;
  }
}
.kyb-company-info-title-buttons {
  display: block;
  margin-bottom: 8px;
}
.kyb-company-info-supplier-group-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  > .ant-radio-button-wrapper {
    width: fit-content;
    height: auto;
    padding: 2px 14px;
    border-radius: 4px;
    border: 1px solid @gray-color-1;
    text-align: center;
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: @color-neutral-500;
    background-color: @color-neutral-100;
    &:before {
      background-color: transparent;
    }
    &:focus-within {
      box-shadow: none;
    }
    &.ant-radio-button-wrapper-checked {
      border: 1px solid @color-caf-blue-main;
      color: @color-caf-blue-main;
      background-color: @color-caf-blue-lighter;
    }
  }
}
.kyb-company-info-content {
  .typescale-subtitle-1 {
    margin-bottom: 0;
  }
}
.kyb-company-info-section,
.kyb-company-info-article {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.kyb-company-info-section {
  padding: 24px 0;
  border-bottom: 1px solid @color-neutral-400;
}
.kyb-company-info-article {
  > div {
    flex: auto;
  }
}
.kyb-company-info-full {
  flex: 1;
}
@media (min-width: @screen-lg-min) {
  .kyb-company-info-article {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px 64px;
    > div {
      flex: none;
      flex-basis: fit-content;
      min-width: 120px;
    }
  }
}
