@import '../../../../../styles/variables.less';

#phone-score-id {
  .col-ant {
    padding: 5;
  }
  .divider-ant {
    padding: 5;
    margin-left: 15px;
    margin-right: 15px;
  }
  .list-subtitle {
    color: @text-dark;
    font-size: 15px;
  }
  .text-style {
    color: @text-dark;
  }
  .span-style {
    margin-right: 10px;
  }
}
