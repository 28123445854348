@import '../../../../../styles/variables.less';

#query-price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // max-width: 250px;
  padding: 0 18px;
  height: 40px;

  border-radius: 8px;
  background: #f2f2f2;

  span {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid @border-color-2;

    font-size: 12px;
    color: @text-dark;
    // text-align: end;
    text-transform: uppercase;
  }

  strong {
    color: @accent-color;
    font-size: 14px;
    font-weight: 700;
  }
}
