@import '../../../../../styles/variables.less';

#generate-access-key-component {
  .titleDiv {
    color: @text-color;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .ant-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    .ant-checkbox {
      margin-top: 20px;
    }
  }

  .ant-checkbox-wrapper-disabled {
    opacity: 0.5;
  }

  .checkbox-custom-label {
    display: flex;

    div.icon {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;
      margin-left: 5px;
      margin-right: 10px;

      background-color: @light-primary-color;
      border-radius: 50%;

      i {
        color: @text-dark;
        font-size: 24px;
      }
    }

    div.details {
      strong {
        color: @text-dark;
        font-size: 15px;
        font-weight: 700;
      }

      span {
        display: block;
        margin-top: 5px;

        color: @text-color;
        font-weight: normal;
      }
    }
  }
}
