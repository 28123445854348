@import '../../../../../../../../../styles/variables.less';

.compliance-rule-details-popover {
  min-width: 304px;

  .ant-popover-inner-content {
    padding: 16px !important;
  }

  .doc-side-check-container {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .popover-title {
    h3 {
      color: @gray-color-1;
      font-weight: bold;
      font-size: 16px;
      margin: 0px;
    }

    margin-bottom: 6px;
  }

  .check-list {
    .check-item:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
