@import '../../../../../../styles/variables.less';

#access-keys-card-component {
  .ant-card-body {
    padding: 0;
  }
  .cells {
    display: flex;
    flex-direction: column;

    .ant-typography {
      margin: 0;
      word-break: break-all;
    }
  }

  .validation-tag {
    display: inline-block !important;

    margin: 5px;
    padding: 8px 10px;

    background-color: @white-color;
    border: 1px solid @gray-color-3;
    border-radius: 8px;

    > i {
      color: @text-dark;
    }

    #tag-component {
      display: inline;
      margin-left: 5px;
    }
  }
}
