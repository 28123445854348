@import '../../styles/variables.less';

#empty-state-card {
  width: 100%;
  // margin: 10px 0px;
  margin-bottom: 10px;

  padding: 10px;

  border: 1px solid @border-color;
  background: #f9f9f9;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 20px;

    i {
      font-size: 45px;

      &.caf-ic_check_d {
        color: @success-color;
      }

      &.caf-ic_processing_line {
        color: @info-color;
      }

      &.caf-ic_x_close {
        color: @danger-color;
      }

      &.caf-ic_alert-triangle {
        color: @gray-color-2;
      }

      &.caf-ic_clock {
        color: @gray-color-2;
      }
    }

    strong {
      display: block;
      margin-top: 15px;

      text-align: center;

      color: @gray-color-1;
      line-height: 1.3;

      font-weight: 500;
      font-size: 15px;

      max-width: 80%;
    }
  }

  .empty-state-btn {
    cursor: pointer;
    color: @primary-color;
  }
}
