@import '../../../../../../../styles/variables.less';

#confirmation-base-card-component {
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    color: @text-dark-secondary;
    margin-bottom: 0;
    font-size: 21px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 12px;
    color: @info-color;
    display: block;
    margin-bottom: 16px;
  }

  .ant-card {
    display: flex;
    flex-direction: column;
    flex: 1;

    margin-bottom: 0 !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;

    background-color: #fff;

    span,
    p,
    i {
      color: @text-dark-secondary;
    }
  }

  .ant-btn {
    width: 100%;
    border-radius: 8px !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-color: @border-color;
    text-shadow: none;
    height: 48px;
    color: @info-color;
    background-color: #fff;

    &:hover {
      background-color: @gray-color-5;
    }
  }
}
