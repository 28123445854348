@import '../../../../../../styles/variables.less';

#general-status-card {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 20px;

  border-radius: 6px;

  .general-status-info {
    display: flex;
    align-items: flex-start;

    margin: 20px;

    .general-status-icon {
      font-size: 2.45rem;
      margin-right: 15px;
      color: #fff;
    }

    .general-status-description {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      color: #fff;

      strong {
        display: block;
        margin-bottom: 3px;

        font-weight: 900;
        font-size: 16px;
      }

      span {
        font-size: 13px;
      }
    }
  }

  .general-status-actions {
    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(100% - 40px);
    margin-top: -5px;
    border-top: 1px solid #fff;

    .ant-btn-text {
      width: 100% !important;
      height: 38px !important;
      margin: 0px !important;
      padding-right: 0px !important;
      padding-left: 0px !important;
      font-size: 14px !important;
      color: #fff !important;
      font-weight: 500 !important;

      &:first-of-type {
        border-right: 1px solid #fff;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
}
