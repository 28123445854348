@import '../../../../../../../../styles/variables.less';

#legal-information-processes-container {
  display: flex;
  flex-direction: column;

  .legal-information-processes-title {
    // margin-top: 40px;
    padding-bottom: 15px;

    border-bottom: 1px solid @border-color;

    .ant-col {
      padding-left: 0px;
      padding-right: 0px;

      // &:last-of-type {
      //   text-align: end;
      // }
    }

    strong {
      font-size: 16px;
      color: @text-dark;
    }
  }

  .legal-information-processes-btn {
    display: flex;

    height: auto;
    width: 100%;

    margin: 0px;
    padding: 0px;

    &:hover {
      opacity: 0.7;
    }

    .legal-information-processes-data {
      display: flex;
      align-items: center;

      width: 100%;

      padding-bottom: 20px;

      color: @text-dark;

      margin-top: 20px;

      border-bottom: 1px solid @border-color;

      .ant-col {
        &:not(:last-of-type) {
          padding-left: 0px;
        }

        &:last-of-type {
          text-align: end;
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      .process-data-description {
        display: flex;
        flex-direction: column;
        padding-right: 30px;

        span {
          margin-top: 8px;
        }
      }

      .process-data-condition {
        padding-right: 15px;
        font-weight: 500;

        .div-dot {
          margin-right: 8px !important;
        }
      }
    }
  }
}
