#settings-styled {
  .row-spaced {
    margin-top: 30px;
    margin-left: 0px;
  }
  .button-save {
    margin-top: 15px;
    float: right;
  }
}
