@import '../../../../../styles/variables.less';

.invoice-details {
  p {
    color: @text-dark;
  }

  .ant-list-header {
    border-bottom: 2px solid #d1d1d1 !important;
  }
}

.invoice-details__header {
  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
    text-align: left;
  }
}

.invoice-details__item {
  display: grid;
  grid-template-columns: 1.1fr 0.9fr 1fr;

  &_hide-price {
    grid-template-columns: 1.8fr 0.2fr;
  }

  @media (max-width: 1350px) {
    grid-template-columns: 1.5fr 0.5fr 1fr;

    &_hide-price {
      grid-template-columns: 1.7fr 0.3fr;
    }
  }

  @media (max-width: 870px) {
    grid-template-columns: 1.8fr 0.2fr 1fr;

    &_hide-price {
      grid-template-columns: 1.7fr 0.3fr;
    }
  }

  &_break-word {
    max-width: calc(100% - 20px);
    overflow: hidden;
    overflow-wrap: break-word;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: left;
    margin: 0;

    &:last-child {
      text-align: right;
    }
  }
}

.invoice-details__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
}

.invoice-details__footer {
  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }
}

@media print {
  .invoice-details__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }

  .invoice-details__item {
    grid-template-columns: 1.2fr 0.8fr 1fr;
  }
}
