@import '../../../../../styles/variables.less';

#menu-item-notifications-center {
  height: 100%;

  margin: 0px;
  padding: 0px;

  transition: background 0.2s linear;

  &.unread {
    background: transparent;
  }
  // &:hover {
  //   background: #f2f2f296;
  // }

  .notification-item {
    display: flex;
    flex-direction: column;

    padding-bottom: 0px;
    border-bottom: 0;

    span,
    strong {
      white-space: normal;
      line-height: 1.4;
    }

    .notification-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: @text-dark;
        font-weight: 500;
        font-size: 14.5px;
      }

      .col-button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        .loading-icon {
          font-size: 16px;
        }

        .caf-ic_eye {
          color: @gray-color-1;
        }

        .caf-ic_check_d {
          color: @primary-color;
        }

        &:hover {
          .caf-ic_eye {
            color: @text-dark;
          }

          .caf-ic_check_d {
            opacity: 0.7;
          }
        }
      }
    }
  }
  .notification-description {
    display: block;
    margin: 5px 0px;
  }

  .notification-download-file {
    display: flex;
    align-items: center;
    justify-content: left;

    padding: 0px;
    margin: 10px 0px 10px;

    color: @info-color;

    border-radius: 0px;
    background: transparent;
    text-decoration-line: underline;

    i {
      font-size: 21px;
      color: @info-color;

      margin-right: 10px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .notification-expired-file {
    display: flex;
    align-items: center;
    justify-content: left;

    padding: 0px;
    margin: 10px 0px 10px;

    color: @danger-color;

    border-radius: 0px;
    background: transparent;

    i {
      margin-right: 6px;
      font-size: 18.5px;
      color: @danger-color;
    }
  }

  .card-new-notification-model {
    display: flex;
    flex-direction: column;
    align-items: fle x-start;
    width: 100%;

    .content {
      padding: 24px;
    }

    .ant-card-body {
      width: 100%;
      padding: 0 !important;
    }

    .card-new-notification-header {
      width: 100%;

      .title {
        display: block;
        margin-bottom: 2px;

        font-size: 12px;
      }

      .subtitle {
        color: @text-dark;
        font-size: 18px;
      }
    }

    .card-new-notification-table-infos {
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .ant-col {
        font-size: 14px;
      }

      .ant-col:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;

        strong {
          color: @text-dark;
        }

        i {
          font-size: 18px;

          &.caf-ic_checkmark {
            color: @primary-color;
          }

          &.caf-ic_close {
            color: @danger-color;
          }
        }
      }

      .ant-col:last-child {
        padding-left: 0 !important;
      }
    }

    .action-description {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      width: 100%;
      margin-top: -10px;

      .subtitle {
        display: inline-flex;
        margin: 10px 10px 0px 0px;
      }

      .card-execution-tag {
        margin: 10px 0px 0px !important;
        max-width: initial !important;

        white-space: normal !important;

        span {
          display: -webkit-box;
          max-width: initial !important;

          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;

          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .action-tag {
        margin: 10px 0px 0px !important;
        background: rgba(@gray-color-2, 0.1);

        white-space: normal !important;

        span {
          font-size: 14px;
        }

        i {
          margin-right: 2px;
          font-size: 15px;
        }
      }
    }
    .added-span {
      color: @primary-color;
      line-height: 1.7;
    }
    .removed-span {
      color: @error-color;
      line-height: 1.7;
      span {
        text-decoration: line-through !important;
      }
    }
    .updated-span {
      //color: @info-color;
      line-height: 1.7;
    }
    .subtitle-span {
      font-weight: 700;
      font-size: 16px;
      line-height: 2;
    }
    .info-link {
      color: @info-color;
      a {
        color: @info-color;
      }
    }

    .create-link {
      color: @primary-color;
      a {
        color: @primary-color;
      }
    }

    .removed-link {
      color: @error-color;
    }

    .border-divider {
      border-top: 1px solid #e0e0e08f;
    }

    .link-transaction-template {
      color: @text-dark;
      span {
        text-decoration: underline;
        font-style: italic;
      }
      span:hover {
        color: @info-color !important;
      }
    }
  }
}
