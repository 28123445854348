@import '../../../../../../../styles/variables.less';

.kyb-relationship-list-item {
  padding: 6px 24px;
  border: 1px solid @color-neutral-300;
  border-radius: 8px;
  & + & {
    margin-top: 24px;
  }
}
.kyb-relationship-list-inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: 24px;
  color: @text-dark;
  > div {
    display: flex;
    align-items: center;
  }
  > .kyb-relationship-list-type {
    margin-left: -24px;
  }
}
.kyb-relationship-list-type {
  &:not(:first-child) {
    margin-right: 8px;
  }
}
.kyb-relationship-list-arrow {
  margin: 0 8px -4px 8px;
}
.kyb-relationship-list-path {
  display: flex;
  .relationship-popover-container {
    display: flex;
    align-items: center;

    .kyb-relationship-list-info {
      margin-left: 12px;
    }
  }
}

.kyb-relationship-list-info,
.kyb-relationship-list-field {
  white-space: nowrap;
  .hint {
    margin-left: 5px;
  }
}
.kyb-relationship-list-type,
.kyb-relationship-list-path {
  padding: 8px 0;
}
.kyb-relationship-list-icon {
  font-size: 24px;
}
.kyb-relationship-risk-img {
  margin-left: 8px;
}
.kyb-relationship-risk-list {
  padding: 0 24px;
  list-style: unset;
  > li {
    margin: 12px 0 0;
    > b {
      color: @text-dark;
    }
  }
  #tag-component {
    display: inline-block;
    margin-left: 12px;
  }
}
