@import '../../../../../../../../../../../styles/variables.less';

#search-executions-modal{
   margin-bottom: 15px;
   header {
     margin-bottom: 3rem;

     strong{
        color: @text-dark;
        font-size: 2rem;
     }
     p{
        margin-top: 12px;
        font-size: 1rem;
     }
   }
   section{
        div{
            margin-bottom: 8px;
        }
   }
   footer{
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
   }
   .button-clear{
      color: @text-dark !important;
      font-weight: 700;
   }
   .button-submit{
      background-color: #004AF71F !important;
      color: #004AF7 !important;
      border: none;
   }
}