@import '../../../styles/variables.less';

#rectangle-button-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 192px;
  min-width: 140px;

  padding: 18px 20px;

  background: @white-color;

  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  text-shadow: none;

  border-radius: 8px;
  border: 1px solid @border-color-2;

  white-space: pre-line !important;
  word-wrap: break-word;

  .content-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 20px;
    margin-right: 15px;

    background-color: #d1d1d1;
    color: #fff;

    // custom
    &.caf-ic_company,
    &.caf-ic_car {
      padding-bottom: 2px;
    }

    &.caf-ic_combateafraude,
    &.caf-ic_onboardind_big,
    &.caf-ic_faceid {
      padding-left: 1px;
    }

    &.caf-ic_match {
      font-size: 18px;
    }

    transition: background-color 0.3s ease-in-out;
  }

  .content-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;

    span {
      text-align: left;

      font-weight: 400;
      color: lighten(@text-dark, 22%);
      margin-bottom: 5px;
      font-size: 13px;

      &:first-child {
        font-weight: 500;
        color: @text-dark;
        margin-bottom: 7px;
        font-size: 16px;
      }
    }
  }

  &.selected {
    // border: 2px solid @primary-color;
    border-color: @primary-color;
    // padding: 14px 17px;
    background: @light-primary-color;

    &::after {
      border-color: @primary-color;
    }

    .content-icon {
      background-color: @primary-color;
    }
  }

  .ant-btn-loading-icon {
    position: absolute;
    font-size: 35px;
    color: @info-color;
  }

  &:hover {
    border-color: @primary-color;
  }

  &.danger {
    i {
      background: @light-danger-color;
      color: @danger-color;
    }

    &:hover {
      border: 2px solid @danger-color;
      background: @light-danger-color;

      i {
        background: @danger-color;
        color: @white-color;
      }
    }
  }

  &:disabled {
    background-color: transparent;

    // i {
    //   background-color: @gray-color-1;
    // }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border-color: @border-color;
    }

    &.selected {
      border-color: @primary-color;
      background: @light-primary-color;
    }

    &:not(.selected) {
      .content-icon {
        background-color: @gray-color-1;
      }
    }
  }

  &.coming-soon {
    .content-icon {
      background-color: @gray-color-1;
    }
  }

  .coming-soon-tag {
    margin: 0px 10px;
    font-size: 11px !important;
    background-color: @warning-color;
    color: #fff !important;
    padding: 3px 8px;
    border-radius: 50px;
    margin-top: 4px;
    font-weight: 500 !important;
  }

  &.vertical {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 20px;

    .content-icon {
      width: 56px;
      height: 56px;
      font-size: 24px;
      margin: 0;
      margin-bottom: 12px;
    }

    .content-text {
      align-items: center;
      justify-content: center;
      flex: 0;

      span {
        text-align: center;
        color: @text-color-secondary;

        &:first-child {
          color: @text-dark;
        }

        &:not(:first-child) {
          margin-bottom: 0;
        }
      }
    }

    .coming-soon-tag {
      margin-top: 5px;
    }
  }
}
