@import '../../../styles/variables.less';

.accounts-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content-container {
    background-color: #ffffff;
    width: 800px;
    height: 70%;
    align-content: center;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.8);

    .title-container {
      max-height: 80%;
      margin-top: 40px;
    }

    .list-container {
      width: 100%;
      height: 80%;
      max-height: 80%;
      margin-top: 20px;

      .ant-list {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px 0;

        .ant-list-item {
          .ant-card {
            display: flex;
            border: 1px solid #d9d9d9;
            justify-content: space-between;

            &.selected {
              border: 1px solid @primary-color;
            }
          }
        }
      }
    }

    .buttons-container {
      margin-top: 40px;
      align-self: flex-end;

      .ant-btn-secondary {
        margin-right: 8px;
      }
    }
  }
}
