@import '../../../../../../styles/variables.less';

#profile-model-price-widget-component {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex: 0;

  span,
  strong {
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding: 16px 24px;
    background: @gray-color-4;
    border-radius: 8px;

    // width: 300px;

    > span {
      // width: 120px;
      color: @text-dark;
      text-align: end;
      padding-left: 10px;
      padding-right: 20px;
      margin-right: 10px;
      // border-right: 1px solid #d9d9d9;
    }

    strong {
      font-size: 28px;
      font-weight: 700;
      line-height: 1.2;
    }
  }

  > span {
    // width: 300px;
    text-align: center;

    margin-top: 10px;

    &.calculated-price {
      margin-top: 0;
      text-align: right;
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span,
    strong {
      color: @accent-color;
      text-align: end;
    }

    span {
      font-size: 12px;
      padding-top: 3px;
    }
  }

  .warning {
    font-weight: 300;
    font-size: 12px;
    padding: 0 20px;
  }
}
