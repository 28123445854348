@import '../../../../styles/variables.less';

.sidebar-menu {
  margin-top: 4vh;
}

.sidebar-submenu-title {
  font-size: 0.6rem;
  font-weight: 600;
  color: @header-text-color;
  pointer-events: none;
}

.sidebar-menu-item {
  display: flex;
  align-items: center;
  width: 100%;

  span {
    color: @header-text-color;
    font-size: 0.9rem;
  }

  svg {
    color: @primary-color;
    width: 27%;
    font-size: 1.8rem;
    margin-left: 7%;
  }

  &.ant-menu-item-selected {
    background-color: rgba(@primary-color, 0.1);
  }

  &.ant-menu-submenu {
    flex-direction: column;
    justify-content: center;
  }

  &.collapsed {
    svg {
      width: 100%;
      font-size: 1.7rem;
      margin-left: 0px;
    }
  }
}

.sidebar-submenu-sub-item {
  padding: 1rem 0px;

  span {
    font-weight: 100;
  }

  svg {
    width: 20%;
    font-size: 1.5rem;
    margin-left: 0px;
  }

  &.collapsed {
    span {
      margin-left: 0.5rem;
      width: 80%;
    }

    svg {
      width: 15%;
      font-size: 1.4rem;
    }
  }
}

.submenu-wrapper {
  background-color: red;
}

.sidebar-menu-item-active,
.sidebar-submenu-item-active {
  background-color: rgba(@primary-color, 0.1);

  span {
    font-weight: 600;
  }
}

.sidebar-menu-item-active {
  @border-width: 3px;

  border-left: @border-width solid @primary-color;

  svg {
    margin-left: calc(7% - @border-width);
  }

  &.collapsed {
    svg {
      margin-left: -@border-width;
    }
  }
}
