@import '../../../../../styles/variables.less';

#execution-subheader {
  .gx-header-horizontal-top {
    padding: 0 0;
    .gx-container {
      margin-top: 0px;
      margin-bottom: 40px;
      padding: 0 0;
      width: auto !important;
    }
  }

  .custom-horizontal-pdd {
    padding-top: 30px;

    &.retract {
      padding-bottom: 20px;
    }
  }

  .execution-subheader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .profile-info {
    flex: 1;
    padding-right: 10px;

    transition: all 0.2s ease-in-out;
  }

  .profile-name {
    color: @header-text-color;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 2px;

    width: fit-content;
    word-break: break-word;
  }

  .profile-last-update {
    font-size: 13px;
    font-weight: 400;
    color: @text-dark;
  }
  .beadcrums-color {
    color: @text-dark !important;
  }

  .ant-breadcrumb-separator {
    color: @text-dark;
  }
  .actions-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @text-dark!important;

    padding: 0px 20px !important;

    background: transparent;
    border: 1px solid rgba(@text-dark, 0.8);

    strong {
      color: @text-dark;
      font-weight: 500;
      font-size: 15px;
    }

    .icon-color {
      color: @text-dark;
    }

    &:hover,
    &:focus {
      border-color: @primary-color;
      background: rgba(@text-dark, 0.05);
    }

    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover,
    &.ant-btn[disabled]:focus,
    &.ant-btn[disabled]:active,
    &.ant-btn::before {
      background: transparent !important;
    }

    &.ant-btn-loading {
      border: none;
      padding: 0px 25px !important;

      .ant-btn-loading-icon {
        color: @text-dark;

        .anticon {
          font-size: 25px;
        }
      }
    }
  }
}
