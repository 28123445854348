@import '../../../../../styles/variables.less';

.executions-processes-details-modal {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 25px;
  }

  .subtitle {
    display: block;
    margin-top: 5px;

    font-size: 15px;
  }

  .processes-details-infos {
    .ant-col {
      display: flex;
      flex-direction: column;

      padding-left: 0px;
      padding-right: 0px;

      margin-bottom: 15px;

      color: @text-dark;

      strong {
        font-size: 15px;
        margin-bottom: 5px;
      }

      span {
        font-size: 15px;
      }
    }
  }

  .processes-details-parties-title {
    .ant-col:last-of-type {
      text-align: end;
    }

    strong {
      font-size: 16px;
      color: @text-dark;
    }
  }

  .processes-details-parties-data {
    display: flex;
    align-items: center;

    width: 100%;

    padding-bottom: 20px;

    color: @text-dark;

    margin-top: 5px;

    & + .processes-details-parties-data {
      margin-top: 20px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid @border-color;
    }

    .ant-col {
      display: flex;
      flex-direction: column;

      padding-left: 0px;

      strong {
        margin-bottom: 5px;
      }

      &:last-of-type {
        text-align: end;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}
