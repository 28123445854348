.image-patterns-container {
  img {
    height: 100%;
    width: 100%;
    max-height: 100px;
    max-width: 100px;
    object-fit: contain;
    object-position: left;
  }
}
