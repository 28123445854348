@import '../../../../../../../styles/variables.less';

.kyb-company-details-print-modal {
  .ant-checkbox-wrapper {
    margin-left: 0;
    margin-right: 8px;

    .check-box-text {
      display: inline-flex;
      width: max-content;
    }
  }

  [id='companyPrintCheckboxes.root.items.people.items.businessIdentity'] {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
}

.print-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;

  button:nth-child(1) {
    background-color: white;
    color: @text-dark;
    border: none;
    width: 121px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
  }

  button:nth-child(2) {
    background-color: @primary-color;
    color: white;
    width: 121px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
  }
}
