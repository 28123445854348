.view-more-content-paragraph {
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 1.5;
  text-overflow: ellipsis;
  line-break: auto;
  word-break: break-word;

  &:not(.all-text){
    max-height: 150px;
  }

  &.all-text {
    height: 100%;
  }
}
