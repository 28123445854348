@import '../../../../../../styles/variables.less';

.sso-form {
  max-width: 60%;
  margin-bottom: 60px;
}

@media screen and (max-width: 1100px) {
  .sso-form {
    max-width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .sso-form__button {
    span {
      display: none;
    }
  }
}

.sso-form__input-wrapper {
  margin-bottom: 0 !important;
}

.sso-form__input-wrapper
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  display: flex;
  gap: 10px;
}

.sso-form__input_no-margin-bottom {
  margin-bottom: 0 !important;
}

.sso-form__input {
  width: 80%;
}

.sso-form__input_hint {
  font-size: 12px;
  color: #828282;
}

.sso-form__button {
  --icon-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border: 1px solid @primary-color !important;

  // hover & not disabled
  &:hover:not(:disabled) {
    background-color: @primary-color !important;
    color: white !important;
  }

  // disabled
  &:disabled {
    border: 1px solid #d9d9d9 !important;
  }

  padding: 15px !important;

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
  }
}

.sso-form__domain-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  max-height: 150px;
  overflow-y: scroll;
}

.sso-form__domain-list-item {
  color: white;
  background-color: @primary-color;
  padding: 2px 8px;
  border-radius: 28px;
  width: min-content;

  display: flex;
  align-items: center;
  gap: 5px;
}

.sso-form__remove-domain-button {
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.sso-form__submit-button {
  position: absolute !important;
  bottom: 0;
  right: 0;
}
