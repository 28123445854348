.workflow-container {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    display: block;
    padding: 0;
  }
}
