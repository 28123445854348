@import '../../../../../styles/variables.less';

#internal-restrictive-lists {
  .title-text {
    color: @text-dark;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .ant-col-lists {
    margin-left: 0 !important;
    padding: 0;
  }
}
