@import '../../styles/variables.less';

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 2rem;
  max-width: 100%;

  .header {
    text-align: center;
    position: sticky;
    top: 0;
    left: auto;
    width: 100% !important;
    height: 50px;
    padding: 0px;
    margin-bottom: 40px;
    box-shadow: none;
    background: none;
    max-width: 100%;
  }

  .header-opacity {
    height: 32px;
    width: 100% !important;
    opacity: 0.8;
    z-index: 5;
    background-color: #f9f9f9;
  }

  @media screen and (min-width: @screen-md-min) {
    .header,
    .header-opacity {
      width: (@screen-md-min - 48); //720
    }
    padding: 0px 0.2rem;
  }

  @media screen and (min-width: @screen-lg-min) {
    .header,
    .header-opacity {
      width: (@screen-lg-min - 72); //920
    }
    padding: 0px 0.5rem;
  }

  @media screen and (min-width: @screen-xl-min) {
    .header,
    .header-opacity {
      width: (@screen-xl-min - 100); //1100
    }
    padding: 0px 1rem;
  }

  @media screen and (min-width: (@screen-xl-min + 167px)) {
    .header,
    .header-opacity {
      width: @screen-xl-min; //1200
    }
    padding: 0px 1.5rem;
  }

  @media screen and (min-width: @screen-xxl-min) {
    .header,
    .header-opacity {
      width: (@screen-xl-min + 200); //1400
    }
    padding: 0px 2rem;
  }
}

#main-content {
  .gx-main-content-wrapper {
    padding: 0px;
    width: 100%;
    margin-top: 32px;
    max-width: 100%;
  }
}

.sidebar {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: @gray-color-3;
  border-radius: 0px;
  position: absolute;
  height: 100vh;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
}

.gx-main-content-wrapper,
.header {
  padding-left: 80px !important;
}

.fullScreen {
  padding: 0px !important;
  margin: 0px !important;
  padding-left: 40px !important;
  overflow-y: unset !important;
}

.ant-layout-sider {
  width: 278px !important;

  &.ant-layout-sider-collapsed {
    width: 80px !important;
  }
}

.ant-tag-critical,
.ant-tag-high,
.ant-tag-elevated,
.ant-tag-relevant {
  color: @white-color;
}
.ant-tag-critical {
  background: @color-warning-dark;
}
.ant-tag-high {
  background: @color-optional-orange-dark;
}
.ant-tag-elevated {
  background: @color-optional-pink-dark;
}
.ant-tag-relevant {
  background: @color-optional-blue-dark;
}
