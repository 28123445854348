@import '../../../../../../styles/variables.less';

.ant-select-selector {
  padding-right: 40px !important;
}

.title-filter-companies {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: @text-dark;
}

.submit-on-footer {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: max-content;
  align-self: flex-end;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;

  button {
    width: 94px;
    height: 41px;
  }
}

.clean-button-on-footer {
  button {
    border: none !important;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: none !important;
    color: #004af7 !important;
    border: none !important;
  }
}

.submit-button-on-footer {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: @gray-color-2 !important;
    color: @secondary-color !important;
    border: none !important;
  }

  .ant-btn-loading-icon {
    margin-left: -15px;
    padding-right: 3px;
  }
}

.wrapper-inputs-filter {
  display: flex;
  height: max-content;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    padding: 11.5px 12px;
    color: @text-dark;
    margin-top: 8px;
  }

  input::placeholder {
    color: @gray-color-2;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: @text-dark;
  }
}

.wrapper-select-filter-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: min-content;
  padding-right: 10px;
  caret-color: transparent;

  label {
    margin-bottom: 8px;
  }

  .ant-select {
    height: 44px !important;
    width: 285px !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 100% !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: @text-dark;
  }

  .ant-select-selection-placeholder {
    color: @gray-color-2 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    opacity: 1 !important;
  }

  .ant-select-selector {
    background: transparent !important;
    padding-right: 40px !important;
  }

  .wrapper-select-input-and-flag {
    position: relative;
    display: flex;
    align-items: center;

    .select-input-selected-flag {
      position: absolute;
      margin-left: 13px;
    }
  }

  .wrapper-select-input-and-flag.is-selected {
    .ant-select-selection-item {
      padding-left: 35px;
    }
    .ant-select-selector {
      padding-right: 40px !important;
    }
  }
}

.wrapper-inputs-filter-secondary {
  display: flex;
  height: max-content;
  width: 100%;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    padding: 11.5px 12px;
    color: @text-dark;
  }

  input::placeholder {
    color: @gray-color-2;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: @text-dark;
  }
}

.company-tabs-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }
  .ant-tabs-tab {
    padding: 5px 0;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    align-items: center;
    letter-spacing: 0.5px;

    color: #323232;
  }
}

.sub-title {
  font-weight: 400;
  max-width: 510px;
  margin-top: 10px;
  margin-bottom: 2.5rem;
  color: #828282;
  font-size: 16px;
  line-height: 20px;
}
