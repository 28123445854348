@import '../../../../../styles/variables.less';
#credit-score-simple {
  .div-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 35px;
    width: 35px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #dde4f6 !important;
  }
  .score-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}
.span-score {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 8px;
}
.good-score {
  background-color: @color-approved-main;
}
.medium-score {
  background-color: @color-pending-main;
}
.low-score {
  background-color: @color-warning-main;
}
