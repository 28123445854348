#onboarding-builder-iframe {
  .onboarding-container {
    iframe {
      width: 100%;
      height: 80vh;
      display: block;
      padding: 0;
    }
  }
  .style-iframe {
    padding-left: 32px !important;
    padding-right: 32px !important;
    max-width: 1280px !important;
    @media screen and (min-width: 1600px) {
      width: 1400px !important;
    }
    @media screen and (min-width: 1367px) {
      width: 1200px !important;
    }
    @media screen and (min-width: 1200px) {
      width: 1100px !important;
    }
    @media screen and (min-width: 992px) {
      width: 920px !important;
    }
    @media screen and (min-width: 768px) {
      width: 720px !important;
    }
  }
  .root {
    display: flex !important;
    justify-content: center !important;
  }
}
