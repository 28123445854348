@import '../../../../../../../styles/variables.less';

#address-item-execution-container {
  .circle-icon {
    margin: 0 !important;
    margin-left: 5px !important;
  }

  display: flex;
  align-items: center;
  padding: 25px 0px;
  width: 100%;
  border-top: 1.5px solid @border-color;
  gap: 22px;

  .address-item-execution-information {
    gap: 18px;
    width: 100%;
  }

  .address-item-type-execution-tag {
    width: 90px;
    height: 23px;
    border-radius: 12px;
    border: 1.5px solid @primary-color;
    display: flex;
    align-items: center;
    justify-content: center;

    small {
      font-weight: bold;
      color: @primary-color;
      font-size: 12px;
    }
  }
}
#more-details {
  span {
    color: @text-dark;
  }
  strong {
    color: @text-dark;
  }
}
