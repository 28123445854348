@import '../../../../../styles/variables.less';

.invoice-list {
  --columns: 4;
  --padding-horizontal: 15px;
  --padding-vertical: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 1200px) {
    --columns: 3;
    --padding-horizontal: 10px;

    #period {
      display: none;
    }
  }

  @media (max-width: 950px) {
    .invoice-list__button_hide-sm > span {
      display: none;
    }

    .invoice-list__item,
    .invoice-list__list-header {
      grid-template-columns: repeat(var(--columns), 1.3fr) 0.1fr;
    }
  }

  @media (max-width: 800px) {
    --padding-horizontal: 5px;
  }

  p {
    margin: 0 !important;
    color: @text-dark;
  }

  &_hide-price {
    --columns: 3;

    @media (max-width: 1200px) {
      --columns: 3;
      --padding-horizontal: 10px;

      #period {
        display: inline-block !important;
      }
    }
  }

  &__filter {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  &__list {
    width: 100%;
  }

  &__list-header {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr) 0.5fr;
    padding: var(--padding-vertical) var(--padding-horizontal);
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr) 0.5fr;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    padding: var(--padding-vertical) var(--padding-horizontal);

    &:hover {
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    }

    a {
      justify-self: end;
    }
  }

  &__tag {
    width: min-content;
    white-space: nowrap;
    padding: 5px 10px;
    border-radius: 48px;

    &_settled,
    &_open {
      background-color: rgba(45, 153, 75, 0.16);
      color: #17521b;
    }

    &_pending,
    &_actionRequired {
      background-color: rgba(253, 232, 214, 1);
      color: #664014;
    }

    &_failed,
    &_canceled {
      background-color: #fadae1;
      color: #521717;
    }

    &_layout-tag {
      background-color: #d6e2fe;
      color: #00257a;
    }
  }

  &__button {
    display: flex;
    align-items: center;

    svg {
      --size: 20px;

      width: var(--size);
      height: var(--size);
    }

    &_reverse {
      flex-direction: row-reverse;
    }
  }
}
