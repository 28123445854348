@import '../../../../../styles/variables.less';

.add-tags-menu {
  width: 360px;

  padding: 18px !important;
  margin: -4px 0px !important;

  position: relative;

  .ant-card-body {
    padding: 0px !important;
  }

  .ant-btn-dashed {
    color: @primary-color;
    border-color: @primary-color;
  }

  .add-tags-info {
    display: flex;
    text-align: center;

    line-height: 1.4;
  }

  .add-tags-close {
    background: transparent;
    border: none;
    outline: none;
    top: 10px;
    right: 5px;

    position: absolute;

    cursor: pointer;
    color: @gray-color-2;

    &:hover {
      color: @gray-color-1;
    }
  }

  .add-tags-details {
    display: flex;
    align-items: center;
    flex-direction: column;

    .ant-form-item {
      margin-bottom: 10px !important;
    }

    .tag-colors-group-title {
      display: flex;
      align-self: flex-start;
      margin-bottom: 2px;
    }
  }

  .loading-tags-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 30px 0px 10px;
  }

  .add-tag-btn-container {
    background: none;
    border: none;
    outline: none;
    padding: 0px !important;

    margin: 0px 10px 10px 0px;

    cursor: pointer;
  }

  .tag-colors-group {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-radio-button-wrapper,
    .ant-radio-button-wrapper-checked {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 26px;
      width: 40px;
      margin: 5px;

      border: none;
      outline: none;
      border-radius: 4px !important;

      i {
        display: none;
        color: #fff;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      opacity: 0.8;

      i {
        display: flex;
      }
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
  }

  .create-tag-btn {
    display: flex;
    align-items: center;

    width: 100% !important;

    padding: 0px;
    border: none;
    outline: none;
    background: none;

    span {
      margin-left: 4px;
    }
  }

  .add-tags-footer-btns {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin-top: 15px !important;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px 18px;
      height: 20px;
    }

    .ant-btn:disabled {
      background-color: transparent !important;
      color: @primary-color !important;

      &:hover {
        color: @primary-color !important;
      }
    }

    .add-tag-back-btn {
      border-color: @gray-color-2;
      color: @gray-color-1;

      &:hover {
        opacity: 0.6;
      }
    }

    .add-tag-create-btn {
      border-color: @primary-color;
      color: @primary-color;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .empty-result-tags {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 10px 0;

    span {
      text-align: center;
    }
  }

  .validate-tag-title {
    display: flex;
    margin: -8px auto 12px;

    color: @danger-color;
    font-size: 12px;
  }
}

.set-tag-color-menu {
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  padding: 10px !important;

  button {
    width: 18px;
    height: 18px;

    border: none;
    outline: none;

    cursor: pointer;

    border-radius: 50%;

    margin: 5px;
  }
}

#dropdown-add-tags {
  padding: 0px !important;
}
