.billing-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
}

.billing-header__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
    text-align: left;
  }

  h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;

    span {
      font-weight: 700;
    }
  }
}

.billing-header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: rgba(0, 74, 247, 0.2);

  svg {
    width: 2rem;
    height: 2rem;
    color: rgba(0, 37, 122, 1);
    margin-left: 5px;
  }
}

.billing-header__button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.billing-header__export-menu {
  border-radius: 5px;

  .ant-menu-item {
    border-radius: 5px;
  }
}
