@import '../../../../../../styles/variables.less';

#debtors-data-column {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 700;
  padding-left: 0;
  color: @text-dark;

  .debtors-has-debts {
    margin-top: 10px;
    color: @danger-color;
    font-weight: bold;
  }
  .debtors-no-debts {
    margin-top: 10px;
    color: @success-color;
    font-weight: normal;
  }
}
