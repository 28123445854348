@import '../../../../../../../../../styles/variables.less';

.kyb-more-relationship-wrapper {
  .ant-spin {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.KYB-people-sources-sayari {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
}

.kyb-more-relationship-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.kyb-more-relationship-header-text {
  margin-top: 16px;
  margin-bottom: 32px;
}

.kyb-more-relationship-text {
  font-size: 14px;
  color: #000000;
  margin-bottom: 16px;
  white-space: normal;
}

.kyb-more-relationship-filter-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.kyb-more-relationship-filter-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kyb-more-relationship-toggle-button {
  display: flex;
  align-items: flex-end;
  width: 104px;
  height: 44px;
  background: linear-gradient(to right, #007bff 50%, white 50%) !important;
  gap: 8px;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
  border-radius: 22px;

  .ant-btn {
    line-height: 0px !important;
    padding: 0 0px !important;
  }
}

.icon-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon-left,
.icon-right {
  font-size: 20px; /* Tamanho dos ícones */
  transition: color 0.3s ease;
}

.kyb-more-relationship-toggle-button .icon-left {
  color: white;
}

.kyb-more-relationship-toggle-button .icon-right {
  margin-left: 16px !important;
  color: #007bff;
}

.kyb-more-relationship-toggle-button.toggled {
  background: linear-gradient(to right, white 50%, #007bff 50%) !important;
}

.kyb-more-relationship-toggle-button.toggled .icon-left {
  color: #007bff;
}

.kyb-more-relationship-toggle-button.toggled .icon-right {
  color: white;
}

.kyb-more-relationship-toggle-button::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.3s ease;
  border-radius: 22px;
}

.kyb-more-relationship-toggle-button.toggled::before {
  left: 50%;
}

.kyb-relationship-container {
  display: flex;
  flex-direction: column;
}

.kyb-relationship-warning-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.kyb-relationship-warning-message {
  width: 790px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f8b2391a;
  border-radius: 8px;
  border: 1px solid @border-warning-color;
  gap: 10px;
  padding: 10px;
}

.kyb-relationship-expand-button {
  width: 215px;
  height: 40px;
  padding: 8px 16px;
  gap: 8px;
  border-radius: 4px;
  background-color: @color-caf-blue-main;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.kyb-relationship-pagination {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.kyb-relationship-pagination-control {
  justify-content: flex-start;
}
