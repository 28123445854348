.input {
  width: 2rem;
  margin: 1.2rem;
}

.form-align {
  display: flex;
  justify-content: center;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-btn-link {
  padding: 0 !important;
  margin: 0 !important;
}

.error-text {
  color: #e21b45;
}

.btn-close {
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: black;
}
