@import '../../../styles/variables.less';

#profile-model-activities {
  margin-top: 30px;
  .recurrence-header-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-form .ant-row,
    .ant-row {
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 15px;
    }

    .ant-row .ant-form-item {
      margin-bottom: 0px !important;
    }

    .ant-input {
      font-size: 15px;
    }

    .period-group-container {
      display: flex;
      align-items: center;

      margin-bottom: 10px;

      i {
        font-size: 22px;
        color: @text-dark;
        margin-right: 4px;
      }

      .ant-form-item {
        margin-bottom: 0px !important;
      }

      .data-filter-period {
        padding: 0px 6px !important;
        cursor: pointer;

        .ant-picker-input > input::placeholder {
          color: @text-dark !important;
          cursor: pointer;
        }

        .ant-picker-input > input {
          color: @text-dark !important;
          font-size: 16px !important;
          font-weight: 500;

          cursor: pointer;
        }

        .ant-picker-clear {
          right: -12px !important;
        }
      }
    }

    .input-filter-group {
      display: flex;
      align-items: flex-end;
    }

    .ordination-input {
      .ant-select-selector {
        padding-right: 0px !important;
      }

      .ant-select-arrow {
        right: 0px !important;
      }
    }
  }

  .recurrence-timeline-row {
    margin: 0px -25px !important;
  }

  .recurrence-timeline-col {
    padding: 0px !important;
  }

  .recurrence-day-container:last-child {
    border-bottom: 1px solid @border-color;
  }

  .recurrence-day-timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 100%;

    .timeline-item-container {
      display: flex;
      width: 100%;

      border-top: 1px solid @border-color;
      padding: 30px 26px;

      .day-info-content {
        display: flex;
        align-self: flex-start;

        margin-left: 30px;

        span {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          font-size: 15px;
          color: @text-dark;
          font-weight: 700;
        }

        strong {
          font-size: 35px;
          color: @text-dark;
          font-weight: 700;
        }
      }

      .activity-info-content {
        position: relative;
        flex: 1;
      }

      .timeline-item-tail {
        position: absolute;
        left: 30px;
        height: 100%;
        border-left: 2px solid @border-color;
      }
    }

    .ant-timeline-item {
      display: flex;
      align-items: center;

      width: 100%;

      .ant-card {
        margin-bottom: 0 !important;
      }

      .ant-timeline-item-content {
        width: 100%;
        margin: 0 0 0 22px;
      }

      .ant-timeline-item-tail {
        display: none;
      }

      .ant-timeline-item-head-custom {
        left: 30px;
        top: -3px;
        align-self: center;

        position: relative;
        z-index: 1;
        transform: translate(-50%, 0%);
      }
    }

    .ant-timeline-item:not(:last-child) {
      padding-bottom: 25px;
    }

    .recurrence-day-status-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 38px;
      height: 38px;

      border-radius: 50%;
      background: #ffeeee;

      i {
        color: @danger-color;
        font-size: 22px;
      }
    }
  }

  .ant-spin {
    margin-bottom: 30px !important;
  }
}

.ant-picker-month-panel .ant-picker-content {
  height: 220px;

  .ant-picker-cell {
    text-transform: capitalize;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    height: 22px;
  }

  .ant-picker-cell-selected .ant-picker-cell-inner {
    height: 24px;
  }
}
