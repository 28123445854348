@import '../../styles/variables.less';

#content-container-share-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 14px;

  .ant-input-affix-wrapper .ant-input-prefix {
    margin-left: 15px;
  }

  .profile-name {
    color: @text-dark;
    margin: 10px 0px;
  }

  .input-grid {
    display: grid;
    grid-template-columns: 170px 1fr;

    .form-phone {
      border-radius: 0px;
      height: 35px;
      border-bottom: 1px solid #eee;
    }
    .form-mail {
      border-radius: 0px;
      height: 35px;
      border-bottom: 1px solid #eee;
      margin-left: 26px;
    }
  }
}

.modal-integration-action-buttons {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
