@import '../../../../../styles/variables.less';

.icon-status-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 38px;
  height: 38px;

  flex-shrink: 0;

  &.custom-icon-status {
    width: 40px;
    height: 40px;

    border-radius: 50%;
    background: #eeffee;

    .caf-ic_edit_2 {
      margin: 1px 0px 0px 1px;
      padding: 5px 5.5px;

      font-size: 16px;
      color: #eeffee;
      background-color: #5ec660;
    }

    .caf-ic_onboardind_big {
      &.icon-custom-status-reproved {
        border: 7px solid #ffeeee;
        color: #ffeeee;
        background-color: @danger-color;
      }

      &.icon-custom-status-approved {
        border: 7px solid #eeffee;
        color: #eeffee;
        background-color: @success-color;
      }
    }
  }

  i {
    &.caf-ic_close {
      border: 7px solid #ffeeee;
      color: #ffeeee;
      background-color: @danger-color;
    }

    &.caf-ic_plus {
      border: 7px solid @gray-color-4;
      color: @gray-color-4;
      background-color: @gray-color-1;
    }

    &.caf-ic_checkmark {
      border: 7px solid #eeffee;
      color: #eeffee;
      background-color: @success-color;
    }

    &.caf-ic_processing_line {
      border: 7px solid #eeeeff;
      color: #eeeeff;
      background-color: lighten(@info-color, 7%);
    }

    &.caf-ic_hold {
      border: 7px solid lighten(@warning-color, 40%);
      color: lighten(@warning-color, 40%);
      background-color: @warning-color;
    }

    &.caf-ic_arrow-up-right {
      border: 7px solid #eeffee;
      color: #eeffee;
      background-color: @success-color;
    }

    border-radius: 50%;
    padding: 4px;
    font-size: 18px;
  }
}
