@import '../../../../../../styles/variables.less';

.director-card-content {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 12px 0;
  margin: 0 14px;

  border-top: 1px solid @border-color;
  border-bottom: 1px solid transparent;

  .director-image {
    flex: 1;
    min-height: 42px;
    min-width: 42px;
    max-height: 42px;
    max-width: 42px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }

  .director-info {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
  }
}
