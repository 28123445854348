@import '../../../../../../../styles/variables.less';

#trust-item {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.clickable {
      cursor: pointer;
    }

    .squared-tag {
      font-weight: 500;
      font-size: 12.5px;
      border-radius: 4px !important;
      margin-left: 10px;

      &.primary {
        color: @success-color;
        background: @inverse-success-color;
      }

      &.danger {
        color: @danger-color;
        background: @inverse-danger-color;
      }

      &.warning {
        color: @warning-color;
        background: @inverse-warning-color;
      }

      &.info {
        color: @info-color;
        background: @inverse-info-color;
      }

      &.default {
        color: @gray-color-1;
      }
    }
  }

  .trust-items-list {
    padding-left: 30px !important;

    li {
      font-size: 14px;
    }

    li + li {
      margin-top: 7px;
    }
  }

  button {
    cursor: pointer;
    border-radius: 20px;
    padding: 0 15px;
    height: 25px;
    margin-top: 10px;

    &.warning {
      color: @warning-color;
      background-color: @inverse-warning-color;
      border: 0 !important;
    }

    & + button {
      margin-left: 10px;
    }
  }

  .title {
    font-size: 15px !important;
  }
}

.trust-item-reproved-reasons {
  .ant-popover-title {
    display: flex;
    align-items: center;

    min-height: 35px;
    padding: 5px 15px;

    span {
      font-size: 14px;
    }
  }

  .reproved-reasons-list {
    margin: 5px 0px 7.5px;
    padding: 0px;

    > li {
      display: flex;
      align-items: center;
      max-width: 350px;

      list-style: none;

      i {
        margin-right: 10px;

        color: @danger-color;
        font-size: 14px;
      }

      span {
        font-size: 14px;
      }
    }

    li + li {
      margin-top: 7px;
    }
  }

  .reproved-reasons-empty {
    span {
      font-size: 14px;
    }
  }

  .unavailable-sources-list {
    border: 1px solid @warning-color;
    background-color: @inverse-warning-color;
    border-radius: 8px;

    > div {
      border-bottom: 1px solid @warning-color;
      padding: 10px 24px;
      display: flex;
      flex-direction: column;

      &:last-child {
        border-bottom: none;
      }

      span {
        &:first-child {
          color: @text-dark;
          font-weight: 500;
        }

        &:last-child {
          font-style: italic;
        }
      }
    }
  }
}
