@import '../../../../../../../../styles/variables.less';

.kyb-company-partner-onboarding-link-modal {
  .partner-data {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .partner-data-item {
      margin-bottom: 20px;

      .typescale-subtitle-1 {
        margin-bottom: 0.3em;
      }

      .typescale-body-1 {
        color: @text-color;
      }

      .partner-data-button-copy-link {
        margin-left: 10px !important;
      }
    }

    .loading-icon {
      display: inline-block;
    }
  }
}
