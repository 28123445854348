@import '../../../../styles/variables.less';

body {
  .typescale-h5 {
    font-size: 24px;
  }
  .typescale-headline-small {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
  }
  .typescale-headline-small,
  .typescale-subtitle-1,
  .typescale-subtitle-2 {
    color: @text-dark;
  }
  .typescale-subtitle-1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .typescale-subtitle-2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
  .typescale-body-1,
  .typescale-body-2 {
    color: @black-color;
  }
  .typescale-body-1 {
    font-size: 16px;
    > b {
      color: @color-neutral-600;
    }
  }
  .typescale-body-2 {
    font-size: 14px;
  }
  .typescale-caption {
    color: @color-neutral-600;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}
.kyb-company-summary-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.kyb-company-summary-main,
.kyb-company-summary-grid {
  display: grid;
  gap: 24px;
}
.kyb-company-summary-grid {
  grid: auto / 1fr;
}
.kyb-company-summary-records-divider {
  margin: 0;
}
.kyb-company-details-wrapper {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 20px;
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    bottom: -2px;
  }
}
.kyb-company-popover-button {
  border: 0;
  color: @color-neutral-600;
  background-color: @color-neutral-200;
  &.ant-btn:not(.ant-btn-circle) {
    padding-left: 12px;
    padding-right: 12px;
  }
  &:hover,
  &:active,
  &:focus {
    color: @color-caf-blue-lighter;
    background-color: @color-caf-blue-main;
  }
}
.kyb-company-popover-overlay {
  .ant-popover-inner {
    border: 1px solid @gray-color-6;
    border-radius: 32px;
  }
  .ant-popover-inner-content {
    max-height: 400px;
    padding: 12px 0 !important;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: @gray-color-2;
      border-radius: 10px;
    }
  }
}
.kyb-company-popover-content {
  padding: 12px 24px;
}
.kyb-company-other-address {
  flex: 1;
  flex-basis: auto !important;
}
.kyb-company-other-address-item {
  padding: 12px 0;
  &:not(:last-child) {
    border-bottom: 1px solid @color-neutral-400;
  }
}
.kyb-company-collapse {
  border: 1px solid @color-neutral-400;
  h5.typescale-subtitle-2 {
    text-transform: capitalize;
    margin: 0 0 8px 0;
  }
  .kyb-company-info-article {
    gap: 36px 64px;
  }
  .kyb-company-info-section {
    padding: 24px;
    &:last-child {
      border-bottom: 0;
    }
  }
  &,
  .ant-collapse-content {
    border-color: @color-neutral-400 !important;
  }
  .ant-collapse-item-active {
    border: 0;
    border-bottom: 1px solid @color-neutral-400 !important;
  }
  .ant-collapse-item > .ant-collapse-header {
    padding: 16px;
    font-size: 18px;
    b {
      font-weight: 500;
    }
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .ant-collapse-arrow {
    left: unset !important;
    right: 16px;
  }
  .header-panel {
    align-items: center;
  }
  .wrapper-content {
    gap: 24px 64px;
    padding: 16px;
  }
  &.kyb-company-collapse-small {
    .ant-collapse-item > .ant-collapse-header {
      padding: 8px;
    }
  }
  &.kyb-company-collapse-transparent {
    margin-top: 24px;
    border: 0;
    background-color: transparent;
  }
}
.kyb-company-collapse-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .typescale-subtitle-2 {
    margin-bottom: 0;
  }
}
.kyb-company-counter-card {
  display: flex;
  width: fit-content;
  padding: 24px;
  justify-content: space-between;
  align-self: flex-start;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid @color-neutral-300;
  background: @color-neutral-100;
  > div {
    min-width: 95px;
  }
  .typescale-headline-small {
    margin-bottom: 4px;
  }
}
.kyb-company-summary-counter-card {
  display: flex;
  padding-bottom: 24px;
  margin: 24px 0 0;
  border-bottom: 1px solid @color-neutral-400;
}
.kyb-company-collapse-item:not(:last-child) {
  border-bottom: 1px solid @color-neutral-400;
}
.kyb-company-collapse-header-panel {
  padding: 8px;
  > div {
    display: flex;
    align-items: center;
  }
}
.kyb-company-pagination-area {
  align-items: center;
}
.kyb-company-main-tab-title {
  margin-bottom: 24px;
}
.kyb-company-records {
  .KYB-tab-title {
    margin-bottom: 48px;
  }
  .ant-btn {
    &,
    & + p {
      position: relative;
      top: -10px;
    }
  }
}
.kyb-company-records-items .ant-col:last-child {
  height: 36px;
}
.kyb-company-tab-title {
  margin-bottom: 24px;
}
.kyb-company-groupstructure-group-summary {
  gap: 32px;
  > div:last-child {
    padding-right: 24px;
  }
}
.kyb-company-people-main {
  .kyb-company-collapse-list {
    margin-top: 24px;
  }
}
.KYB-people-creditreport .credit-safe-shareholders .shareholder-line {
  margin-top: 0 !important;
}
.kyb-company-details-left-side {
  min-width: 0px;

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: flex 1;
  height: 100%;
  align-items: center;

  justify-content: space-between;

  .tab-item {
    color: @text-dark;
    font-size: 16px;
    cursor: pointer;
  }

  .ant-tabs-tab {
    padding: 0 0 10px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }
}

.company-details-tabs-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-tabs-content-holder {
    padding-left: 1px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }

  > .ant-tabs-nav .ant-tabs-nav-list {
    flex: 1;
  }

  .ant-tabs-nav-list {
    border-bottom: 2px solid #ebebeb;

    .ant-tabs-tab:nth-last-child(2) {
      margin-right: 0;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: #bdbdbd;
  }

  .ant-tabs-tab {
    font-size: 16px;
    display: flex;
    align-items: center;
    color: black;

    &:not(.ant-tabs-tab-disabled) {
      .kyb-company-details-ubo-structure-report-tab-title {
        color: @primary-color;
      }
    }
  }
  .ant-tabs-tab-btn:focus {
    color: black;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    color: black;
  }
}

.kyb-company-details-right-side {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  height: 100%;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.remove-bottom-top {
  border-top: none !important;
}

.remove-min-height {
  min-height: 0;
}

.company-collapse {
  border-color: @gray-color-2;

  > .ant-collapse-item,
  .ant-collapse-content {
    border-color: @gray-color-2;
  }

  .charge-text {
    color: @gray-color-1;
  }
}
.kyb-explore-popover-overlay {
  .ant-popover-inner {
    border: 1px solid @gray-color-6;
    border-radius: 32px;
  }
  .ant-popover-inner-content {
    max-height: 400px;
    max-width: 600px;
    padding: 18px 18px !important;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: @gray-color-2;
      border-radius: 10px;
    }
  }
}
.kyb-explore-popover-content {
  font-size: 14px;
  color: @text-dark;

  header {
    display: flex;
    align-items: center;
  }
  span {
    display: block;
    margin-top: 8px;
  }

  section {
    margin-top: 16px;
    strong {
      display: block;
    }
    div:not(:last-child) {
      margin-bottom: 5px;
    }
    div {
      text-align: left;
    }
  }

  .kyb-explore-content-icon {
    font-size: 24px;
    margin-right: 8px;
  }
}

@media (min-width: @screen-lg-min) {
  .kyb-company-summary-grid-1-1-1 {
    grid: auto / 1fr 1fr 1fr;
  }
  .kyb-company-summary-grid-1-1 {
    grid: auto / 1fr 1fr;
  }
}
