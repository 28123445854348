@import '../../../../../../styles/variables.less';

#simples-data-id {
  .col-ant {
    padding: 5;
  }
  .text-style {
    color: @text-dark;
  }
}
