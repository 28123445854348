@import '../../../../../../../styles/variables.less';

.custom-document-loading {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-spin {
    margin: 40px 0px 50px !important;
  }
}

.document-validation-status {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: 10px;
  border-radius: 8px;

  i {
    font-size: 20px;
    margin-right: 5px;
  }

  span {
    font-size: 15px;
    word-wrap: break-word;
  }

  &.primary {
    color: @success-color;
    background: @inverse-success-color;
  }

  &.danger {
    color: @danger-color;
    background: @inverse-danger-color;
  }
}
