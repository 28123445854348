@import '../../../../../../styles/variables.less';

#column-title-value-container {
  display: flex;
  flex-direction: column;

  color: @text-dark;

  .title {
    font-weight: 700;
    margin-bottom: 1em;
  }

  .value {
    line-height: 1.4;
    white-space: normal;
    word-break: break-word;

    margin-bottom: 1em;
  }
}
