@import '../../../../../../../../../../../../styles/variables.less';

#address-item-container {
  display: flex;
  flex-direction: column;
  padding: 25px 0px;
  width: 100%;
  border-top: 1.5px solid @border-color;

  > section {
    display: flex;
    gap: 12px;

    .address-item-data-container {
      width: 100vw;
      .address-item-information {
        button {
          cursor: pointer;
          transition: filter 0.3s;
          background: transparent;
          border: none;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }

      .address-item-type-tag {
        width: 90px;
        padding: 2.5px;
        border-radius: 12px;
        border: 1.5px solid @primary-color;
        display: flex;
        align-items: center;
        justify-content: center;

        small {
          font-weight: bold;
          color: @primary-color;
          font-size: 12px;
        }
      }
    }
  }

  .iframe-map {
    border: 1px solid @border-color;
    border-radius: @border-radius-base;
  }
}
