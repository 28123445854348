.caf-kyb-connection-modal {
  height: 189px;
}

.caf-kyb-connection-modal-title {
  font-size: 24px !important;
}

.caf-kyb-connection-modal-text {
  font-size: 16px;
  color: #828282;
  font-weight: 400;
  line-height: 20.8px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.caf-kyb-connection-modal-buttons {
  position: absolute;
  bottom: 16px;
  right: 24px;
  display: flex;
  gap: 8px;
}

.caf-kyb-connection-modal-cancel-button,
.caf-kyb-connection-modal-ok-button {
  position: static;
  width: auto;
  height: 40px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
}

.caf-kyb-connection-modal-cancel-button {
  position: static;
  border: 1px solid black;
  color: black;
}

.caf-kyb-connection-modal-ok-button {
  background-color: #004af7;
  color: #ffffff;
}
