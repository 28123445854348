@import '../../../../../styles/variables.less';

#profile-model-starter-flow-component {
  .label-1 {
    font-weight: bold;
    font-size: 15px;
    color: @text-dark-secondary;
  }

  #page-title-component {
    max-width: 610px;
    line-height: 1.5;
  }

  .subtitle {
    display: inline-block;
    margin-top: 5px;
  }
}
