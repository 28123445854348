@import '../../styles/variables.less';

// this style will always be applied
.sidebar-container {
  position: relative;
  background-color: @white-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }

  .sidebar-expand-button {
    color: @primary-color;
    font-size: 2rem;
    cursor: pointer;
    background-color: rgba(@primary-color, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 0.2rem;

    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: @primary-color;
      color: @white-color;
    }
  }

  // this style will be applied only when the sidebar is not collapsed
  &:not(.sidebar-container-collapsed) {
    .sidebar-logo {
      margin: 4vh;
    }

    .sidebar-expand-button {
      align-self: flex-end;
      margin-right: 1rem;
    }
  }

  // this style will be applied only when the sidebar is collapsed
  &.sidebar-container-collapsed {
    .sidebar-logo {
      margin: 5vh 0.5rem;
    }

    .sidebar-expand-button {
      align-self: center;
      margin-right: 0px;
    }
  }
}
