@import '../../../../../../../styles/variables.less';

.kyb-company-summary-main {
  > .ant-row {
    gap: 24px;
  }
}
.kyb-company-summary-card {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: 1px solid @border-color-3;
  border-radius: 8px;
  .ant-row-end {
    flex: 1;
    align-items: flex-end;
  }
  .typescale-subtitle-1 {
    margin: 0;
  }
  .typescale-subtitle-2 {
    margin-top: 24px;
  }
  .kyb-company-summary-score-text {
    display: inline-block;
    margin: 22px 0 4px 0;
  }
}
.kyb-company-summary-link {
  font-weight: 600;
}
#tag-component > .kyb-company-summary-tag {
  border-radius: 12px;
  padding: 4px 12px 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  &.ant-tag-warning {
    color: @color-pending-darker;
    background: @color-pending-light;
  }
  &.ant-tag-error {
    color: @color-warning-darker;
    background: @color-warning-light;
  }
  &.ant-tag-success {
    color: @color-approved-darker;
    background: @color-approved-light;
  }
}
.kyb-company-summary-tag-empty {
  width: 1px;
  height: 24px;
}
.kyb-company-summary-footer {
  margin: 24px 16px 0 0;
  text-align: right;
}
.kyb-company-summary-score-content {
  margin: 24px 0 0;
  text-align: center;
  .not-available {
    display: inline-block;
    margin-bottom: 8px;
  }
}
.kyb-company-summary-risk {
  gap: 11px;
  #tag-component {
    display: inline-block;
  }
  .typescale-subtitle-1 {
    margin-bottom: 16px;
  }
  .typescale-body-2 {
    margin-left: 8px;
  }
  .kyb-company-summary-footer {
    margin-top: 21px;
  }
}
.kyb-company-summary-risk-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.kyb-company-summary-risk-tag {
  text-transform: capitalize;
}
body .kyb-company-summary-score-low {
  color: @green-color;
}
body .kyb-company-summary-score-medium {
  color: @yellow-color;
}
body .kyb-company-summary-score-high {
  color: @red-color;
}
.kyb-company-summary-group-card,
.kyb-company-summary-people-card {
  height: 100%;
}
@media (min-width: @screen-lg-min) {
  .kyb-company-summary-main {
    > .ant-row {
      gap: 24px 0;
    }
  }
  .kyb-company-summary-card {
    height: 100%;
    min-height: 278px;
    gap: 24px;
    .typescale-subtitle-2 {
      margin-top: 0;
    }
    .kyb-company-summary-score-text {
      margin: 22px 0 4px 0;
    }
  }
  .kyb-company-summary-score-content {
    margin: 0;
  }
  .kyb-company-summary-risk {
    gap: 11px;
  }
  .kyb-company-summary-footer {
    margin: 0 32px 0 0;
  }
  .kyb-company-summary-group-content,
  .kyb-company-summary-people-content {
    > .ant-col {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
