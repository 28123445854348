@import '../../../../../../../../../styles/variables.less';

#information-source-details-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: auto;
  margin: 15px 0px 8px;
  padding: 2px 8px;

  border-radius: 4px;

  line-height: 1.5;

  text-shadow: none;

  &.primary {
    color: @primary-color;
    background: @inverse-primary-color !important;
  }

  &.success {
    color: @success-color;
    background: @inverse-success-color !important;
  }

  &.danger {
    color: @danger-color;
    background: @inverse-danger-color !important;
  }
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}
