@import '../../../../styles/variables.less';

#socket-banner {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e70f0;
  color: @white-color;
  z-index: 999;

  &.hideBanner {
    display: none;
  }

  i {
    margin-right: 10px;
  }
}

#socket-banner-list {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 32px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @primary-color;
  border-radius: 10px 0 0 0;
  border-width: 2px;
  border-style: solid;
  border-color: #e0e0e0;

  &.hideBanner {
    display: none;
  }

  i {
    margin-right: 10px;
  }
}
