@import '../../../../../../../../styles/variables.less';

#document-fields-container {
  .approval-diagnose {
    color: @success-color;
  }

  .failure-diagnose {
    color: @danger-color;
  }
}
