.render-flags-of-company {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .wrapper-country-flag {
    &:not(:first-child) {
      margin-left: -6px;
    }
    cursor: pointer;
  }

  .wrapper-country-flag-next-ones {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 1.7em;
    height: 1.7em;
    margin-left: -6px;
    background-color: #d6e2fe;
    border: 2px solid white;
    border-radius: 100%;
    opacity: 1;
    cursor: pointer;
    transition: background-color 0.5s;

    .bullets {
      height: 3px;
      width: 3px;
      border-radius: 100%;
      background-color: #00257a;
      border: 2px solid #00257a;
      transition: background-color 0.5s;
      transition: border-color 0.5s;
    }
  }

  .wrapper-country-flag-next-ones:hover {
    background-color: #004af7;

    .bullets {
      background-color: white;
      border-color: white;
    }

    .ant-tooltip .ant-tooltip-inner {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #323232;
      background: white !important;
    }
  }
}
