@import '../../../../../../styles/variables.less';

.kyb-company-print-logo {
  width: 100%;
  height: auto;
}
.kyb-company-print-today-date.typescale-caption {
  display: inline-block;
  margin-bottom: 16px;
  color: @black-color;
  font-weight: 300;
}
.kyb-company-print-details {
  padding-top: 0px;
}
.kyb-company-print-name {
  display: block;
  margin-bottom: 4px;
}
.kyb-company-print-identifier.typescale-body-1 {
  color: @color-neutral-500;
}
.kyb-company-execution-status {
  zoom: 1.2;
  padding: 5px 14px 2px 10px !important;
  &:before {
    content: ' ';
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-top: -3px;
    margin-right: 8px;
  }
  &.ant-tag-warning {
    color: @color-pending-dark;
    &:before {
      background: @color-pending-main;
    }
  }
  &.ant-tag-error {
    color: @color-warning-dark;
    &:before {
      background: @color-warning-main;
    }
  }
  &.ant-tag-success {
    color: @color-approved-dark;
    &:before {
      background: @color-approved-main;
    }
  }
}
h2.kyb-company-print-section-title {
  display: block;
  margin: 24px 0;
  font-weight: 600;
  color: @black-color;
}
.kyb-company-print-summary .ant-row-end {
  display: none;
}
.kyb-company-print-riskfactors {
  min-height: auto;
}
.kyb-company-print-info {
  margin-top: 32px;
}

@media print {
  #company-to-print {
    background-color: white;
    .company-to-print {
      width: 100%;
      height: auto;
    }
    .company-print-no-render {
      display: none;
    }
    .company-print-render {
      display: block;
    }
    .page-break-inside-avoid {
      break-inside: avoid;
    }
    .KYB-risk-level-control {
      padding: 5px;
      .risk-level-title {
        font-size: 12px;
      }
    }
    .kyb-company-summary-main {
      > .ant-row {
        gap: unset;
      }
      .kyb-company-summary-card {
        height: 100%;
      }
    }
    .kyb-company-tab-pane {
      margin: 24px 0;
    }
    .kyb-company-summary-records-divider {
      margin: 24px 0;
    }
    .kyb-company-print-break-avoid {
      break-inside: avoid;
    }
    .kyb-company-info-article {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 24px 64px;
      > div {
        flex: none;
        flex-basis: fit-content;
      }
    }
    .kyb-company-riskfactors-ranking {
      display: block;
    }
    .kyb-company-riskfactors-group {
      flex-direction: row;
      > div {
        &:not(:first-child) {
          flex: unset;
          min-width: 150px;
        }
        &:first-child {
          flex: 1;
        }
      }
    }
    .kyb-company-other-address {
      width: 100%;
    }
    .kyb-company-collapse {
      border-left: none;
      border-right: none;
      .ant-collapse-content,
      .ant-collapse-content-box,
      .ant-collapse-item {
        border: 0 !important;
      }
      .ant-collapse-header {
        border-radius: 8px 8px 0 0;
        border-left: 1px solid @color-neutral-400;
        border-right: 1px solid @color-neutral-400;
      }
    }
    .kyb-company-collapse-item {
      border-left: 1px solid @color-neutral-400;
      border-right: 1px solid @color-neutral-400;
      &:first-child {
        border-top: 1px solid @color-neutral-400 !important;
      }
    }
    .kyb-company-collapse-transparent {
      display: none;
    }
    .kyb-company-people-main {
      .kyb-company-summary-counter-card {
        padding-bottom: 0;
        border-bottom: none;
        margin-top: 0;
      }
    }
    .KYB-people-creditreport {
      margin-top: 0;
      .credit-safe-shareholders .shareholder-line {
        margin-top: 0 !important;
      }
    }
    .KYB-people-sources-sayari {
      margin-top: 0;
    }
    .kyb-company-pagination-area {
      display: none;
    }
    .kyb-company-print-supplier-title {
      margin-bottom: 24px;
    }
    .kyb-company-groupstructure-summary-area {
      grid-auto-flow: row;
    }
    .kyb-company-groupstructure-group-summary {
      gap: 32px;
    }
    .kyb-company-input {
      > .ant-card-body > .ant-row > .ant-col:first-child {
        display: none !important;
      }
    }
    .KYB-financial-info-table .ant-table {
      .ant-table-tbody > tr > td,
      .ant-table-thead > tr > th {
        padding: 8px 8px;
      }
    }
    .row-show-pagination-people {
      display: none;
    }
  }
}
