@import '../../../../../../../../../styles/variables.less';

#information-source-card {
  .ant-card-body {
    width: 100%;
    padding: 0px;
  }

  .information-source-icons {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    margin-right: 10px;

    position: relative;

    .flag-icon {
      position: absolute;

      top: 30px;
      right: 8px;

      width: 20px;
      height: 20px;

      border-radius: 50%;
    }

    .circle-icon {
      margin-left: 0px !important;
      margin-right: 10px !important;
    }
  }

  .information-source-content {
    .information-source-title {
      font-size: 16px;
      color: @text-dark;

      margin-bottom: 0px;
    }

    .information-source-subtitle {
      font-size: 16px;
      color: @text-dark;
    }

    .information-source-updated {
      font-size: 13px;
    }
  }
}
