@import '../../../../../../../../../styles/variables.less';

.KYB-people-creditreport {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;

  .wrapper-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 0px !important;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      color: @text-dark;
    }
  }

  .current-tab-infos {
    display: flex;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 32px;

    h4 {
      margin: 0;
      padding: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
      color: @text-dark;
    }
  }

  .previous-director-list {
    width: 100%;
    border: 1px solid @gray-color-2;
    border-radius: 8px;
    margin-top: 20px;
    padding: 26px 40px;

    .ant-col-9 {
      padding: 0;
      margin: 0;
    }

    .ant-col-4 {
      padding: 0;
      margin: 0;
    }

    .ant-col-5 {
      padding: 0;
      margin: 0;
    }

    .ant-col-1 {
      padding: 0;
      margin: 0;
      max-width: 25px;
    }

    .previous-director-column {
      font-weight: 700;
      font-size: 13px;
      line-height: 14px;
      color: @text-dark;
    }

    .current {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #39c560;
    }

    .previous {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #bdbdbd;
    }

    .dissolved {
      width: 14px;
      height: 14px;
      border-radius: 100%;
      background: #e21b45;
    }

    .line {
      border-bottom: 1px solid @gray-color-2;
      padding: 17px 0px !important ;

      .director-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: @text-dark;
      }

      .director-infos {
        font-size: 12px;
        line-height: 14px;
        color: #253132;
      }
    }
  }

  .credit-safe-shareholders {
    display: flex;
    flex-direction: column;
    width: 100%;

    .credit-safe-shareholders-header .ant-col {
      padding: 0;
      margin: 0;
    }

    .shareholder-line {
      width: 100%;
      background: #f9f9f9;
      border: 1px solid #bdbdbd;
      border-radius: 8px;
      margin-top: 17px !important;
      padding: 25px 10px !important;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.5px;
      color: @text-dark;

      .ant-col {
        display: flex;
        align-items: center;
      }
    }
  }

  .wrapper-person-control-cards {
    margin-top: 10px;
    margin-bottom: 35px !important;

    .ant-col {
      padding-left: 0;
    }

    .card-person-control {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      min-height: 112px;
      width: 100%;
      border: 1px solid #bdbdbd;
      border-radius: 8px;
      padding: 14px;
      text-align: center;

      strong {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: @text-dark;
      }

      .wrapper-status {
        margin-top: 15px;
        min-height: 40px;
        display: flex;
        gap: 10px;

        .content-status {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            font-size: 11px;
            line-height: 12px;
            color: @text-dark;
          }

          .group-content-and-status {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 5px;

            .active-status {
              height: 10px;
              width: 10px;
              background: #39c560;
              border-radius: 100%;
              margin-right: 5px;
            }

            .active-ceased {
              height: 10px;
              width: 10px;
              background: #e21b45;
              border-radius: 100%;
              margin-right: 5px;
            }

            .active-total {
              height: 10px;
              width: 10px;
              background: #bdbdbd;
              border-radius: 100%;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .announces {
    width: 100%;
    margin-top: 40px !important;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: @text-dark;

    .ant-col-24 {
      padding: 0;
      margin: 0;
    }

    strong {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .row-show-pagination-people {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px !important;

    .ant-col {
      padding: 0px;
    }
  }
}
