#modal-profile-update {
  .col-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .preferred-language {
    .ant-select-selector {
      cursor: pointer;
    }
  }

  .update-profile-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .update-profile-pointer {
    cursor: pointer;
    position: relative;

    > i {
      position: absolute;
      bottom: 4px;
      right: 6px;
      font-size: 14px;
      background: #eeeeee;
      padding: 6px;
      border-radius: 50%;
      cursor: pointer;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .btn-change-password {
    text-shadow: none;
  }

  .loading-opacity {
    opacity: 0.2;
  }

  .loading-position {
    position: absolute;
    bottom: 35px;
    right: 35px;
  }

  .ant-collapse {
    .ant-collapse-content-box {
      padding: 0;
    }
    .ant-collapse-header {
      margin-left: -15px;
    }
  }
}
