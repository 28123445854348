.empty-page {
  .title{
    margin-top: 24px;
    color: #515265;
  }

  .text{
    color: #515265;
    font-size: 18px;
  }

  .buttons-container{
    margin-top: 24px;
    display: flex;

    button{
      height: 41px;
    }
  }
}
