@import '../../../../../styles/variables.less';

@border-radius-base: 7px;

table.main-table {
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  text-align: center;

  border-collapse: separate;
  border-spacing: 0;

  thead {
    tr {
      background-color: @primary-color;
      height: 30px;
      color: @white;
    }

    th {
      padding: 0px 1rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    th:not(:first-child) {
      border-left: 1px solid @white;
    }

    .thead-th {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .tooltip {
        width: auto;
        margin-left: 0.5rem;
        svg {
          position: absolute;
          right: -0.7rem;
          color: @white;
          width: auto;
          font-size: large;
        }
      }
    }

    tr:first-child th:first-child {
      border-top-left-radius: @border-radius-base;
    }
    tr:first-child th:last-child {
      border-top-right-radius: @border-radius-base;
    }

    tr:last-child th:first-child {
      border-bottom-left-radius: @border-radius-base;
    }
    tr:last-child th:last-child {
      border-bottom-right-radius: @border-radius-base;
    }
  }

  tbody {
    tr {
      height: 52px;

      td:not(:first-child) {
        border-left: 1px solid @primary-color;
      }

      td {
        border-bottom: 1px solid @border-color;
        width: auto;
      }

      td .action {
        position: relative;
        svg {
          margin-left: 0.5rem;
          color: @primary-color;
          font-size: medium;
          cursor: pointer;
          position: absolute;
          right: 0.5rem;
        }
      }
    }

    tr.hovered {
      background-color: rgba(@border-color, 0.6);
    }
  }
}

.action-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  header {
    @header-border-radius-base: 8px;
    width: 100%;

    font-size: 14px;
    font-weight: 700;
    background-color: @primary-color;
    color: @white;
    padding: 0.5rem;
    border-top-left-radius: @header-border-radius-base;
    border-top-right-radius: @header-border-radius-base;

    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;

    svg {
      margin-left: 0.5rem;
      color: @white;
      font-size: large;
      cursor: pointer;
    }
  }

  table {
    width: 90%;
    font-size: 14px;
    margin: 0.6rem 0px;

    th,
    td {
      padding: 0.2rem 0.4rem;
    }

    thead {
      tr {
        th {
          white-space: nowrap;
          font-weight: 800;
          color: @text-dark;
        }

        th:not(:first-child) {
          border-left: 1px solid rgba(@primary-color, 0.1);
        }
      }
    }

    tbody {
      tr {
        td {
          color: @primary-color;
        }

        td:not(:first-child) {
          border-left: 1px solid rgba(@primary-color, 0.1);
        }
      }

      tr:not(:first-child) {
        border-top: 1px solid rgba(@primary-color, 0.1);
      }
    }
  }
}

.ant-popover-inner-content {
  padding: 0 !important;
}
