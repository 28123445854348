@import '../../../../../../styles/variables.less';

#modal-new-routine-custom-component {
  button {
    &:not(.selected) {
      .content-icon {
        background-color: lighten(@gray-color-3, 5%) !important;
        color: @gray-color-1;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 23px;
  }

  .profile-model-name {
    border-bottom: 1px solid @gray-color-3;
  }
}
