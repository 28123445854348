@import '../../styles/variables.less';

.section-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #F2F2F2;
  min-height: 43px;
  margin-top: 10px;
  margin-bottom: 20px;

  .url-button-wrapper{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .request-type-button{
    height: 26px;
    width: 57px;
    margin-left: 8px;
    margin-right: 0px;
  }

  .request-url{
    width: 450px;
    margin-left: 10px;
  }

  .json-button-wrapper{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    word-break: break-all;

    .json-loading-spin {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.modal-integration-action-buttons {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
