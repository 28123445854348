@import '../../../../../../../styles/variables.less';

.content-user-verified {
  background-color: @gray-color-5;

  .ant-collapse-content-box {
    background-color: transparent;
  }

  .ant-collapse-header {
    margin-top: 1rem;
    color: @info-color !important;
    background-color: transparent;
  }

  .user-content {
    display: flex;
    flex-direction: row;

    .content-icon {
      color: @primary-color;
      font-size: 20px;
      background: rgba(0, 74, 247, 0.2) !important;
      padding: 0.5rem;
      height: 35px;
      width: 35px;
      border-radius: 100%;
      cursor: pointer;
    }

    .user-text-info {
      text-align: start;
      margin: 0.5rem 0 0 1rem;

      .user-info {
        display: flex;
        align-items: center;

        .user-name {
          font-weight: bold;
          margin: 0 1rem 0 0;
        }
      }

      .user-reliability-resume {
        margin-top: 2rem;

        .tooltip-content {
          margin-left: 0.4rem;
        }
      }
    }
  }
}
