@import '../../../styles/variables.less';

.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  width: 50px;
  margin: 0 10px;

  border-radius: 50%;

  flex-shrink: 0;

  &.primary {
    color: @primary-color;
    background-color: @circle-icon-color;
  }

  &.danger {
    color: @danger-color;
    background: @circle-icon-color;
  }

  &.warning {
    color: @warning-color;
    background: @circle-icon-color;
  }
  &.background-blue {
    color: @primary-color;
    background: #004af733;
  }
  &.no-mrg-left {
    margin-left: 0px !important;
  }
  i {
    font-size: 28px;
  }

  img {
    display: flex;

    height: 50px;
    width: 50px;
  }
}
