@import '../../../../../../../styles/variables.less';

#confirmation-card-compliance-rules-component {
  display: flex;
  flex-direction: column;
  flex: 1;

  .validations-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    padding: 7px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    span {
      font-size: 15px;
      line-height: 20px;
    }

    .validation-status {
      width: 12px;
      min-width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: @gray-color-2;
      margin-right: 10px;

      &.reprove {
        background-color: @danger-color;
      }

      &.pending {
        background-color: @warning-color;
      }
    }
  }
}
