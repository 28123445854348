@import '../../../../../../styles/variables.less';

.PercentageData {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;

  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    aside {
      margin-left: 1rem;

      span {
        font-weight: 800;
        font-size: 40px;
        color: @primary-color;
      }

      p {
        margin: 0px;
        font-size: 15px;
        color: @text-dark;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .PercentageData {
    padding: 0px 0.1rem;
  }
}
