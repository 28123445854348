@import '../../../../../../styles/variables.less';

#create-users-modal {
  .ant-form-item-label {
    > label {
      display: flex;
      width: 100%;
    }

    .custom-label-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      a:hover {
        border-bottom: 1px solid @primary-color;
      }
    }
  }
}
