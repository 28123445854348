@import '../../../../../../../../styles/variables.less';

#profile-model-background-checking-component {
  #collapse-header-title {
    min-height: 34px;
  }

  .profile-model-collapse-container {
    .ant-collapse-item-disabled {
      background-color: @gray-color-4;
    }

    .ant-collapse:not(:last-child) > .ant-collapse-item:last-child,
    .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
      border-radius: 0px !important;
    }

    .ant-collapse {
      border-radius: 0px;
    }

    .ant-collapse:first-child {
      border-radius: 8px 8px 0px 0px;
    }

    .ant-collapse:last-child {
      border-radius: 0px 0px 8px 8px;
    }
  }

  .profile-model-collapse {
    background: #fff;

    .ant-collapse-header {
      display: flex;
      align-items: center;

      padding: 16px 25px;

      // .anticon {
      //   display: none !important;
      // }
    }

    .ant-select {
      width: 160px;

      .ant-select-selector {
        border-color: @border-color;
      }
    }

    .ant-collapse-content-box {
      padding-left: 35px !important;
      padding-right: 35px !important;
    }

    .personal-data-info {
      margin-bottom: 4px;

      p {
        font-size: 15px;
        line-height: 1.3;
        color: @text-dark;
        margin: 0;
      }
    }
  }

  .ant-switch-disabled,
  .ant-select-disabled {
    opacity: 0.6;

    .ant-select-arrow {
      display: none;
    }

    .ant-select-selector {
      //   opacity: 0.6;
      color: @text-dark;
      background-color: #fff;
    }

    // .ant-select-selector {
    //   border-color: #fff !important;
    //   background-color: #fff;
    // }
  }

  .data-source-checkbox {
    align-items: center;
    justify-content: space-between;
    padding: 6px 0;
    min-height: 49px;
    border-bottom: 1px solid @border-color;

    &:last-child {
      border-bottom: none;
    }

    &.ant-row,
    .ant-row {
      flex-wrap: nowrap !important;
    }

    .ant-checkbox-wrapper {
      padding: 2px 0;
      font-weight: 400;
    }

    .data-source-price {
      padding-left: 8px;
      font-size: 12px;
    }
  }

  .select-item {
    width: 200px;
    margin: 0;
    margin-left: 20px;
  }

  .switch-item {
    width: 80px;
    margin: 0;
  }

  .ant-collapse-content-box {
    position: relative;
  }

  .options-background {
    width: 375px;
    height: 100%;
    position: absolute;
    background: #f6f6f6;
    right: 0;
    top: 0;
  }

  .list-header {
    font-size: 13px;

    .select-item-title {
      width: 200px;
    }

    .switch-item-title {
      width: 100px;

      .anticon {
        margin-left: 2px;
      }
    }
  }

  .ant-select-selector {
    height: 32px;

    .ant-select-selection-placeholder {
      line-height: 30px;
    }

    .ant-select-selection-search-input {
      height: 30px;
    }
  }

  .ant-switch {
    height: 20px;
    line-height: 20px;
    min-width: 40px;

    .ant-switch-handle {
      width: 16px;
      height: 16px;
    }

    &.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 16px - 2px);
    }
  }

  .ant-checkbox {
    &:not(.ant-checkbox-checked) {
      .ant-checkbox-inner {
        background-color: #fff;
      }
    }
  }

  .ant-collapse-item-active,
  .ant-collapse-content-active {
    background-color: @gray-color-5 !important;
  }

  .profile-model-recurrence-props {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: 40px;

    #page-title-component {
      span {
        line-height: 1.4;
      }
    }

    .profile-model-search {
      display: flex;
      justify-content: flex-end;
    }
  }

  .is-recurrence {
    .ant-select-selector {
      background-color: @primary-color;
      border-color: @primary-color !important;
    }

    .ant-select-selection-item {
      color: #fff;
    }

    .ant-select-arrow {
      color: rgba(#fff, 0.8);
    }
  }

  // preview-compact
  &.preview-compact-mode {
    .ant-checkbox-disabled {
      .ant-checkbox-inner {
        border-color: @primary-color !important;
      }
    }

    .data-source-checkbox {
      min-height: 40px;

      .column-options,
      .data-source-price {
        display: none !important;
      }
    }

    .ant-collapse-header {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .ant-collapse-content-box {
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    .collapse-title {
      font-size: 15px;
    }
  }
}
