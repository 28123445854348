@import '../../../../../styles/variables.less';

.relationships-section-title {
  color: @text-dark;
  font-size: 16px;
}

.reset-divider {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
