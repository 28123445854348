@import '../../../../../../styles/variables.less';

.chart-container {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.label-card {
  width: 30%;
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 0.8rem;
  background-color: #f9f9f9;
}

.label-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 200px;
}

.label-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 10px;
}

.label {
  font-size: 0.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0px;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.pagination {
  display: grid;
  grid-template-columns: repeat(auto-fit, 10px);
  grid-gap: 0.5rem;
  justify-items: center;
  justify-content: center;
}

.pagination-item {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e8e8e8;
  cursor: pointer;
  border: none;

  &.active {
    background-color: @primary-color;
  }
}
