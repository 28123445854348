@import '../../../styles/variables.less';

#collapse-header-toggle-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  right: 25px;

  transition: all 0.2s ease-in-out;

  background-color: transparent;

  i {
    font-size: 20px;
  }

  &.active {
    background-color: @primary-color;
  }
}
