#confirmation-card-background-checking-component {
  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(.empty) {
    .ant-card {
      overflow: hidden;

      .ant-card-body {
        padding: 0 !important;
      }
    }
  }

  #profile-model-background-checking-component {
    .ant-collapse {
      border: none !important;
      border-radius: 0 !important;
    }
    .ant-collapse-item:last-child {
      border-radius: 0 !important;
    }
  }
}
