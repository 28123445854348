@import '../../../styles/variables.less';

.special-tag {
  margin-left: 8px;
  padding: 2px 5px;

  background: #cfcfcf5e;
  border-radius: 50px;

  color: #828282;
  font-size: 9px;
  line-height: normal;

  overflow: hidden;
  text-transform: uppercase;
}
