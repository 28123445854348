@import '../../../../../../styles/variables.less';

#profile-model-create-footer-component {
  background-color: @body-background;
  border-top: 1px solid @border-color;

  .ant-progress-inner {
    background-color: @gray-color-4;
  }
}
