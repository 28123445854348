@import '../../../../../../../styles/variables.less';

#custom-status-reasons {
  width: 100%;
  height: 100%;
  padding-top: 30px;

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-select-selection-item {
    height: 24px;
    margin-right: 5px;
    font-size: 13px;
    background: @primary-color !important;
    color: #fff;
    padding-left: 12px !important;
    padding-right: 9px;
    border-radius: 100px;
    border: none;
  }
  .ant-select-selection-item-content {
    margin-right: 7px;
  }

  &.reproval {
    .ant-select-selection-item {
      background: @danger-color !important;
    }
  }

  .ant-select-selection-item-remove {
    line-height: 1;

    i {
      font-size: 12px;
      color: #fff;
    }
  }
}
