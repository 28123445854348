@import '../../../../../../../../styles/variables.less';

#background-checking-services-container {
  .cols {
    section {
      &:nth-child(2) {
        margin-left: auto;
      }
    }
  }

  .serivces-list {
    padding: 16px;
    border: 1px solid @border-color;
    border-radius: 8px;

    > div {
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid @border-color;
      padding: 20px 0px;

      &:last-child {
        border-bottom: none;
      }

      span {
        font-weight: bold;
        font-size: 16px;
        color: @text-dark;
      }

      .service-price {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-right: 5px;

        section {
          font-weight: 500;
          font-size: 15px;
          color: @text-dark;
        }
      }
    }
  }
}
