@import '../../../../../../../styles/variables.less';

#note-card{
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #EBEBEB;

    .note-created-at{
        font-style: italic;
    }

    .note-card-footer{
        display: flex;
        justify-content: space-between;
        align-items: center;

        button :hover{
            transform: scale(1.1);
            cursor: pointer;    
        }
    }
    .show-more-note{
        margin-top: 1rem;
        color: @primary-color;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

}

