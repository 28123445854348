#profile-model-create-component {
  .profile-model-create-wrapper {
    position: relative !important;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
  }

  .page-title {
    padding-top: 5px;
    padding-right: 30px;
    margin-bottom: 0 !important;
  }
}
