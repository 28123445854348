.json-view-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10;
  word-break: break-all;
  margin-top: 10px;
}
