@import '../../styles/variables.less';

#select-source-wrapper {
  .ant-select {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 99999px;
    color: @text-dark;
    font-size: 11px;
    font-weight: bold;

    .ant-select-selector {
      height: 26px;
    }
  }
}

.select-source {
  min-width: 180px !important;

  .ant-select-item-option-content {
    font-size: 12px;
  }
}
