#empty-documents-component {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 15px;

  .empty-documents-img {
    display: flex;
    justify-content: flex-end;

    height: 100%;

    &.custom-img {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }

  .send-images-container {
    padding-top: 5px;
    padding-left: 30px;

    height: 100%;

    width: 100%;
    max-width: 425px;

    .send-images-description {
      display: flex;
      max-width: 300px;

      font-size: 35px;
      line-height: 1.15 !important;
      color: #515265;
    }

    .send-images-btn {
      width: 100%;
      max-width: 250px;
      margin-top: 25px;
    }
  }
}
