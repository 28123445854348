@import '../../../../../styles/variables.less';

.profile-model-update-modal {
  .subtitle {
    display: block;
    font-size: 15px;
    margin-top: 2px;
  }

  .ant-spin {
    margin: 25px 0px !important;
  }

  .ant-form-item {
    width: 100%;
    margin-bottom: 10px !important;
  }

  // .ant-form-vertical .ant-form-item-label > label {
  //   font-size: 18px;
  //   color: #323232;
  //   font-weight: 600;

  //   display: block;
  //   margin-bottom: 5px;
  // }

  .ant-select-selector {
    border-bottom: 1px solid #d9d9d9 !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    padding: 0px 0px 10px !important;

    span {
      font-size: 16px !important;
      color: @text-dark;
    }
  }

  .ant-select-arrow {
    top: 35% !important;

    i {
      font-size: 18px !important;
    }
  }

  .profile-model-update-content {
    margin-top: 30px !important;
  }

  .footer-btns-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    padding: 0px 15px;

    .ant-btn {
      padding: 0px 30px !important;
    }
  }
}
