@import '../../../../../styles/variables.less';

#executions-legal-information-processes-container {
  .legal-information-processes-title {
    padding-bottom: 5px;
    margin-bottom: 15px;

    border-bottom: 1px solid @border-color;

    strong {
      font-size: 14px;
      color: @text-dark;
    }
    p {
      color: @text-dark;
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }
  .passives {
    margin-top: 20px;
    p {
      padding-left: 20px;
    }
    strong {
      padding-left: 20px;
    }
  }
}
