@import '../../styles/variables.less';

.no-results {
  min-height: 170px;
  width: 100%;
  padding: 25px 25px;
  background-color: @secondary-color;
  border-radius: 8px;

  &.shadow {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.247) !important;
  }

  span {
    line-height: 130%;
    color: #828282;
  }

  svg {
    width: 56px;
    height: 56px;
  }
}
