.content-user-verified {
  background-color: #fafafa;

  .user-content {
    display: flex;
    flex-direction: row;

    .content-icon {
      color: #004af7;
      font-size: 20px;
      background: rgba(0, 74, 247, 0.2) !important;
      padding: 0.5rem;
      height: 35px;
      width: 35px;
      border-radius: 100%;
      cursor: pointer;
    }

    .user-text-info {
      text-align: start;
      margin: 0.5rem 0 0 1rem;

      .user-info {
        display: flex;
        align-items: center;

        .user-name {
          font-weight: bold;
          margin: 0 1rem 0 0;
        }
      }

      .user-reliability-resume {
        margin-top: 2rem;
      }
    }
  }

  .container-user-information {
    margin: 1rem 2rem;

    .user-item-info {
      margin: 8px 0;
    }

    .content {
      margin: 1rem 0 0 1rem;
    }
  }
}
