@import '../../../../styles/variables.less';

.sidebar-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .sidebar-user-menu-button {
    height: auto;

    .sidebar-user-avatar {
      cursor: pointer;
    }
  }

  p {
    font-size: small;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 0px;
    color: @primary-color;
    display: flex;
    align-items: center;
  }

  span {
    font-size: 40px;
  }

  &.collapsed {
    span {
      font-size: 24px;
    }
  }
}

.sidebar-user-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: @gray-color-3;
  padding: 10px 0;

  .sidebar-user-edit-menu-button {
    color: @primary-color;
    background: #fff;
    border-color: @primary-color;
    margin: 0px;
    width: 90%;
    .ant-select-selector {
      border-color: @primary-color;
      background-color: @primary-color;
    }
  }

  .sidebar-user-language-select {
    .ant-select-selector {
      background-color: @secondary-color;
      color: @black-color;
      text-align: center;
      font-weight: bold;
      text-align-last: center;
    }
  }

  hr {
    width: 90%;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      margin: 0px;
    }
  }
}
