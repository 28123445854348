img.ant-image-img {
  border-radius: 10px;
  object-fit: cover;
  margin-top: 16px;
}

video.card-image {
  border-radius: 10px;
  object-fit: cover;
}

.liveness-card {
  .ant-card-body {
    height: 260px;
    display: flex;
    .card-info {
      margin: 30px;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-info-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        .card-info-status-content {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
        }
      }
      .card-info-simple {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
      }
    }
  }
}
