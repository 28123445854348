@import '../../../styles/variables.less';

#collapse-header-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 40px;

  font-size: 16px;
  font-weight: 500;
  color: @text-dark;

  span:first-child {
    display: flex;
    align-items: center;
  }

  span:last-child {
    margin-right: 10px;
  }

  .div-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 35px;
    width: 35px;
    margin: 0 10px;
    border-radius: 50%;
    background-color: #dde4f6 !important;
    i {
      color: @primary-color;
    }
  }

  .collapse-title {
    margin-right: 15px;
  }
  .title-bold {
    font-size: 20px;
    font-weight: 700;
  }
}
