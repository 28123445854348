@import '../../styles/variables.less';

#card-archives-upload {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 50px 20px;
  }

  .gx-card-widget .ant-card-body {
    padding: 0px;
  }
  .ant-card-body {
    padding: 24px;
  }

  .ant-card-bordered {
    border: none;
  }

  .upload-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 115px;
    height: 115px;
    border-radius: 50%;
    background: #f2f2f2;

    i {
      font-size: 90px;
      color: #929292;
    }
  }

  .upload-title {
    font-size: 18px;
    font-weight: 600;
    color: #2b2b2b;
  }

  .upload-title-select {
    color: @primary-color;
    cursor: pointer;
  }

  .upload-title-supported {
    display: block;
    font-size: 15px;
    color: #828282;
    margin-top: 6px;
  }
}

.archives-upload-notification-title {
  display: block;

  font-size: 18px;
  font-weight: 500;
  color: #2b2b2b;
}

.ant-notification-notice-description .ant-typography {
  font-size: 16px;
  font-weight: 500;
  color: #828282;
  display: block;
  margin-bottom: 4px;
}

.ant-modal-close-x {
  color: #2b2b2b;
  font-weight: 900;
}
