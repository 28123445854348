.quickAccess__wrapper {
  display: grid;
  grid-template-columns: 0.7fr 2fr;

  padding: 1rem 0.5rem;
  height: 100%;
  width: 100%;

  border-radius: 8px;
  border: 1px solid #d1d1d1;
  background-color: white;

  box-shadow: 0px 0px 20px 0px #00000029;

  &:hover {
    box-shadow: 0px 8px 20px 0px #0000003d;
  }

  transition: box-shadow 0.15s ease-in-out;
}

.quickAccess__icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    border-radius: 50%;
    background-color: #004af729;
    width: 4rem;
    height: 4rem;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;

      color: #00257a;
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.quickAccess__title_wrapper {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1000px) {
  .quickAccess__wrapper {
    grid-template-columns: 1fr 2fr;
  }

  .quickAccess__content_wrapper {
    width: 300px;
    height: 80px;
  }
}

@media screen and (max-width: 1000px) {
  .quickAccess__wrapper {
    grid-template-columns: 0.5fr 2fr;
  }

  .quickAccess__content_wrapper {
    width: auto;
    height: auto;
  }
}

.quickAccess__content_title {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}

.quickAccess__content_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}
