@import '../../../../../styles/variables.less';

.executions-dossier-card-container {
  margin-bottom: 20px;

  .dossier-card-content {
    // .ant-row:first-of-type {
    //   margin-right: 0px;
    // }

    .dossier-card-title {
      margin: 2px 0px 5px;
      color: @text-dark;
    }

    .dossier-card-subtitle {
      font-size: 15px;
      color: @text-color;

      margin-bottom: 30px;
    }
  }

  .dossier-icon {
    min-width: 48px;
    width: 48px;
    height: 48px;
  }
}
