@import '../../../../../styles/variables.less';

#executions-tabs-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto 40px;
  width: 100%;

  .tab-item {
    color: @text-dark;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  .tab-item-icon {
    margin-right: 3px;
  }

  button {
    background: none;
    border: none;
    outline: none;
  }

  .ant-tabs-tab {
    padding: 0 0 10px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0px !important;
  }
}
