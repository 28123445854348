.create-link-wrapper {
  h2 {
    max-width: 500px;
    font-size: 28px;
    text-align: center;
  }

  img {
    width: 130px;
    height: 130px;
  }

  .data-link-info {
    img {
      width: 110px;
      height: 110px;
    }

    p {
      display: flex;

      a {
        word-break: break-all;
        max-width: 420px;
        text-align: center;
        color: #4990ef;
      }
    }

    button {
      cursor: pointer;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.95);
      }
    }
  }
}
