@import '../../../../../../../styles/variables.less';

#to-print-header {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 15px;

  header {
    display: flex;
    align-items: center;

    img {
      width: 125px;
    }

    .to-print-description {
      display: flex;
      flex-direction: column;

      margin-left: 25px;

      strong {
        color: @text-dark;
        font-size: 16px;
      }

      span {
        color: @text-color;
        font-size: 14px;
      }
    }
  }

  .to-print-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin: 35px 0px 25px;

    .profile-details {
      display: flex;
      align-items: center;

      flex: 1;

      .profile-img {
        display: flex;
        flex-shrink: 0;

        margin-right: 15px;

        width: 80px;
        height: 80px;

        border-radius: 8px;
        border: 1px solid @border-color;

        overflow: hidden;

        -o-object-fit: cover;
        object-fit: cover;

        background-color: #ffffff;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        transition: all 0.2s ease-in-out;
      }

      .profile-info {
        display: flex;
        flex-direction: column;

        padding-right: 20px;

        line-height: 1.4;

        .profile-name {
          color: #575757;
          font-size: 16px;
        }

        .profile-identification {
          font-size: 13px;
        }

        .profile-tags {
          display: flex;
          align-items: center;

          flex-wrap: wrap;

          width: 100%;

          overflow: hidden;

          .card-execution-tag {
            margin: 5px 5px 0px 0px;
            max-width: 100%;

            span {
              font-size: 10px;
            }

            .special-tag {
              font-size: 7px;
            }
          }
        }
      }
    }

    .profile-status {
      #general-status-card {
        margin: 0px;
        max-width: 215px;

        .general-status-info {
          margin: 12px;

          .general-status-icon {
            margin-right: 10px;
            font-size: 1.5rem;
          }

          .general-status-description {
            strong {
              font-size: 12.5px;
            }

            span {
              font-size: 10.5px;
              line-height: 1.2;
            }
          }
        }
      }
    }
  }
}
