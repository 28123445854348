@import '../../../../../styles/variables.less';

.checkpoint-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  .ant-timeline-item {
    width: 100%;

    .ant-timeline-item-tail {
      display: none;
    }

    .ant-timeline-item-head-custom {
      top: 15px;
      left: 15px;

      padding: 0px 1px !important;
    }
  }

  .ant-timeline-item:not(:last-child) {
    padding-bottom: 25px;
  }

  .ant-timeline-item:last-of-type .checkpoint-status-line-validation {
    display: none;
  }

  .checkpoint-status-line-validation {
    position: absolute;
    top: 34px;
    left: -13px;
    height: 100%;
    border-left: 2px solid @border-color;
  }

  .checkpoint-status-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    border-radius: 50%;
    border: 2px solid;

    i {
      display: flex;
      font-size: 19px;
    }
  }

  .checkpoint-status-info {
    display: flex;
    align-items: center;

    margin-left: 20px;
    padding: 0px;
    width: calc(100% - 20px);

    border: none;
    outline: none;
    background: none;
    text-align: initial;

    cursor: pointer;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      flex: 1;
      padding-right: 20px;

      span {
        display: block;
        margin-bottom: 2px;

        font-size: 16px;
        font-weight: 500;

        width: fit-content;
        word-break: break-word;

        &:not(.dotted-label-text) {
          color: @text-dark;
        }
      }

      p {
        font-size: 13px !important;
        color: @text-color !important;

        width: fit-content;
        word-break: break-word;
        line-height: 1.4;

        margin: 0;
      }
    }

    i {
      font-size: 22px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}
