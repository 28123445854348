@import '../../../../../../styles/variables.less';

#routine-subheader {
  .gx-header-horizontal-top {
    padding: 16px 0;
  }

  .gx-container {
    margin-top: 0px;
    width: auto;
  }

  .custom-horizontal-pdd {
    padding-top: 30px;

    &.retract {
      padding-bottom: 20px;
    }
  }

  .routine-subheader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .breadcrumbs-color {
    color: @text-dark;
  }

  .ant-breadcrumb-separator {
    color: @text-dark;
  }

  .profile-info {
    flex: 1;
    padding-right: 10px;

    transition: all 0.2s ease-in-out;
  }

  .profile-name {
    color: #fff;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 2px;

    width: fit-content;
    word-break: break-word;
  }

  .profile-last-update {
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }
}
.actions-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;

  padding: 0px 20px !important;

  background: transparent;
  border: 1px solid rgba(#fff, 0.8);

  strong {
    color: #ffffff;
    font-weight: 500;
    font-size: 15px;
  }

  &:hover,
  &:focus {
    border-color: @primary-color;
    background: rgba(#fff, 0.05);
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active,
  &.ant-btn::before {
    background: transparent !important;
  }

  &.ant-btn-loading {
    border: none;
    padding: 0px 25px !important;

    .ant-btn-loading-icon {
      color: #ffffff;

      .anticon {
        font-size: 25px;
      }
    }
  }
}
