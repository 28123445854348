@import '../../../../../../../styles/variables.less';

.media-exposure-collapse{

  .ant-collapse-content-box{
    .ant-tag{
      border: 1px solid @gray-color-3;
    }

    .ant-divider{
      border-color: @gray-color-3
    }

    .open-modal-button{
      margin: 0;
      padding: 0;
      padding-left: 5px,
      // margin-left: 5
    }

    .entities-text{
        display: 'flex';
        align-items: 'center';
        justify-content: 'flex-start';
    }
  }

}
