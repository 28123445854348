@import '../../../../styles/variables.less';
#integration-guide-component {
  .custom-page-title {
    flex: 1 !important;
  }

  .android-logo {
    width: 36px !important;
  }

  .ios-logo {
    width: 64px !important;
  }
  .ant-tag {
    color: @white-color !important;
    background-color: @success-color!important;
  }
}

.ant-alert-message {
  color: #323232;
}

.ant-alert,
.ant-alert-warning {
  background-color: #f8b2391a;
  border-color: #f16e00;
}
