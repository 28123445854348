@import '../../../../../../styles/variables.less';

.sla-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 2rem;

  h3 {
    font-weight: 600;
    font-size: 12px;

    span {
      color: @primary-color;
      font-size: 14px;
    }
  }

  .sla-data__container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.7rem;
    margin-bottom: 15px;
    .sla-data__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;

      h5 {
        font-weight: 600;
        font-size: 13px;
        white-space: nowrap;
      }

      .sla-data__item_card {
        background: url('../../../../../../assets/images/data-doc.svg') no-repeat;
        background-size: contain;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        h4,
        p {
          margin: 0;
          color: @white;
          font-weight: 550;
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .sla-data {
    padding: 0px 0.1rem;
  }
}
