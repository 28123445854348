@import '../../styles/variables.less';

.disabled-always-checked .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  filter: grayscale(1);
}
.react-tel-input .flag-dropdown {
  border: none;
  background-color: #fff;
}
.react-tel-input .form-control {
  border: none;
}
.input-phonenumber {
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  color: @text-dark;
}
.multiselect-filters {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;

  .ant-select-item-option {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-select-item-option-content {
    border-bottom: 1px solid @gray-color-4;
    padding-right: 20px;
  }

  .ant-select-item-option-state {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);

    > span {
      font-size: 13px;
    }
  }

  .ant-select-item-empty {
    font-size: 13px;
    text-align: center;
    font-style: italic;
    padding: 25px 32px;
    word-break: break-word;
  }

  .multiselect-option {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    .option-title {
      font-size: 13px;
      font-weight: 700;
      color: @text-dark;
      margin-bottom: 4px;
      line-height: 1.2;
    }

    .option-subtitle {
      font-size: 11px;
      font-weight: 400;
      font-style: italic;
      color: @text-dark;
      line-height: 1.2;
    }
  }
}
