@import '../../../../../styles/variables.less';

#sim-swap-id {
  .col-ant {
    padding: 5;
  }
  .text-style {
    color: @text-dark;
  }
  .span-style {
    margin-left: 5px;
    margin-right: 10px;
    color: @text-dark;
  }
}
