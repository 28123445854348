@import '../../../../../../../../../../../styles/variables.less';

.kyb-more-relationship-form-content {
  border: 1px solid @color-neutral-300;
  border-radius: 8px;
  background-color: @color-caf-blue-lighter;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px;
  width: auto;
  height: fit-content;

  .ant-form-vertical .ant-form-item {
    margin: 0;
  }

  .ant-select-selector {
    padding-right: 0px !important;
    color: black;
  }

  .ant-select-arrow {
    top: 40%;
  }

  .ant-select-clear {
    top: 40%;
  }

  .ant-select-selection-placeholder {
    color: @color-neutral-600;
    opacity: 1;
  }

  .ant-slider-step {
    height: 4px;
    background-color: @color-neutral-400;
  }

  .ant-slider-handle {
    width: 18px;
    height: 18px;
    border: 0;
    margin-top: -7px;
    box-shadow: 1px 1px 4px 2px hsla(0, 0, 0, 0.12);
    z-index: 2;
  }

  .ant-checkbox {
    border-color: @color-neutral-300;
    background-color: @white-color;
  }

  .ant-checkbox-wrapper {
    color: @gray-color-7;
    margin-left: 0px;
  }

  .ant-select-open .ant-select-selector {
    width: auto !important;
  }

  .ant-slider-track {
    background-color: @color-caf-blue-main !important;
    transition: unset;
    z-index: 1;
  }

  .ant-typography {
    font-size: 14px;
    color: #000000;
    margin-bottom: 14px;
    white-space: normal;
  }

  .ant-btn {
    color: @color-caf-blue-main;
  }

  .ant-btn-primary {
    width: auto;
    border-radius: 4px;
  }

  .ant-btn-link {
    width: 70px;
    border-radius: 4px;
    border: 1px solid @color-caf-blue-main;
  }
}

.kyb-more-relationship-input-slider-container {
  display: flex;
  flex-direction: row;
}

.kyb-more-relationship-selects-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 30px;
}

.kyb-more-relationship-select {
  flex: 1;
}

.kyb-more-relationship-selects {
  display: flex;
  flex-direction: row;
  flex: 2;
  flex-wrap: wrap;
  gap: 16px;
  .ant-space-item {
    flex: 1;
  }

  .ant-select {
    flex: 1;
    &:first-child {
      flex: 1.4;
    }
  }
}

@media (max-width: 1200px) {
  .kyb-more-relationship-form-content {
    height: fit-content;
  }

  .kyb-more-relationship-select {
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
    flex-basis: calc(50% - 16px) !important;
  }

  .kyselectb-more-relationship- {
    flex: 1 1 calc(50% - 16px) !important;
  }

  .ant-select-arrow {
    top: 50% !important;
  }

  .ant-select-clear {
    top: 50% !important;
  }
}

.kyb-more-relationship-select {
  font-size: 14px;
  font-weight: bold;
}

.kyb-more-relationship-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 352px;
  margin-top: 30px;
}

.kyb-more-relationship-actions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.kyb-more-relationship-input {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.kyb-more-relationship-slider {
  flex: 1;
  margin-left: 40px;
}
