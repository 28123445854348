@import '../../../../../../../../../styles/variables.less';

.company-record-filter-source {
  scroll-margin-top: 100px;

  .ant-select-selector {
    border-color: @gray-color-2 !important;
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: @black-color;
    font-weight: bold;
    opacity: 1;
  }
}

@media print {
  .company-record-with-details {
    border: 1px solid @gray-color-2;
    margin-top: 20px;
    padding: 0 10px;
    border-radius: 10px;
  }
}
