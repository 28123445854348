@import '../../../../../../../styles/variables.less';

.validation-list-item {
  margin-left: 24px;
  padding-bottom: 8px;

  &:first-of-type {
    margin-top: 14px;
  }

  &:not(:first-of-type) {
    padding-top: 8px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid @border-color !important;
  }

  #trust-item header {
    cursor: default !important;
  }
}

.compliance-rules-workflow-builder {
  width: 100%;
  padding-top: 15px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #ff8a00;
    img {
      margin-right: 8px;
    }
  }
}
