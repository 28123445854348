@import '../../../../styles/variables.less';

.export-menu {
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: @text-dark;
  }

  img {
    margin-right: 10px;
  }
}

.export-menu-btn {
  svg {
    color: @primary-color !important;
    font-size: 20px !important;
    margin-right: 0.3rem;
  }
}

.export-btn {
  width: 100%;
  height: 100%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    font-size: 24px;
  }
}
