@import '../../../../../../styles/variables.less';

.card-execution-tag {
  margin: 0px 15px 10px 0;

  span {
    text-transform: uppercase;
  }

  i {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .special-tag {
    max-width: 23ch;
  }
}
