@import '../../../../../../styles/variables.less';

#card-table-info-column {
  .card-table-info-column-data {
    display: flex;
    flex-direction: column;
    margin: 0.4rem 0;
    height: 100%;

    &.custom-border {
      padding-top: 15px;
      padding-bottom: 3px;

      border-bottom: 1px solid @border-color;
    }
  }

  strong {
    font-size: 15px;
    font-weight: 500;

    color: @text-color;
  }

  span {
    display: block;
    margin-top: 5px;

    font-size: 14.5px;
    word-break: break-word;

    &.default {
      color: @text-dark;
    }

    &.primary {
      color: @primary-color;
    }

    &.danger {
      color: @danger-color;
    }

    &.warning {
      color: @warning-color;
    }

    &.info {
      color: @info-color;
    }
  }
}
