@import '../../../../../../../../../../styles/variables.less';

#cndt-debts-items {
  .subtitle {
    display: flex;
    margin: 5px 0px 30px;

    font-size: 16px;
  }

  .cndt-debts-data {
    display: flex;

    margin-top: 5px;

    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .column-label-description {
      margin-top: 4px !important;
      font-size: 14px !important;
    }
  }

  .cndt-debts-items-title {
    strong {
      color: @text-dark;
      font-size: 16px;
    }
  }

  .cndt-debts-observation {
    display: flex;
    margin-top: 5px;

    color: @text-dark;

    font-size: 15px;
    font-weight: 500;
  }
}
