@import '../../../../../../../../styles/variables.less';

.document-authenticity-container {
  strong, h3 {
    color: @text-dark-secondary;
  }

  h3, h2 {
    margin-bottom: 0px;
  }

  .authenticity-check-title {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      display: flex;
      align-items: center;
    }

  }

  .ant-table-wrapper {
    margin-top: 10px;
  }

  .approval-diagnose {
    color: @success-color;
  }

  .failure-diagnose {
    color: @danger-color;
  }
}
