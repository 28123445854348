@import '../../../../../styles/variables.less';

#notes-box{
    padding: 24px;
    max-height: 750px;
    overflow-y: auto;
    scrollbar-width: thin; 
    scrollbar-color: @gray-color-2  @white-color; // cor da linha - cor da caixa da seta


    .notes-box-header{
        margin: 0px 0px 1rem 0px;
        h4 {
            color: @text-dark;
        };
    }
    .notes-box-view{
        background-color: @white-color;
        box-sizing: border-box;
    }
    .notes-box-view :not(:last-child){
        margin-bottom: 10px;
    }
}
#notes-footer{
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        color: @text-dark;
        span {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 450;
        }
    }
    button :hover{
        transform: scale(1.05);
        cursor: pointer;    
    }
}
 