@import '../../styles/variables.less';

.status-div {
  display: flex !important;

  &.primary {
    color: @success-color !important;
  }
  &.danger {
    color: @danger-color !important;
  }
  &.warning {
    color: @warning-color !important;
  }
  &.info {
    color: @info-color !important;
  }

  &.default {
    color: @text-color;
  }

  .div-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    min-width: 10px;

    margin: 5px 6px 0px 0px !important;
    padding: 0 !important;

    border-radius: 50%;

    background-color: @text-color;

    &.primary {
      background-color: @success-color;
    }
    &.danger {
      background-color: @danger-color !important;
    }
    &.warning {
      background-color: @warning-color !important;
    }
    &.info {
      background-color: @info-color !important;
    }
  }
}
