@import '../../../styles/variables.less';

#request-success-component {
  .ant-result {
    padding: 0px;

    .ant-result-icon {
      margin-bottom: 30px;
    }

    .ant-result-title {
      h1 {
        margin-bottom: 15px;
        color: @text-dark;
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        line-height: 1;
      }
    }

    .ant-result-subtitle {
      span {
        color: @gray-color-1;
        font-size: 15px;
        font-weight: 300;
        overflow-wrap: break-word;
        line-height: 1.4;
        text-align: center;
      }
    }
  }

  .ant-btn {
    padding: 0px 30px;
  }
}

#request-success-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 120px;
  width: 120px;
  margin: 0px auto;

  border: 5px solid @primary-color;
  border-radius: 50%;

  i {
    color: @primary-color;
    font-size: 100px;
  }
}
