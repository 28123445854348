@import '../../../../../../../../../styles/variables.less';

#basic-execution-infos-component {
  .subdivision-title {
    color: @text-dark;
    margin-bottom: 12px;
    margin-top: 18px;
    font-size: 18px;
    display: block;
  }

  #basic-executions-infos-contacts {
    color: @text-dark;
    border-top: 1px solid @border-color;
    margin-bottom: 12px;
    margin-top: 18px;
    padding-top: 18px;
    display: block;
  }

  #execution-qsa-content {
    border-top: 1px solid @border-color;
    padding-top: 18px;
  }

  .status-div {
    font-weight: 400;

    .div-dot {
      width: 10 !important;
      height: 10 !important;
      min-width: 10 !important;
      margin: 4px 6px 0px 0px !important;
    }

    span {
      font-size: 14px !important;
    }
  }
  .ghost-collapse {
    .ant-collapse-header {
      display: none;
    }
    .ant-collapse-content-box {
      padding: 0;
    }

    ul {
      margin-top: 10px;
      padding-left: 0px;

      li {
        list-style: none;
        line-height: 1.5;

        margin-bottom: 10px;
      }
    }
  }

  .executions-card-partners-content {
    margin-top: 10px;
    margin-bottom: 10px;

    .legal-representative-container {
      display: flex;
      align-items: center;
      color: @black-color;
      font-weight: bold;

      & + .legal-representative-container {
        margin-top: 10px;
      }

      span {
        font-weight: normal;
        font-size: 14px;
        margin: 0;
      }

      .legal-representative-tag {
        font-size: 12px;
        font-weight: normal;
        color: #323232;
        margin-left: 14px;
        border: 1px solid @border-color;
        line-height: 22px;
      }
    }
  }

  .execution-contact-item {
    margin-right: 20px;
    color: @text-dark;

    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
}

.partner-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 74px;

  border-top: 1px solid @border-color;
  &:last-of-type {
    border-bottom: 1px solid @border-color;
  }

  .partner-card-content {
    display: flex;
    width: 100%;
    padding: 10px 0px;
    align-items: center;
    height: 74px;
    min-height: 74px;
    max-height: 74px;

    .partner-image {
      flex: 1;
      min-height: 42px;
      min-width: 42px;
      max-height: 42px;
      max-width: 42px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 8px;
    }

    .partner-info-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;

      .partner-cpf {
        color: @text-dark;
        display: flex;
        align-items: flex-end;

        p.gx-font-italic {
          color: @gray-color-2;
          align-self: flex-end;
          margin: 0;
        }

        small {
          font-weight: 400;
          font-size: 14px;
          color: #575757;
          margin-left: 4px;
        }
      }

      .partner-info {
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;

        .partner-name-function {
          color: @text-dark;
          font-size: 14px !important;

          .partner-fuction {
            margin-left: 4px;
            word-break: keep-all;
            word-wrap: break-word;
          }
        }

        .status-div {
          margin-left: 8px;

          font-weight: 400;

          .div-dot {
            width: 10 !important;
            height: 10 !important;
            min-width: 10 !important;
            margin: 4px 6px 0px 0px !important;
          }

          span {
            font-size: 14px !important;
          }
        }
      }
    }

    .partner-open-profile-icon {
      color: @gray-color-2;
      font-size: 28px;
      font-weight: lighter;
    }
  }
}
