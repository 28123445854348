@import '../../../../../../../../../styles/variables.less';

#owners-card-container {
  margin-bottom: 24px;

  .ant-card-body {
    display: flex;
    align-items: center;

    height: 80px;
    padding: 0px;
  }

  .owners-card-infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    flex: 1;
    height: 100%;
    padding: 10px 20px;

    span {
      color: @text-dark;
      font-size: 18px;
      font-weight: bold;

      margin-bottom: 8px;
    }

    .owners-card-labels {
      display: flex;
      align-items: center;

      .status-div {
        display: flex;
        align-items: center;
        justify-content: center;

        > * {
          margin: 0;
        }

        .div-dot {
          margin-right: 6px;
        }

        span {
          font-size: 15px;
          font-weight: 400;
        }

        &:not(:first-of-type) {
          margin-left: 15px;
        }
      }
    }
  }

  .owners-card-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    // padding: 20px;
    border-left: 1px solid @border-color;

    width: 60px;

    cursor: pointer;

    i {
      font-size: 22px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
