.item-information {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  .line {
    height: 1px;
    background: #e0e0e0;
    width: 100%;
    margin: 0 0.5rem;
  }

  .field-value {
    margin-left: 0.2rem;
  }

  .no-break-text {
    white-space: nowrap;
  }

  .score-content {
    display: flex;
    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}
