@import '../../styles/variables.less';

#upload-card-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  right: 40px;
  bottom: 35px;
  width: 340px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border: none;
  margin: 0;
  z-index: 999;

  .ant-card-body {
    padding: 0;
    position: relative;
  }

  .close-upload-card {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    height: 46px;
    right: 15px;
    z-index: 99;

    button {
      display: flex;
      justify-content: center;

      height: 25px;
      width: 25px;
      border-radius: 50%;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      transition: color 0.2s;

      &:not(:disabled) {
        &:hover {
          opacity: 0.8;
        }
      }

      i {
        font-size: 18px;
        color: #fff;
        align-self: center;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }

  .ant-collapse,
  .ant-collapse-content,
  .ant-collapse > .ant-collapse-item {
    border: 0;
    background: transparent;
    color: #828282;
  }

  .ant-collapse-header {
    background-color: @secondary-color;
    color: #fff;
    display: flex;
    align-items: center;
    // width: calc(100% - 30px);
    height: 40px;
    position: relative;
    border-radius: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 13px;
    height: 46px;
    font-weight: 500;

    .ant-collapse-arrow svg {
      font-size: 15px;
      color: #979797;

      &:hover {
        color: #444;
      }
    }
  }

  .ant-collapse-content > .ant-collapse-content-box .progress-list-item-header {
    display: flex;
    align-items: center;

    span {
      padding-right: 2px;
    }
  }

  .ant-progress {
    .ant-progress-bg {
      height: 4px !important;
    }

    &.ant-progress-status-success {
      .ant-progress-bg {
        height: 1px !important;
        background-color: @border-color !important;
      }
    }
  }

  .expand-icon {
    cursor: pointer;
    margin-right: 35px;

    i {
      font-size: 26px;
    }
  }

  .status-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: @gray-color-4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @gray-color-2;
    margin-right: 10px;

    i {
      font-size: 18px;
    }

    &.status-icon-error {
      background-color: @inverse-danger-color;
      color: @danger-color;
    }

    &.status-icon-success {
      background-color: @inverse-success-color;
      color: @success-color;
    }
  }

  .delete-file-btn {
    cursor: pointer;
    background: none;
    border: none;

    i {
      font-size: 18px;
      color: @text-dark;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .status-info {
    font-size: 12px;
    font-weight: 300;
  }
}
