@import '../../../../../../../styles/variables.less';

.card-execution-wrapper {
  border-radius: 10px;
  margin-bottom: 0.8rem;
  border: 1px solid @gray-color-4;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid @gray-color-3;
  }
}

.card-execution-component {
  padding: 1rem;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 120px;
  transition: 1s;

  .card-execution-content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;

    section {
      flex: 0.7;
    }

    .person-company-infos {
      display: flex;
      flex-direction: column;

      justify-content: center;

      h3 {
        color: #2b2b2b;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        padding: 0;
        margin: 0;
        word-break: break-word;
        padding-top: 2px;
      }

      h6 {
        margin: 0;
        padding: 0;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
      }

      .wrapper-identify {
        margin-top: 7px;
        flex-direction: column;
        align-items: start;

        .wrapper-identify-icon {
          color: @primary-color;
          font-size: 16px;
          margin-right: 6px;
        }

        span {
          color: @text-dark;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .execution-details {
      flex: 0.5;
      display: flex;

      hr {
        border: 1px solid #e0e0e0;
        height: 70px;
        margin-right: 30px;
        margin-left: 30px;
        padding: 0;
        align-self: center;
      }

      .group-dates-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        height: 100%;
        padding-top: 12px;
        padding-bottom: 14px;
        min-width: 125px;

        .wrapper-dates {
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.5px;
          color: #2b2b2b;

          .description-date {
            font-weight: 800;
          }

          .content-date {
            font-weight: 400;
          }
        }
      }

      .card-info-status {
        align-items: center;
        justify-content: center;
        display: flex;
        min-width: 120px;
        display: flex;
        width: 50%;

        .wrapper-icon-text {
          justify-content: center;
          display: flex;
          flex-direction: column;
          align-items: center;

          span {
            margin-top: 10px;
            font-weight: 700;
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }
  }
}

.wrapper-footer {
  padding: 1rem;
  border-top: 1px solid @gray-color-5;
  padding-top: 0.8rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 1rem;

  .footer-reasons {
    display: flex;
    color: @heading-color;
    align-items: center;

    p {
      margin: 0;
      margin-left: 0.3rem;
    }
  }

  .alert {
    svg {
      color: @warning-color;
      font-size: 1.2rem;
      margin-bottom: 3px;
    }

    p {
      color: @warning-color;
    }
  }
}
