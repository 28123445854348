@import '../../../styles/variables.less';

#notification-center {
  .panel-title {
    font-size: 16px;
    font-weight: bold;
    color: @text-dark-secondary;
    margin-left: 10px;
  }

  .search-input {
    border-bottom: 0;
  }

  i {
    margin-left: 0px;
    color: @text-dark-secondary;
    font-size: 22px;
    font-weight: bold;
  }

  .btn-span {
    margin-left: 10px;
    font-weight: bold;
    color: @text-dark-secondary;
  }
  .isRead-icon {
    color: @primary-color;
  }
}
