@import '../../../../../../../styles/variables.less';

.KYB-financial-info {
  display: flex;
  flex-direction: column;
  .tabs {
    margin-top: 50px;
  }
  .ant-tabs-nav {
    margin-bottom: 36px;
  }
}
