@import '../../styles/variables.less';

#view-more-container {
  display: flex;
  flex: 1;
  width: 100%;

  .ant-btn {
    display: flex;
    align-items: center;

    border: none !important;
    box-shadow: none !important;
    outline: none;

    i {
      font-size: 14px;
      margin-right: 6px;
    }

    span {
      font-size: 15px;
      font-weight: normal;

      margin: 0px !important;
    }
  }

  .ant-btn-link {
    span {
      font-weight: 500 !important;
    }
  }

  .ant-btn:not(.ant-btn-circle) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .ant-btn::after {
    outline: none !important;
    background: #fff !important;
    border: none !important;
    box-shadow: none !important;
  }

  .ant-btn:hover {
    color: @primary-color;
  }

  .ant-btn-link:active {
    color: #2f80ed;
  }
}
