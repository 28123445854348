@import '../../../styles/variables.less';

.consumptions-page {
  display: flex;
  flex-direction: column;

  gap: 1rem;

  &__main-tab {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    flex-direction: column;
  }

  &__warning {
    margin: 1rem 0;
    width: max-content;
    max-width: 100%;

    button {
      right: 10px !important;
    }
  }
}

.consumption-details {
  display: flex;
  flex-direction: column;

  gap: 20px;

  &__header {
    display: flex;
    justify-content: space-between;

    hgroup {
      margin-top: 10px;
    }

    h2 {
      margin-bottom: 5px;
      font-size: 24px;
      font-weight: 700;
      line-height: 31.2px;
      text-align: left;
    }

    h3 {
      display: flex;
      align-items: center;
      gap: 5px;
      svg {
        --size: 1rem;

        width: var(--size) !important;
        height: var(--size) !important;
      }

      color: #828282;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;
    }
  }

  &__breadcrumb {
    span {
      color: #323232 !important;
    }

    a {
      color: #828282 !important;
    }
  }
}
