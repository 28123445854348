@import '../../../../../styles/variables.less';

#card-recurrent-event {
  margin-bottom: 0px !important;
  border: 0px;
  box-shadow: none;
  .ant-card-body {
    padding: 0px 24px;
    .timeline-item-container {
      display: flex;
      width: 100%;

      .day-info-content {
        display: flex;
        align-self: flex-start;

        margin-left: 30px;

        span {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          font-size: 15px;
          color: @text-dark;
          font-weight: 700;
        }

        strong {
          font-size: 35px;
          color: @text-dark;
          font-weight: 700;
        }
      }

      .activity-info-content {
        position: relative;
        flex: 1;
      }

      .timeline-item-tail {
        position: absolute;
        left: 30px;
        height: 100%;
        border-left: 2px solid @border-color;
      }

      .card-footer {
        display: flex;
        position: relative;
        padding: 3px 20px;
        background-color: transparent;
        border-radius: 0 !important;
        border-top: 1px solid @border-color !important;
        justify-content: space-between;
        align-items: center;

        span {
          line-height: 1.2;

          &:first-letter {
            text-transform: capitalize;
          }
        }

        .separator {
          font-style: normal;
          margin: 0 4px;
          line-height: 1;
          color: @text-color-secondary;
        }
      }
    }

    .ant-timeline-item {
      display: flex;
      align-items: center;

      width: 100%;

      .ant-card {
        margin-bottom: 0 !important;
      }

      .ant-timeline-item-content {
        width: 100%;
        margin: 11px 0 11px 22px;
      }

      .ant-timeline-item-tail {
        display: none;
      }

      .ant-timeline-item-head-custom {
        left: 30px;
        top: -3px;
        align-self: center;

        position: relative;
        z-index: 0;
        background-color: none;
        transform: translate(-50%, 0%);
      }
    }

    .ant-timeline-item:not(:last-child) {
      padding-bottom: 0px;
    }

    .cpf-query-details {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;

      width: 100%;

      .status-div {
        flex-shrink: 0;
      }
    }
  }
}
