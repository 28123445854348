@import '../../../../../../../styles/variables.less';

#card-content-documents {
  #empty-message-component {
    flex: 1;
  }

  .card-documents-header {
    .documents-header-container {
      display: flex;
      //align-items: center;
      justify-content: space-between;

      width: 100%;

      .ant-form .ant-row,
      .ant-row {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }

      .ant-row .ant-form-item {
        margin-bottom: 0px !important;
      }

      .ant-input {
        font-size: 15px;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 20px;
      }
    }
  }

  .card-documents-data {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    margin: 0 0 5px !important;
  }

  .card-document {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;

    .document-image-container {
      display: flex;
      justify-content: center;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      width: 100%;
      height: 140px;
      margin-bottom: 12px;
      border-radius: 8px;
      border: 1px solid #fff;
      background-color: #e6eaf2;

      transition: border-color 0.3s;
    }

    .document-image {
      position: absolute;
      top: 8px;
      width: calc(100% - 16px);
      height: calc(100% - 8px);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      background-position: center;
      background-size: 90%;
      background-repeat: no-repeat;
      overflow: hidden;
      background-color: #fff;
      z-index: 1;
      opacity: 0.9;
    }

    .document-extension-tag {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 2px 8px 4px;
      border-radius: 4px;
      color: #ffffff;
      z-index: 2;
    }

    .document-infos-container {
      display: flex;
      width: 100%;

      i {
        font-size: 16px;
      }
    }

    .document-infos-text {
      display: flex;
      flex-direction: column;
      flex: 1;

      .file-name {
        width: fit-content;
      }
    }

    span:first-of-type {
      font-weight: 700;
      font-size: 16px;
      color: @text-dark;
      margin: 0 5%;
      margin-bottom: 5px;
      transition: color 0.3s;
    }

    span:last-of-type {
      font-size: 12px;
      color: @text-color;
      margin: 0 5%;
    }

    &:hover .document-image-container {
      border-color: @info-color;
    }

    &:hover span:first-of-type {
      color: @info-color;
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 50px 0px !important;

    .ant-spin {
      margin-bottom: 0 !important;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 0 10px;
    margin-top: 20px;

    .ant-pagination > li {
      margin-bottom: 0 !important;
    }
  }

  .ant-input-group.ant-input-group-compact > *:first-child {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  #empty-message-component {
    .ant-empty {
      padding: 0px !important;
    }
  }
}

.dropdown-generate-link {
  i {
    font-size: 15px;
  }
  span {
    font-weight: 500;
  }
}
