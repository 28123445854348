@import '../../../../../../../../../../styles/variables.less';

.address-line {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D1D1D1;
  color: @text-dark;
}

.modal-addresses-content {
  &.no-height-limit {
    max-height: none;
  }

  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

