.col-antd-warrant {
  margin-top: 25px;
  padding-left: 16px;
  padding-right: 16px;
}

.row-antd-warrant {
  padding-left: 16px;
  padding-right: 16px;
}
