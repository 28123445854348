@import '../../../../../../styles/variables.less';

.consulted-companies-list {
  .title {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: @text-dark;
    margin: 0;
  }

  .filters {
    display: flex;
    justify-content: flex-end;

    .ant-select-arrow {
      color: black;
      top: 40%;
    }
  }

  .pagination-label {
    margin: 0;
    margin-bottom: 30px;
  }

  .loading {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }

  .ant-pagination {
    justify-content: center;

    .ant-pagination-total-text {
      display: none;
    }
  }

  .card {
    border-radius: 10px;
    margin-bottom: 0.8rem;
    border: 1px solid #d1d1d1;

    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }

    .card-component {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 120px;
      transition: 1s;
      margin: 0;
      color: #2b2b2b;

      .card-section {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-right: 1px solid #d1d1d1;
        margin: 0;

        &.first {
          flex-direction: column;
          align-items: flex-start;

          .name {
            display: inline;
            margin-top: 5px !important;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 16px;
            padding: 0;
            margin: 0;
            word-break: break-word;
            margin-bottom: 5px;
            margin-right: 5px;
          }

          .workflow-name {
            color: @text-color;
            margin-bottom: 10px;
          }

          .company-number {
            font-size: 12px;

            .wrapper-company-number {
              display: flex;
              align-items: flex-end;

              img {
                margin-right: 5px;
              }
            }
          }
        }

        &.second {
          .address {
            padding-left: 0;
            text-align: left;
          }

          .dates {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
            font-size: 12px;
            padding-right: 0;
            text-align: right;

            p {
              margin-bottom: 0;
              font-weight: bold;
            }
          }
        }

        &.third {
          display: flex;
          flex-direction: column;

          .ant-tag {
            margin-bottom: 5px;
          }
        }

        &.fourth {
          display: flex;
          flex-direction: column;

          span {
            margin-top: 2px;
            font-weight: 700;
            font-size: 14;
            line-height: 18.2px;
          }

          .approved {
            color: #39c560;
          }
          .rejected {
            color: #e21b45;
          }
          .pending {
            color: #f16e00;
          }
          .processing {
            color: #763ad7;
          }
        }
      }

      :last-child {
        border: none;
      }
    }
  }

  .sources-found {
    display: flex;
    align-items: flex-start;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    font-size: 13px;

    span {
      display: flex;
      align-self: flex-end;
      text-align: center;
      background-color: #004af71f;
      border-radius: 50px;
      color: #004af7;
      padding: 6px 6px;
    }
  }
}
