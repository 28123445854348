@import '../../../../../styles/variables.less';

#credit-operations-scr {
  .danger-span {
    color: @danger-color;
  }
  .info-span {
    color: @info-color;
  }
  .button-panel {
    font-weight: bold;
    color: @info-color;
  }
  .span-list {
    font-size: 14px;
    font-weight: bold;
    line-height: 3;
  }
  .total-span {
    color: @danger-color;
  }
}
