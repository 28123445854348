@import '../../styles/variables.less';

#empty-message-component {
  .ant-empty {
    padding: 30px 15%;
  }

  .ant-empty-description {
    color: @text-dark;
    opacity: 0.5;
    font-weight: 300;
    margin-bottom: 2px;
  }

  .ant-empty-image {
    height: 40px;
    margin-bottom: 10px;

    i {
      font-size: 40px;
      color: @text-dark;
      opacity: 0.15;
    }
  }

  &.with-card {
    .ant-empty {
      border: 1px solid @border-color;
      border-radius: 8px;
      padding: 70px 15%;
    }
  }

  &.empty-error {
    .ant-empty-image {
      i {
        color: @danger-color;
        opacity: 0.25;
      }
    }
  }
}
