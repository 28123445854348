@import '../../../../../../../../../styles/variables.less';

.KYB-financial-info-table {
  overflow-x: auto;
  .ant-table-thead {
    background-color: transparent;
  }
  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
  tr.ant-table-row,
  .ant-table .ant-table-thead > tr > th {
    height: 48px;
  }
  .ant-table .ant-table-tbody > tr > td,
  .ant-table .ant-table-thead > tr > th {
    padding: 8px 16px;
  }
}
