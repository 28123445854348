@import '../../styles/variables.less';

#content-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-top: 14px;
  justify-content: center;
  align-items: center;

  .ant-input-affix-wrapper .ant-input-prefix {
    margin-left: 15px;
  }

  .profile-name {
    color: @text-dark;
    margin-top: 28px;
  }

  .copy-link-container {
    display: flex;
    margin-top: 4px;
    height: 20px;
    margin-bottom: 4px;
    font-size: 18px;

    .copy-link-text {
      color: @gray-color-1;
      white-space: nowrap;
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .copy-link-button {
      border: 0px;
      background: transparent;
      outline: 0px;
      //font-size: 14px;
      height: 18px;
      color: #2e70f0;
      //width: 84px;
      cursor: pointer;
    }
  }

  .input-grid {
    display: grid;
    grid-template-columns: 149px 1fr;

    .form-phone {
      border-radius: 0px;
      height: 35px;
      border-bottom: 1px solid #eee;
    }
    .form-mail {
      border-radius: 0px;
      height: 35px;
      border-bottom: 1px solid #eee;
      margin-left: 26px;
    }
  }
}

.modal-action-buttons {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 54px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: 166px;
  }
}
