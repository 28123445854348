@import '../../../../../../../../styles/variables.less';

#personal-labor-and-financial-information-items {
  #page-title-component {
    margin-bottom: 25px !important;

    .title {
      font-size: 26px !important;
    }
  }

  .personal-labor-and-financial-information-data {
    padding: 20px 0px;
    border-top: 1px solid @border-color;

    .ant-col {
      display: flex;
      flex-direction: column;

      padding-left: 0px !important;
      padding-right: 0px !important;

      color: @text-dark;

      strong {
        font-size: 15px;
      }

      span {
        font-size: 14px;
      }
    }

    .personal-labor-and-financial-information-type-infos {
      padding-left: 20px !important;
      border-left: 1px solid @border-color;
    }
  }
}
