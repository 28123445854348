@import '../../../../../styles/variables.less';

#card-link-wrapper {
  border-radius: 8px;
  border: 1px solid @border-color;
  background: @white-color;
  border-bottom: 3px solid @border-color;
  min-height: 157px;
  width: 100%;
  transition: 1s;
  padding: 2rem 2.2rem 2rem 2.2rem;

  /*  & + &#card-link-wrapper {
    margin-top: 20px;
  } */

  .ant-switch {
    background: #444444;
  }

  & + &#card-link-wrapper {
    margin-top: 20px;
  }

  .ant-row {
    .ant-col-5 {
      border-left: 1px solid @border-color;
      border-right: 1px solid @border-color;
    }

    .ant-col-4 {
      border-right: 1px solid @border-color;
    }

    .ant-col-2 {
      margin: auto;
      padding-left: 0px !important;
    }
  }

  .profile-template-inactive-icon {
    > span {
      margin-left: 0px !important;
    }
  }

  .link-type-icon {
    background: @inverse-primary-color;
    border-radius: 50%;
    min-width: 52px;
    min-height: 52px;
    width: 52px;
    height: 52px;

    i {
      color: #4c4c4c;
    }
  }

  .data-link-info {
    width: 100%;

    h3 {
      margin-bottom: 3px;
    }

    div {
      display: flex;
      justify-content: space-between;

      .onboarding-link-name {
        display: flex;
        justify-content: center;
        margin-right: 30px;

        p {
          a {
            color: @info-color;
          }
        }

        small {
          cursor: pointer;
          color: @info-color;
          transition: filter 0.2s;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }

      section:not(.onboarding-link-name) {
        display: flex;
        gap: 5px;
      }
    }
  }

  #active-inactive-tag {
    &.tag-active {
      background-color: @primary-color;
      color: @white-color;
    }

    &.tag-inactive {
      background-color: rgba(0, 0, 0, 0.25);
      color: @white-color;
    }
  }

  #link-date-info {
    border-top: 1px solid @border-color;
  }
}
