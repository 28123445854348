.tabs-cmpnt-container {
  position: relative;

  .tabs-cmpnt-content {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .tab-content {
      overflow: hidden;
      scroll-behavior: smooth;
      margin: 0 10px;

      .tab-component {
        position: relative;
        width: min-content;

        .tabs {
          display: flex;
          align-items: center;
          justify-content: center;

          .button-container {
            background: #fff;
          }

          .tab-btn {
            display: block;
            flex: 1;
            background: #eee;
            border: 0;
            white-space: nowrap;
            margin: 0 0.5rem 0.5rem 0.5rem;
          }
        }
      }
    }
  }

  .tab-indicator-container {
    display: flex;
  }

  .tab-indicator {
    height: 3px;
    background: #007bff;
    transition: transform 0.2s;
    position: relative;
  }

  .button-icon {
    color: #004af7;
    font-size: 20px;
    background: rgba(0, 74, 247, 0.2) !important;
    width: 32px;
    height: 32px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  button[type='button']:disabled {
    opacity: 0.1;
    cursor: no-drop;
  }

  .rightside-icon {
    right: 0;
  }

  .leftside-icon {
    left: 0;
  }

  .tabpanel-container {
    margin-top: 1rem;
  }
}
