#confirmation-card-onboarding-component {
  display: flex;
  flex-direction: column;
  flex: 1;

  .services-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    padding: 7px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    i {
      padding-right: 10px;
      font-size: 18px;
    }

    span {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
