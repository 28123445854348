.chart {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &_no-data {
    height: auto;
  }

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__select {
    width: 300px;
  }
}

.bar-chart {
  svg {
    margin-left: -30px;
  }

  &__legend-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style-type: none;
    margin-top: 20px;
    width: 100%;
    max-width: 100%;

    @media (max-width: 1650px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    p {
      max-width: 275px;

      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      color: #828282;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;
    }

    span {
      --size: 10px;

      width: var(--size);
      height: var(--size);
      display: inline-block;
      border-radius: 2px;

      margin-right: 10px;
    }
  }

  &__tooltip {
    .ant-card-body {
      padding: 10px;
      padding-left: 25px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      position: relative;
    }

    p {
      margin: 0;
      color: #323232;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;

      max-width: 250px;
      overflow-wrap: break-word;
    }

    span {
      --size: 10px;

      position: absolute;
      top: 16.5px;
      left: 10px;

      width: var(--size);
      height: var(--size);
      display: inline-block;
      border-radius: 2px;
      margin-right: 10px;
    }
  }
}
