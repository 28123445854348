@import '../../../../../../../../../styles/variables.less';

#label-ocr-col {
  font-size: 15px;
  .label-ocr-col-div {
    border-right: 1px solid @border-color;
    height: 100%;
  }
  span,
  strong {
    margin-right: 0.5rem;
    word-break: break-word;
    color: @text-dark-secondary;
  }
  span {
    display: block;
    font-weight: 400;
    margin-bottom: 3rem;
    &.primary {
      color: @primary-color;
      font-weight: 700;
    }
    &.danger {
      color: @danger-color;
      font-weight: 700;
    }
    &.warning {
      color: @warning-color;
      font-weight: 700;
    }
    &.info {
      color: @info-color;
      font-weight: 700;
    }
  }
  strong {
    font-weight: 700;
    margin-bottom: 0.4rem;
  }
}
#more-details-button {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: @primary-color;
  margin-left: 8px;
  cursor: pointer;
}
#more-details-button strong {
  margin-left: 5px;
}

.no-border-right-col-3 {
  #label-ocr-col {
    &:nth-child(3n) {
      .label-ocr-col-div {
        border-right: none;
      }
    }
  }
}

.no-border-right-col-4 {
  #label-ocr-col {
    &:nth-child(4n) {
      .label-ocr-col-div {
        border-right: none;
      }
    }
  }
}

.no-border-right-col-5 {
  #label-ocr-col {
    &:nth-child(5n) {
      .label-ocr-col-div {
        border-right: none;
      }
    }
  }
}
