.empty-tags-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  span {
    display: flex;
    margin-top: 20px;

    font-size: 15px;
    line-height: 1.5;
    text-align: center;
  }

  img {
    max-height: 140px;
  }
}
