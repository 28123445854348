@import '../../../../../../../../styles/variables.less';

.kyb-company-partners-list-menu {
  .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    padding: 10px !important;
    font-weight: bold;
    font-size: 16px;
    height: 40px;
    &:hover {
      background-color: @color-caf-blue-lighter;
    }

    .partner-status-and-name {
      display: flex;
      align-items: center;
      margin-right: 10px;
      line-height: normal;
      .icon {
        margin-right: 10px;
      }
      .icon-check-circle {
        color: #34d690;
        font-size: 1.5em;
      }
      .icon-alert-triangle {
        color: #ff9500;
        font-size: 1.5em;
      }
      .loading-icon {
        display: inline-block;
      }
    }

    .icon-arrow-details {
      font-size: 1.5em;
    }
  }
}

.kyb-company-partners-list-dropdown {
  border: 1px solid @border-color-3;
  border-radius: 24px;
  padding: 20px;
}
