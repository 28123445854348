@import '../../../../../styles/variables.less';
@import '../../../../../styles/global/variables.less';

#profile-widget-collapse {
  background: #fff;
  position: relative;

  .ant-collapse-header {
    padding: 16px;

    .anticon {
      display: none;
    }
  }

  &.reset-border-top {
    .ant-collapse-content {
      border-top: none !important;
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 15px 20px;
    border-top: 1px solid @grey-4;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }

  // &.custom-footer {
  //   .ant-collapse-content-box {
  //     padding-bottom: 50px !important;
  //   }
  // }

  .report-configuration {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }

  .compliance-group-title {
    display: flex;
    align-items: center;

    width: 100%;
    padding: 5px 20px;

    border-bottom: 1px solid @border-color;
    border-top: 1px solid @border-color;

    background: #fafafa;

    span {
      color: @gray-color-1;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: normal;
    }
  }

  .personal-data-info {
    // margin-bottom: 4px;
    padding: 20px;

    &.bordered {
      padding: 15px 20px;
      & + .bordered {
        // background-color: red;
        border-top: 1px solid @grey-4;
      }
    }

    p {
      font-size: 15px;
      line-height: 1.3;
      color: @text-dark;
      margin: 0;
    }

    .ant-empty {
      p {
        font-size: 14px;
      }
    }
  }

  .identity-info {
    margin: 5px 20px;

    padding-top: 15px;
    padding-bottom: 15px;
  }

  .custom-loading-spin {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    &.identity {
      margin-top: 30px;
      margin-bottom: 25px;
    }

    &.trust-validations {
      margin-top: 50px;
      margin-bottom: 45px;
    }

    &.tags-list {
      margin-top: 25px;
      margin-bottom: 10px;
    }
  }
}

#profile-widget-title {
  display: flex;
  align-items: center;

  font-size: 15px;
  font-weight: 500;
  color: @text-dark;
  text-transform: uppercase;

  i {
    font-size: 20px;
    margin-right: 10px;
  }
}

#profile-widget-subtitle {
  margin: 10px 0px 5px;
  color: @text-color;
  font-size: 14.4px;
}

div.ant-collapse:nth-child(1) {
  overflow: hidden;
}
