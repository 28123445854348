table {
  margin: 0px auto;
  width: 100%;

  thead {
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    th {
      padding: 0.2rem 0.5rem;
      text-align: left;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #ddd;
      td {
        padding: 0.2rem 0.5rem;
      }
    }
  }
}
