@import '../../../../../../../styles/variables.less';

.wrapper-custom-header-KYC {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}

.left-side-header {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 250px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background: rgba(0, 74, 247, 0.1);
  }

  img {
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: @text-dark;
    margin-left: 10px;
  }
}
