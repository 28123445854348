@import '../../styles/variables.less';

.preview-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  h1 {
    color: @primary-color;
    font-weight: bold;
    margin-bottom: 20px;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 700px;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
