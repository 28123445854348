@import '../../../../../../../../../styles/variables.less';

#source-infos-to-print {
  .source-infos-modal-title {
    &.custom-title {
      .title {
        font-style: italic;
      }
    }

    &.custom-subtitle {
      .subtitle {
        font-style: italic;
      }
    }

    .subtitle {
      display: block;
      margin-top: 5px;

      font-size: 16px;
    }
  }

  .information-source-data-details {
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 1px 8px;
    border-left: 2px solid @border-color;

    span {
      display: flex !important;
      flex-wrap: wrap;

      color: @text-dark;
      font-size: 15px !important;
      font-weight: normal !important;

      strong {
        font-weight: 500 !important;
      }
    }

    span:not(:last-of-type) {
      margin-bottom: 5px;
    }

    &:nth-of-type(n + 3) {
      margin-top: 20px;
    }

    .information-source-data-image {
      margin-top: 10px;

      .ant-btn {
        span {
          color: inherit !important;
        }
      }

      .ant-collapse-header {
        display: none !important;
      }

      .ant-collapse-content-box {
        padding: 5px 0px 0px !important;
      }

      img {
        border-radius: 4px;
      }
    }
  }
}
