@import '../../../../../../styles/variables.less';

#subheader-group-component {
  background-color: @secondary-color;
  .gx-header-horizontal-top {
    padding: 16px 0;
  }

  .custom-horizontal-pdd {
    padding-top: 30px;

    &.retract {
      padding-bottom: 20px;
    }
  }

  .subheader-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subheader-info {
    flex: 1;
    padding-right: 15px;

    transition: all 0.2s ease-in-out;
  }

  // .subheader-image {
  //   border-radius: 8px;

  //   overflow: hidden;

  //   -o-object-fit: cover;
  //   object-fit: cover;

  //   background-color: @white-color;
  //   background-size: cover;
  //   background-position: center;
  //   background-repeat: no-repeat;

  //   position: absolute;
  //   z-index: 2;

  //   transition: all 0.2s ease-in-out;

  //   &.expand {
  //     width: 130px;
  //     height: 130px;
  //     bottom: -25px;
  //   }

  //   &.retract {
  //     width: 110px;
  //     height: 110px;
  //     bottom: 10px;
  //   }
  // }

  .custom-breadcrumbs {
    &.expand {
      margin-left: 155px;
    }

    &.retract {
      margin-left: 135px;
    }
  }

  .subheader-name {
    color: @white-color;
    font-weight: 900;
    font-size: 30px;
    margin: 0 0 2px;

    width: fit-content;
    word-break: break-word;
  }

  .subheader-last-update {
    font-size: 13px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }

  .subheader-update-profile {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 42px;
    height: 42px;
    padding: 0px;

    border: 1px dashed rgba(#fff, 0.8);
    border-radius: 50%;
    background: transparent;

    i {
      color: #ffffff;
      font-size: 21px;

      margin-left: 2px;
    }

    span {
      color: #ffffff;
      margin-left: 5px;
    }

    &:hover,
    &:focus {
      border-color: @primary-color;
      background: rgba(#fff, 0.05);
    }
  }

  .tag-you {
    background-color: @primary-color;
    color: @white-color;
  }
}
