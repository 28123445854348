.not-connect-ok-button {
  color: white;
  background-color: #e21b45;
}

.modal-connect-content {
  height: 238px;
}

.title-connect-modal {
  font-size: 32px !important;
  margin-top: 28px;
}

.text-connect-modal {
  font-size: 16px;
  color: '#828282';
  font-weight: 400;
  line-height: 20.8px;
  text-align: center;
  width: 352px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.button-container-connect-modal {
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  gap: 8px;
}

.final-text-not-connect {
  font-size: 32px !important;
  font-weight: 700;
  margin-top: 64px;
  line-height: 41.6px;
  text-align: center;
  width: 485px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.final-text-connect {
  font-size: 32px !important;
  font-weight: 700;
  line-height: 41.6px;
  text-align: center;
  width: 485px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
