@import '../../../../../../../../styles/variables.less';

#basic-infos-card,
#basic-infos-contacts {
  margin-left: 0px;
  margin-right: 0px;

  padding-bottom: 10px;

  .basic-infos-content {
    .ant-row:first-of-type {
      margin-right: 0px;
    }

    .basic-infos-title {
      margin-top: 2px;
      margin-bottom: 20px;
    }

    .address-formatted {
      margin-bottom: 20px;
      color: @text-dark;
    }
  }

  .contact-section-title {
    // margin-top: 10px;

    color: @text-dark;
    font-weight: 700;
  }

  #column-label-container {
    margin-bottom: 20px;
  }
}

.iframe-map {
  border: 1px solid @border-color;
  border-radius: @border-radius-base;
}
