@import '../../../styles/variables.less';

.face-auth-content-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 20px;
}

.face-auth-content-grid-1 {
  min-width: 0px;
}

.face-auth-content-grid-2 {
  min-width: 300px;
}
