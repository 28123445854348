@import '../../../../../styles/variables.less';

#profile-global-content {
  display: flex;
  margin-bottom: 25px;
  padding: 0;

  #profile-content-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .ant-tabs-nav-more {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  #widgets {
    position: sticky;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    overflow-y: auto;
    padding-bottom: 100px;
    max-height: calc(100vh - 170px);

    transition: all 0.2s ease-in-out;

    &.expand {
      margin-top: 45px;
    }

    &.retract {
      margin-top: -25px;
      padding-top: 25px;
    }
  }
}
