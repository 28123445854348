@import '../../../../styles/variables.less';

#icon-status-content-notification {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 38px;
  height: 38px;
  opacity: 1 !important;
  flex-shrink: 0;

  i {
    &.ACTIVATE {
      border: 7px solid rgba(@primary-color, 0.01);
      color: @primary-color;
      background-color: #004af714;
    }
    &.CREATE {
      border: 7px solid rgba(@primary-color, 0.01);
      color: @primary-color;
      background-color: #004af714;
    }
    &.UPDATE {
      border: 7px solid rgba(@info-color, 0.01);
      color: @primary-color;
      background-color: #004af714;
    }
    &.INACTIVATE {
      border: 7px solid #eeeeee;
      color: #575757;
      background-color: #eeeeee;
    }
    &.WARNING {
      border: 7px solid rgba(@info-color, 0.01);
      color: @primary-color;
      background-color: #004af714;
    }

    border-radius: 50%;
    padding: 4px;
    font-size: 18px;
  }
}
