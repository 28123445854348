.Tooltip {
  color: white;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.75);

  .Tooltip__title {
    font-weight: 600;
    font-size: 0.8rem;
    margin: 0;
  }

  .Tooltip__subtitle {
    margin: 0;
  }
}
