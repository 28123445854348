@import '../../../../../../../styles/variables.less';

#create-link-wrapper {
  .custom-label-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    a:hover {
      border-bottom: 1px solid @primary-color;
    }
  }
}
.input-phonenumber-2 {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  color: @text-dark;
}
.ant-select-item-group {
  font-weight: bold;
}
.one-time-link-form-item {
  margin-top: -18px !important;
}
