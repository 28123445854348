@import '../../../../../../../styles/variables.less';


#delete-modal{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 25px;
        margin-bottom: 45px;

        strong {
           font-weight: 700;
           font-size: 2rem;
           line-height: 40px;
           margin-bottom: 25px;
           color: @text-dark;
        }
        span{
           font-size: 16px;
           color: @gray-color-1;
        }
}
#delete-modal-footer{
        display: flex;
        justify-content: flex-end;
}
#post-modal{
        div{
          color: @text-dark        
        }
        strong {
          font-size: 1.2rem;
          color: @text-dark;
        }
}
#post-modal-footer{
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
}