@import '../../../../../styles/variables.less';

#card-executions {
    
  font-size: 14px;


  .ant-collapse {
    background: none;
  }
  .ant-card-body {
    background-color: #f9f9f9;
    border: 1px solid #ebebeb;
    border-radius: 8px;
  }
  .header-collapse {
    margin-top: 10px;
    .ant-col:first-child {
      padding: 0;
    }
  }
  .ant-collapse .ant-collapse-item > .ant-collapse-header {
    padding: 12px 4px !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 0px;
  }
  .ant-collapse-header {
    padding-left: 0px;
  }
  .collapse-execs {
    color: @text-dark;
    
  }
  .row-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    color: @text-dark;
  }
  .card-info-status {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-width: 120px;
    display: flex;

    .wrapper-icon-text {
      justify-content: center;
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        font-size: 12px !important;
      }

      span {
        font-weight: 700;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
#spin-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
