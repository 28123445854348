@import '../../../../../../../styles/variables.less';

#executions-report-spc-items {
  #page-title-component {
    .subtitle {
      display: flex;
      margin: 5px 0px 30px;

      font-size: 16px;
    }
  }

  .spc-items-title {
    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;

      strong {
        color: @text-dark;
        font-size: 16px;
      }
    }
  }

  .end {
    text-align: end;
  }

  .bold {
    font-weight: 600;
  }

  .spc-items-data {
    display: flex;
    margin-top: 10px;

    color: @text-dark;

    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    #column-label-container {
      margin-top: 8px !important;
      margin-bottom: 5px !important;

      .column-label-description {
        margin-top: 5px;
        color: @text-color !important;

        span {
          font-size: 14px !important;
        }
      }

      .column-label-description span:first-of-type {
        margin-top: 0px !important;
      }
    }

    // &:not(:last-of-type) {
    //   border-bottom: 1px solid @border-color;
    // }
  }

  .dates-row {
    border-bottom: 1px solid @border-color;
    padding-bottom: 20px;
    margin-left: 15px !important;
  }

  .spc-items-data + .spc-items-data {
    padding-top: 10px;
  }
}
