@import '../../../../../styles/variables.less';

.manual-upload-documents {
  position: relative;

  &.loading {
    display: flex;
    justify-content: center;
  }

  .loading-manual-upload-documents {
    margin: 200px 0px;
  }

  .manual-query-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 30px;

    .manual-query-btns {
      .ant-btn {
        padding: 0px 30px !important;
      }
    }
  }

  .loading-manual-upload-documents {
    margin: 200px 0px;
  }

  .manual-query-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 30px;

    .manual-query-btns {
      .ant-btn {
        padding: 0px 30px !important;
      }
    }
  }

  .manual-upload-documents-type-section {
    display: flex;
    flex-direction: column;

    width: 100%;

    .manual-upload-documents-type-section-header {
      display: flex;
      align-items: center;

      width: 100%;
      margin-bottom: 15px;

      font-size: 14px;
      font-weight: 300;

      span {
        display: block;
        flex: 1;

        color: @text-dark;
      }

      .ant-switch {
        margin-left: 10px;
      }
    }

    .ant-radio-group {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      .ant-radio-button-wrapper {
        display: flex;
        align-items: center;

        width: calc(50% - 15px);
        height: 100%;

        border-radius: 10px;
        border-width: 1px;
        padding: 15px 18px;

        span:nth-child(2) {
          display: flex;
          height: 100%;
        }

        &:not(.ant-radio-button-wrapper-disabled) {
          &:hover {
            border-color: @primary-color;
          }
        }
      }

      .ant-radio-button-wrapper-checked {
        background-color: @inverse-primary-color;
      }

      .ant-radio-button-wrapper-disabled {
        background: transparent;
        opacity: 0.4;

        cursor: default;
      }

      .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
      }

      .manual-upload-documents-type-btn-content {
        display: flex;
        align-self: center;

        img {
          margin-right: 15px;
          height: 70px;
        }

        div {
          display: flex;
          flex-direction: column;

          line-height: normal;

          strong {
            display: block;
            margin-bottom: 6px;
            margin-top: 1px;

            font-size: 16px;
            color: @text-dark;
          }

          span {
            font-size: 13px;
            color: lighten(@text-dark, 22%);
          }
        }
      }
    }
  }

  .manual-upload-documents-images-section {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-top: 15px;

    .manual-upload-documents-images-header {
      margin-bottom: 20px;

      span {
        font-size: 14px;
        font-weight: 300;
        color: @text-dark !important;
      }
    }
  }

  .manual-upload-documents-btn-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 25px;

    .ant-btn {
      display: flex;
      align-items: center;
      justify-items: center;

      padding-left: 35px !important;
      padding-right: 35px !important;

      height: 40px !important;

      > span {
        font-size: 15px;
      }
    }
  }
}
