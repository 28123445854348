@import '../../../styles/variables.less';

#files-component {
  background-color: @gray-color-4;

  > .ant-col {
    margin-bottom: 5%;
  }

  .logotipo {
    height: 56px;
    margin-bottom: 40px;
  }

  .card-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(#000, 0.05);
    border-radius: 10px;
    padding: 55px 25px;
    width: 100%;

    .ant-spin {
      margin: 0;
    }

    span {
      display: block;
      color: @text-color;
      margin-top: 15px;
      font-weight: 300;
      font-size: 16px;
    }

    h4 {
      margin-top: 25px;
      margin-bottom: 0;
      font-weight: 300;
      font-size: 22px;
      opacity: 0.8;
    }

    .icon-error,
    .icon-success {
      font-size: 50px;
    }

    .icon-error {
      color: @danger-color;
    }

    .icon-success {
      color: @success-color;
    }
  }
}
