@import '../../../../../styles/variables.less';

#activities-list-card-content {
  .ant-card {
    margin-bottom: 10px !important;
  }

  .card-header-title {
    width: 100%;
    margin-bottom: 5px;

    span {
      display: flex;
      align-items: center;

      color: @text-dark;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .content {
    padding: 24px;
  }

  .ant-card-body {
    width: 100%;
    padding: 0 !important;
  }
}

.card-content:first-of-type {
  margin-bottom: 20px;
}
