@import '../../../../../../../../../styles/variables.less';

#protests-items {
  .subtitle {
    display: flex;
    margin: 5px 0px 30px;

    font-size: 16px;
  }

  .protests-items-title {
    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;

      strong {
        color: @text-dark;
        font-size: 16px;
      }
    }
  }

  .end {
    text-align: end;
  }

  .bold {
    font-weight: 600;
  }

  .protests-items-data {
    display: flex;

    margin-top: 10px;

    color: @text-dark;
    padding-bottom: 20px;

    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    #column-label-container {
      margin-bottom: 20px !important;

      .column-label-description {
        margin-top: 5px;
        color: @text-color !important;

        span {
          font-size: 14px !important;
        }
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid @border-color;
    }
  }

  .protests-items-data + .protests-items-data {
    padding-top: 10px;
  }
}
