@import '../../../../../../styles/variables.less';

#execution-filter-component-KYB {
  .reset-filters-button {
    border: 2px solid #004af7;
    color: #004af7;
    font-size: 18px;
    line-height: 16px;
  }

  .ant-collapse-arrow {
    i {
      font-size: 22px;
    }
  }

  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 7px 0;
    color: @text-dark;
    font-weight: 300;
    font-size: 13px;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    background-color: transparent;

    &::after {
      top: 4px;
      left: 4px;
      width: 10px;
      height: 10px;
      background-color: @gray-color-4;
      border-radius: 50%;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: @primary-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-radio {
    top: -0.1em;
  }

  .ant-checkbox {
    top: -0.04em;
  }

  .ant-checkbox-inner {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    background-color: transparent;

    &::after {
      font-family: 'caf-icons';
      content: '\e813';
      top: 1px;
      left: 2px;
      font-size: 15px;
      border: none;
      color: @gray-color-4;
      transform: scale(1);
    }
  }

  .ant-collapse {
    border: none;
    margin-left: -16px;

    .ant-collapse-header {
      font-size: 20px;
      font-weight: 700;
    }

    .ant-collapse-item {
      border: none;

      .ant-collapse-content {
        border: none;
        background-color: transparent;
        overflow: initial;

        .ant-collapse-content-box {
          padding-top: 0;
        }
      }
    }
  }

  .ant-select-multiple {
    border-bottom: 1px solid transparent;
    width: 100%;

    &.ant-select-focused {
      border-bottom: 2px solid @primary-color;

      .ant-select-selector {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 9px 28px 8px rgba(0, 0, 0, 0.05);
        background: #fff;
        border-bottom-color: @primary-color;
        border: none;
      }

      .ant-select-arrow-loading {
        background: #fff;
      }
    }

    .ant-select-selection-item {
      height: 24px;
      margin-right: 5px;
      font-size: 13px;
      background: @primary-color;
      color: #fff;
      padding-left: 12px;
      padding-right: 9px;
      border-radius: 100px;
      border: none;

      .ant-select-selection-item-content {
        margin-right: 7px;
      }
    }
  }

  .ant-select-single {
    border-bottom: 1px solid transparent;
    width: 100%;

    &.ant-select-focused {
      border-bottom: 2px solid @primary-color;

      .ant-select-selector {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
          0 9px 28px 8px rgba(0, 0, 0, 0.05);
        background: #fff;
        border-bottom-color: @primary-color;
        border: none;
      }

      .ant-select-arrow-loading {
        background: #fff;
      }
    }
  }

  .input-search-term {
    border-radius: 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .ant-select-selection-item-remove {
    line-height: 1;

    i {
      font-size: 12px;
      color: #fff;
    }
  }

  .ant-select-show-search {
    .ant-select-selector {
      .ant-select-selection-search {
        flex: 1;
        padding-right: 22px;

        input {
          cursor: text;
        }

        &::after {
          font-family: 'caf-icons';
          content: '\e834';
          position: absolute;
          right: 0;
          color: #bbb;
          font-size: 17px;
          bottom: 0;
          line-height: 1;
        }
      }
    }
  }

  .ant-select-arrow-loading {
    bottom: 8px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: auto;
    right: 7px;
    background: @body-background;
    color: @text-dark;
  }

  .profile-select {
    width: calc(100% - 16px);
    margin-bottom: 20px;

    .ant-select-selector {
      box-shadow: none !important;
      background: transparent !important;
    }
  }

  .btn-filters {
    background-color: transparent;
    margin-top: 30px;
  }

  .collapse-tags-filter {
    .ant-form-item {
      margin-left: 0px;
      margin-right: 0px;

      .ant-input-affix-wrapper input.ant-input {
        padding: 4px 8px;
      }

      .ant-input::placeholder {
        opacity: 0.5 !important;
        letter-spacing: 0.6px;
      }

      .ant-input-affix-wrapper i {
        color: @gray-color-2 !important;
      }
    }

    .checkbox-tags-loading {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 25px 0px 20px;
    }

    .checkbox-empty-state {
      #empty-message-component .ant-empty {
        margin: 0px !important;
        padding: 10px !important;
      }
    }

    .checkbox-tags-filter {
      .checkbox-tags-group {
        .hidden {
          display: none;
        }
      }

      .ant-checkbox-wrapper {
        .ant-checkbox + span {
          display: flex;
          align-items: center;

          .special-tag {
            flex-shrink: 0;
            padding: 2px 6px;
            color: rgba(@text-dark, 0.8);
            font-weight: 400;
          }
        }
      }

      .checkbox-tags-filter-btn {
        margin-top: 5px;

        #view-more-container .ant-btn {
          padding: 0px !important;
          background: transparent !important;

          span {
            font-size: 14px !important;
          }

          i {
            font-size: 13px !important;
          }
        }
      }
    }
  }
}
