@import '../../../../../styles/variables.less';

#empty-recurrence-component {
  display: flex;
  flex-wrap: nowrap;

  height: 100%;
  padding: 20px;

  .img-container {
    display: flex;
    justify-content: flex-end;

    height: 100%;
    padding-right: 6px !important;

    max-height: 250px;
  }

  .apply-profile-model-container {
    padding-top: 5px;
    padding-left: 25px;
    padding-right: 5px;

    height: 100%;

    width: 100%;
    max-width: 400px;

    strong {
      display: flex;
      max-width: 280px;

      font-size: 35px;
      line-height: 1.2 !important;
      color: #515265;
    }

    .apply-profile-model-description {
      display: block;
      margin: 30px auto;

      color: #515265;
      font-size: 15px;
      line-height: 1.4;
    }

    .add-profile-model {
      display: block;

      font-size: 14px !important;
      font-weight: 300;
      line-height: 1.4;

      a:hover {
        border-bottom: 1px solid @primary-color;
      }
    }

    .apply-btn {
      margin-top: 25px !important;

      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    .ant-form-vertical .ant-form-item-label > label {
      font-size: 16px;
      color: #515265;
      font-weight: 500;

      display: block;
      margin-bottom: 4px;
    }

    .ant-select-disabled {
      .ant-select-selector {
        border: none !important;
      }
    }

    .ant-select-selector {
      padding: 0px !important;
      color: #515265;
      border: none !important;

      .ant-select-single .ant-select-selector .ant-select-selection-search {
        left: 0px !important;
      }
    }

    .ant-select-selection-placeholder {
      font-size: 15px;
      color: #515265;
    }

    .ant-select-selection-item {
      font-size: 15px !important;
    }

    .ant-select-arrow {
      margin-top: -5px;
      color: @text-dark;
      font-size: 15px;
      font-weight: 500;

      .anticon-down {
        font-size: 12.5px !important;
      }
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    margin: 50px 0px !important;

    .ant-spin {
      margin-bottom: 0 !important;
    }
  }
}

#select-new-recurrence {
  .ant-select-item-option-content {
    font-size: 16px !important;
  }
}
