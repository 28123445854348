@import '../../../../../../../styles/variables.less';

.custom-document-loading {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-spin {
    margin: 40px 0px 50px !important;
  }
}

.compliance-rules-status {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: 0px;

  & + .compliance-rules-status {
    border-top: 1px solid @border-color;
  }

  .compliance-columns {
    display: flex;
    align-items: center;
  }

  .rule-title {
    font-size: 16.5px;
    word-wrap: break-word;
    color: @text-dark;
    max-width: 60ch;
  }

  .tag-background {
    background: #004af733;
    color: #00257a;
    font-weight: 400 !important;
    font-size: 13.5px;
    padding: 3px 10px 3px 5px;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
