@import '../../../../../../../styles/variables.less';
.spc-row-details {
  margin: 20px 0px;

  .spc-col-detail {
    display: flex;
    flex-direction: column;

    padding-left: 0px !important;
    padding-right: 0px !important;

    color: @text-dark;

    strong {
      font-size: 16px;
    }

    span {
      font-size: 14px;
    }
  }
}

#report-spc-last-phone-card {
  padding-left: 0;
}

.ghost-collapse {
  .ant-collapse-header {
    display: none;
  }
  .ant-collapse-content-box {
    padding: 0;
  }

  ul {
    margin-top: 10px;
    padding-left: 0px;

    li {
      list-style: none;
      line-height: 1.5;

      margin-bottom: 10px;
    }
  }
}
