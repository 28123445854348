@import '../../../../../../styles/variables.less';


#connect-execution-modal{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 25px;

    strong {
       font-weight: 700;
       font-size: 2rem;
       line-height: 40px;
       margin-bottom: 10px;
       color: @text-dark;
    }
    span{
       font-size: 16px;
       color: @gray-color-1;
    }
}
#connect-execution-modal-footer{
    display: flex;
    justify-content: flex-end;
}