@import '../../../../../styles/variables.less';

#executions-detran-info-container {
  display: flex;
  flex-direction: column;

  .detran-info-title {
    // margin-top: 40px;
    padding-bottom: 15px;
    width: 100%;
    flex: 1;

    .ant-col {
      padding-left: 0px;
      padding-right: 0px;

      // &:last-of-type {
      //   text-align: end;
      // }
    }

    strong {
      font-size: 16px;
      color: @text-dark;
    }
  }
}
