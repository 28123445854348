@import '../../../../../../styles/variables.less';

#generate-token-component {
  .div-checkbox {
    background-color: #f9f9f9;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
  }
  .titleDiv {
    color: @text-color;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .ant-checkbox-wrapper-disabled {
    opacity: 0.5;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .checkbox-all {
    font-weight: bold;
    padding-left: 20px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .checkbox-default {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px !important;
  }
}
