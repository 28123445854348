.executions-list-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 20px;
}

.executions-list-grid-1 {
  min-width: 0px;
}

.executions-list-grid-2 {
  min-width: 300px;
}
