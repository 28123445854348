.row-section-liveness {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;

  img {
    margin-top: -5px;
    margin-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
