@import '../../../../../../../styles/variables.less';

#to-print-document {
  #column-title-value-container {
    margin-bottom: 15px !important;

    .title {
      margin-bottom: 0.8em;

      font-size: 12px;
    }

    .value {
      font-size: 10.5px;
    }

    .custom-value {
      font-size: 10.5px;
      line-height: 1.5;
      white-space: normal;
      word-break: break-word;

      & + .custom-value {
        margin-top: 0.5em;
      }
    }

    .status-div {
      font-size: 12px !important;

      .div-dot {
        width: 6px;
        height: 6px;
        min-width: 6px;
      }
    }

    &.situation-col {
      padding-right: 0px !important;
    }
  }

  #column-label-container {
    .column-label-title {
      strong {
        font-size: 12px;
      }
    }

    .custom-label {
      .status-div {
        font-size: 12px !important;

        .div-dot {
          width: 6px;
          height: 6px;
          min-width: 6px;
        }
      }

      .column-label-description {
        &.custom-pdd {
          padding-left: 14px;
        }

        span {
          font-size: 10.5px;
        }
      }
    }
  }

  #relation-and-phone-card {
    padding: 10px !important;

    .circle-icon {
      width: 35px;
      height: 35px;

      i {
        font-size: 18px;
      }
    }

    .relation-and-phone-card-info {
      margin-left: 7.5px;

      strong {
        font-size: 13px;
      }

      span {
        font-size: 12px;
        line-height: 1.5;
        word-break: break-all;
      }
    }
  }

  #empty-state-card {
    background: transparent !important;

    .ant-card-body {
      padding: 8px;

      i {
        font-size: 30px;
      }

      strong {
        margin-top: 8px;
        font-size: 11px;
      }
    }
  }

  #basic-infos-card,
  #basic-infos-contacts {
    padding-top: 10px;

    .circle-icon {
      width: 40px;
      height: 40px;

      margin-left: 0px;
      margin-right: 5px;

      i {
        font-size: 20px;
      }
    }

    .ant-divider-horizontal {
      margin-top: 0px !important;
    }

    .basic-infos-content {
      .basic-infos-title {
        font-size: 15px;
      }
    }
  }

  .dossier-card-container {
    margin: 10px 0px;
    padding-bottom: 15px;

    border: none;
    border-bottom: 1px solid @border-color;
    border-radius: none;

    .ant-card-body {
      padding: 10px 0px;

      .circle-icon {
        width: 40px;
        height: 40px;

        margin-left: 0px;
        margin-right: 5px;

        i {
          font-size: 20px;
        }
      }

      .dossier-card-content {
        .dossier-card-title {
          font-size: 15px;
        }

        .dossier-card-subtitle {
          font-size: 13px;

          margin-bottom: 20px;
        }

        #column-label-container {
          .column-label-description {
            #empty-state-card {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }

  #independent-consultants-card .custom-divider {
    margin-top: 0px;
  }

  #relationships-component {
    .relationships-section-title {
      font-size: 13px;
    }

    #relation-and-phone-card {
      margin: 15px 0px !important;
    }
  }

  #media-exposure-card {
    .media-exposure-link {
      font-size: 13px;
    }

    span {
      margin-top: 2px;
      font-size: 11px;
    }
  }
}

@media print {
  @page {
    size: landscape;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background-color: white !important;
    -webkit-print-color-adjust: exact;

    #to-print-document {
      background-color: white !important;

      #to-print-document-row {
        display: block;

        .to-print-document-col {
          display: block;

          position: relative;
          page-break-inside: avoid !important;

          #to-print-identity {
            display: block;

            .dossier-card-container {
              display: block;

              position: relative;
              page-break-inside: avoid !important;
            }
          }

          #to-print-financial-information {
            display: block;

            #spc-items,
            #protests-items,
            #cadin-sp-items {
              display: block;

              position: relative;
              page-break-inside: avoid !important;
            }
          }

          #to-print-restrictive-lists {
            display: block;

            #owners-items {
              display: block;

              position: relative;
              page-break-inside: avoid !important;
            }

            #sources-list-container {
              display: block;

              .sources-list-col {
                display: block;

                position: relative;
                page-break-inside: avoid !important;
              }
            }
          }

          #to-print-labor-information {
            display: block;

            #cndt-debts-items,
            #personal-labor-and-financial-information-items {
              display: block;

              position: relative;
              page-break-inside: avoid !important;
            }
          }

          #to-print-trust {
            #execution-widget-collapse {
              .ant-collapse-content-box {
                .personal-data-info,
                .compliance-group-title {
                  display: block !important;
                  width: 100%;
                  page-break-inside: avoid !important;
                }
              }
            }
          }
        }
      }

      #empty-state-card,
      #empty-message-component {
        background: transparent !important;
      }

      .ant-row,
      .ant-col,
      .ant-card,
      .personal-data-info,
      .dossier-card-container {
        background-color: white !important;
      }

      #to-print-header,
      #to-print-trust,
      #execution-widget-collapse,
      #basic-infos-card,
      #basic-infos-contacts,
      #independent-consultants-card,
      #information-source-card,
      #media-exposure-card,
      #sources-list-container,
      #contact-data-container,
      #owners-list-container,
      #owners-card-container,
      #owners-items,
      #source-infos-to-print,
      #cndt-debts-items,
      #spc-items,
      #cadin-sp-items,
      #personal-labor-and-financial-information-items {
        background-color: white !important;
      }

      .contact-data-container {
        display: block;

        .contact-data-col {
          display: block;

          position: relative;
          page-break-inside: avoid !important;
        }
      }

      .custom-label,
      .card-partners-content,
      .media-exposure-container,
      .legal-information-processes-row {
        display: block !important;
        width: 100%;
        page-break-inside: avoid !important;
      }

      #empty-state-card,
      #page-title-component,
      #column-label-container,
      #empty-message-component {
        display: block !important;
        width: 100%;
        page-break-inside: avoid !important;
      }

      .spc-items-data,
      .cndt-debts-data,
      .cadin-sp-items-data,
      .protests-items-data,
      .finance-infos-group,
      .custom-validations-data,
      .owners-list-row,
      .sources-list-row,
      .contact-data-row,
      .personal-labor-and-financial-information-data {
        display: inline-flex !important;
        width: 100%;
        page-break-inside: avoid !important;

        margin-top: auto;
        margin-bottom: auto;
      }

      #validations-images-card,
      #empty-documents-component,
      #media-exposure-card,
      #column-title-value-container {
        display: inline-flex !important;
        width: 100%;
        page-break-inside: avoid !important;

        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

@page {
  size: auto;
  margin: 15mm 10mm;
}
