#to-print-identity {
  .dossier-card-container {
    .dossier-card-content {
      .document-validation-status {
        padding: 6px;

        i {
          font-size: 14px;
        }

        span {
          font-size: 11.5px;
        }
      }

      #validations-images-card {
        margin-top: 10px !important;
        margin-bottom: 15px !important;

        height: 200px;

        .validation-content-image-container {
          height: 200px;

          span {
            font-size: 10px;
          }

          img {
            height: 200px;
            max-height: 200px;
          }
        }
      }

      .custom-validations-title {
        margin: 15px 0px;

        strong {
          font-size: 13px;
        }
      }

      #card-table-info-column {
        .card-table-info-column-data {
          strong {
            font-size: 13px;
          }

          span {
            font-size: 11px;
          }
        }
      }

      #empty-documents-component {
        align-items: center;

        .empty-documents-img {
          img {
            max-height: 120px;
          }
        }

        .custom-img {
          img {
            max-height: 145px;
          }
        }

        .send-images-container {
          padding-left: 15px;

          .send-images-description {
            font-size: 15px;
          }

          #custom-upload-btn-container {
            display: none;
          }
        }
      }
    }
  }
}
