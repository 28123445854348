@import '../../../../styles/variables.less';

#blocked-documents-list-container {
  tr.ant-table-row{
    padding-top: 0;
    padding-bottom: 0;
    height: 55px;
  }

  .export-list-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background: transparent;

    transition: color 0.2s ease-in-out;

    i {
      font-size: 28px;
      margin-left: 1.25px;

      color: @gray-color-1;
    }

    &:hover,
    &.ant-dropdown-open {
      i {
        color: @primary-color;
      }
    }

    &.danger {
      i {
        color: @danger-color;
      }
    }

    .ant-btn-loading-icon {
      color: @primary-color;
    }
  }
}
