@import '../../../../../../../../styles/variables.less';

.spc-row-details {
  margin: 20px 0px;

  .spc-col-detail {
    display: flex;
    flex-direction: column;

    padding-left: 0px !important;
    padding-right: 0px !important;

    color: @text-dark;

    strong {
      font-size: 16px;
    }

    span {
      font-size: 14px;
    }
  }
}
