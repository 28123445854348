@import '../../../../../../../../styles/variables.less';

#legal-information-processes-container {
  display: flex;
  flex-direction: column;

  .legal-information-processes-title {
    // padding-bottom: 15px;
    padding-bottom: 5px;
    margin-bottom: 15px;

    border-bottom: 1px solid @border-color;

    .ant-col {
      padding-left: 0px;
      padding-right: 0px;
    }

    strong {
      font-size: 15px;
      color: @text-dark;
    }
  }

  .legal-information-processes-row {
    .legal-information-processes-data {
      &:first-of-type {
        .legal-information-processes-btn {
          padding-top: 10px;
        }
      }

      &:last-of-type {
        margin-bottom: 20px;

        .legal-information-processes-btn {
          border-bottom: none;
        }
      }
    }
  }

  .legal-information-processes-btn {
    display: flex;
    flex-direction: column;

    height: auto;
    width: 100%;

    margin: 0px;
    padding: 20px 0px;

    border-bottom: 1px solid @border-color;
    border-radius: 0;

    &:hover {
      opacity: 0.7;
    }

    .ant-row {
      width: 100%;
    }

    .processes-row-data {
      display: flex;
      // flex-direction: column;

      color: @text-dark;

      .process-data-title {
        strong {
          font-size: 15.5px;
          font-weight: 500;
          color: @text-dark;
        }
      }

      .process-col-reset {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .processes-row-details {
        width: 100%;
        margin-top: 15px;

        color: @text-dark;

        .ant-col {
          padding: 0px;
          // padding-right: 15px;
        }

        .bd-left {
          // padding-left: 15px;
          border-left: 1px solid @border-color;
        }

        .process-data-detail {
          display: flex;
          flex-direction: column;

          &:nth-child(1) {
            padding-right: 15px;
          }

          &:nth-child(2) {
            padding-left: 15px;
            padding-right: 15px;
          }

          &:nth-child(3) {
            padding-left: 15px;
          }

          strong {
            font-size: 15.5px;
          }

          span {
            display: flex;
            margin-top: 5px;

            font-size: 14.5px;
          }
        }
      }

      .processes-row-tags {
        display: flex;
        align-items: center;

        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
