@import '../../../../../../../../../styles/variables.less';

.KYB-financial-info-collapse {
  margin-bottom: 24px;
  .header-panel {
    .right-info {
      padding-right: 48px;
      text-align: right;
    }
  }
  .ant-collapse-content-box {
    padding: 16px;
  }
}
