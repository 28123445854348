#deny-list-content {
  .single-line {
    padding-right: 0px;
    flex-direction: row !important;
    .title {
      margin-right: 10px;
    }
  }
  .float-right {
    display: block !important;
    float: right;
    margin-right: 20px;
  }
}
