@import '../../styles/variables.less';

.ant-form-item-label {
  > label {
    display: flex;
    width: 100%;
    font-size: 13px;
    color: @text-color;
  }

  > label::after {
    display: none !important;
  }

  .custom-label-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    a:hover {
      border-bottom: 1px solid @primary-color;
    }
  }
}

.ant-form-item {
  display: flex;
  flex-direction: column;
}
