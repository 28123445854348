.card-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  text-align: center;
  padding: 4px 8px;
  background-color: #dafee5;
  border-radius: 1.125rem;
  font-weight: 500;
  margin-right: 12px;

  p {
    color: #023114;
    margin: 0;
  }
  // gap: $xs,
  // borderRadius: $lg,
  // fontWeight: $medium,
}
