@import '../../../../../../styles/variables.less';

#column-label-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 30px;

  &.text-end {
    text-align: end !important;
  }

  .column-label-btn {
    margin: 0px !important;
    padding: 0px !important;

    height: 100%;

    &.active {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .custom-label {
    .status-div {
      font-size: 15.5px !important;
    }
  }

  .column-label-title {
    display: inline-block;
    align-items: center;

    width: 100%;

    margin-bottom: 15px;
    padding-bottom: 5px;

    border-color: @border-color !important;

    strong {
      font-size: 15px;
      color: @text-dark;
    }
  }

  .column-label-description {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    &.custom-pdd {
      padding-left: 16px;
    }

    span {
      // font-size: 14.5px;

      &:first-of-type {
        margin-top: 10px;
      }

      &:not(:first-of-type) {
        margin-top: 2px;
      }
    }

    .empty-state {
      line-height: 1.4 !important;
    }
  }
}
