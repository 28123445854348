:root {
  --plyr-color-main: #20c26b;
}

@file-header-height: 60px;

#file-viewer-component {
  display: flex;
  align-items: center;
  justify-content: center;

  .file-viewer-header {
    background: rgba(38, 38, 38, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    height: @file-header-height;
    width: 100%;
    color: #fff;
    padding: 0 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-btn {
      color: #fff;
      padding: 0;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;

      i {
        display: flex;
        color: #fff;
        font-size: 20px;
      }

      &:hover {
        background-color: rgba(#fff, 0.1);
      }
    }

    .back-btn {
      i {
        transform: scaleX(-1);
        font-size: 22px;
      }
    }
  }

  .content-wrapper {
    display: flex;

    .content {
      display: flex;
    }
  }

  .content-pdf {
    width: 100vw;
    max-height: 100vh; // (@file-header-height * 2)

    .frame {
      width: 100vw;
      height: 100vh;
      min-height: 100vh;
      padding-top: @file-header-height;

      iframe {
        height: 100vh;
        width: 100%;
        width: 100vw;
        border: none;
      }
    }
  }

  .plyr {
    min-width: 600px;
  }
}

.file-viewer-modal {
  width: 100%;
  height: 100%;
  place-items: center;

  .ant-modal-content {
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal {
    padding: 0;
    max-width: 100%;
  }

  .viewer-container {
    margin: auto;

    .pg-viewer-wrapper {
      overflow-y: auto;

      #pg-photo-container {
        width: auto !important;
        height: auto !important;

        padding-right: 10px;

        img {
          width: auto !important;
          height: auto !important;
        }
      }

      #pg-viewer {
        display: flex;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
      }

      .loading-container,
      .loading,
      .pdf-loading {
        text-align: center;
      }
    }

    div {
      width: 100%;
      min-width: 600px;
      max-width: 800px;
      height: auto;
    }

    canvas {
      width: 100%;
      margin-bottom: 10px;
    }

    .unsupported {
      display: block;
      margin-bottom: 10px !important;
      font-size: 16px;
    }
  }
}

.file-viewer-tooltip {
  .ant-tooltip-inner {
    font-size: 11px;
    min-height: auto;
  }
}
