@import '../../../../../../../../../styles/variables.less';

#box {
  font-size: 8px; // rem base
  padding: 24px;
  border-radius: 0px 0px 0.5rem 0.5rem;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-bottom: 30px;
  .image-title {
    color: @text-dark-secondary;
    font-size: 1rem;
    display: block;
    margin-bottom: 5px;
  }
  header {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    h1 {
      text-align: left;
      font-weight: 700;
      font-size: 1.5rem;
      margin-right: 1rem;
    }
  }

  .dossier-card-title {
    margin: 2px 0px 5px;
    color: @text-dark;
  }

  .dossier-card-subtitle {
    font-size: 15px;
    color: @text-color;

    margin-bottom: 30px;
  }
}
