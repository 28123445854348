@import '../../styles/variables.less';

.header-skeleton {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  color: white;
}
.header-row {
  background-color: @primary-color;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px 0px 25px;
}
.header-title {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: white;
}
.button-header {
  width: 5%;
  border-radius: 9px;
  background-color: @primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(@primary-color, 0.8);
    &::before,
    &::after {
      transition: opacity, padding 0.3s ease-in-out;
      display: none;
    }
  }
  cursor: pointer;
  position: relative;
}
