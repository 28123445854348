#to-print-restrictive-lists {
  #owners-card-container {
    margin-bottom: 18px !important;

    .ant-card-body {
      height: 70px;
    }

    .owners-card-infos {
      padding: 15px;

      span {
        font-size: 14px;
      }

      .owners-card-labels {
        .status-div {
          font-size: 12.5px !important;

          .div-dot {
            width: 6.5px;
            height: 6.5px;
            min-width: 6.5px;
          }
        }
      }
    }

    .owners-card-arrow {
      display: none !important;
    }
  }

  #owners-items {
    margin: 25px 0px;
    padding: 0px 25px;

    #page-title-component {
      margin-bottom: 0px !important;

      .title {
        font-size: 14px;
      }

      .subtitle {
        font-size: 12px;
      }
    }
  }

  #information-source-card {
    margin-bottom: 20px !important;

    .information-source-icons {
      .circle-icon {
        width: 36.5px;
        height: 36.5px;
        margin-right: 5px !important;
      }

      .flag-icon {
        top: 25px;
        right: 6px;
        width: 15px;
        height: 15px;
      }
    }

    .information-source-content {
      .information-source-title {
        font-size: 13px;
      }

      .information-source-subtitle {
        font-size: 11.5px;
      }

      #information-source-details-btn {
        margin: 10px 0px 5px;

        span {
          font-size: 11.5px;
        }
      }

      .information-source-updated {
        font-size: 11.5px;
      }
    }
  }

  #source-infos-to-print {
    .information-source-data-details {
      display: flex;
      flex-direction: column;

      width: 100%;

      span {
        display: flex !important;
        flex-wrap: wrap;
        font-size: 11.5px !important;
      }
    }

    .information-source-data-image {
      margin-top: 15px;

      img {
        max-height: 80px;
      }
    }
  }

  #title-divider-container {
    span {
      font-size: 11.5px;
    }
  }
}
