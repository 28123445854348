@import '../../../../styles/variables.less';

// ANTD menu list override

.ant-menu-submenu-arrow {
  display: none !important;
}

.ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title {
  padding: 0px !important;
}

.ant-layout-sider .ant-menu-vertical .ant-menu-item {
  padding: 1.6rem 0px !important;

  @media screen and (max-height: 767px) {
    padding: 1.3rem 0px !important;
  }
}

.ant-menu-vertical .ant-menu-submenu {
  padding: 0.5rem 0px !important;

  @media screen and (max-height: 767px) {
    padding: 0.2rem 0px !important;
  }
}

.ant-menu-vertical .ant-menu-submenu {
  padding: 0.5rem 0px !important;
}

.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item {
  padding: 1.6rem 0px !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0.3rem !important;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 0px !important;
  // height: 50px;
}

.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 20% !important;
  padding-right: 0px !important;
  height: 35px;
}

.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
  padding: 0px 1rem !important;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content {
  padding: 0.5rem 0px !important;
}

.ant-menu-item,
.ant-menu-submenu {
  &:hover {
    background-color: rgba(@primary-color, 0.1);
  }
}

// styles aplied to submenu container
.ant-menu-submenu-popup,
.ant-menu-sub {
  border-radius: 10px !important;
}

.ant-menu-sub {
  padding: 0.3rem 0px !important;
}

.ant-menu-vertical.ant-menu-sub {
  min-width: 200px !important;
}
