img.ant-image-img {
  border-radius: 10px;
  object-fit: cover;
}

video.card-image {
  border-radius: 10px;
  object-fit: cover;
}

.face-authenticator-card {
  .ant-card-body {
    display: flex;
    height: 260px;
    .card-info {
      margin: 30px;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-info-status {
        display: flex;
        flex-direction: row;
        align-items: center;
        .card-info-status-content {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
        }
      }
      .card-info-simple {
        display: flex;
        flex-direction: row;
        padding-top: 15px;

        .ant-col-teste {
          justify-content: space-between;
        }
      }
    }
  }
}
