@import '../../../../../../styles/variables.less';

.imgContainer {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 25px;
}

.fraudContainer {
  padding-left: 10px;
  overflow: hidden;

  display: flex;
  align-items: center;
  div {
    h1 {
      font-weight: 800;
      font-size: 45px;
      color: @primary-color;
      margin-bottom: 5px;
    }
    span {
      font-weight: 800;
      font-size: 12px;
      color: @text-dark;
    }
    h2 {
      font-weight: 800;
      font-size: 24px;
      color: @primary-color;
      margin-top: 8px;
    }
  }
}
.fraudContainer,
.imgContainer {
  margin-bottom: 35px;
}

@media screen and (max-width: 1300px) {
  .fraudContainer {
    h2 {
      font-size: 18px !important;
    }
  }
}
