@import '../../../../../../../../../styles/variables.less';

.company-record-details-breadcrumb {
  button {
    color: @gray-color-1;
    cursor: pointer;
    border: none;
    background: none;

    &:hover {
      color: @text-dark;
    }
  }
}

.company-record-entities-mentioned {
  scroll-margin-top: 100px;
}
