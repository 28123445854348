@import '../../../../../../../styles/variables.less';

#executions-cadin-sp-items {
  #page-title-component {
    .subtitle {
      display: flex;
      margin: 5px 0px 30px;

      font-size: 16px;
    }
  }

  .cadin-sp-items-title {
    strong {
      color: @text-dark;
      font-size: 16px;
    }
  }

  .cadin-sp-items-data {
    display: flex;

    margin-top: 5px;

    .ant-col {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .column-label-description {
      margin-top: 4px !important;
      font-size: 14 !important;
    }
  }
}
