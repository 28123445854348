@import '../../../../../styles/variables.less';

.manual-query-modal {
  .tooltip-message-customize-permissions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;

    button {
      cursor: pointer;
      background: transparent;
      border: none;
    }
  }

  .subtitle {
    display: block;
    font-size: 15px;
    margin-top: 2px;
  }

  .ant-form {
    width: 100%;
  }

  .ant-spin {
    margin: 25px 0px !important;
  }

  .alert-info-form {
    .ant-alert-icon {
      top: 26px !important;
      font-size: 18px;
    }

    .ant-alert-message {
      display: block;
      color: @text-dark;
      margin-left: 12px;
    }
  }

  .manual-query-content {
    margin-top: 30px !important;

    .manual-query-collapse-container {
      .ant-form-item {
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;

        .ant-form-item-control-input {
          min-height: initial !important;
        }
      }

      .ant-collapse-item-disabled {
        background-color: @gray-color-4;
      }

      .ant-collapse {
        border-radius: 8px !important;
      }

      .manual-query-panel {
        background: #fff;

        .ant-collapse-header {
          display: flex;
          align-items: center;

          padding: 20px;

          .anticon {
            display: none !important;
          }
        }

        .ant-collapse-content-box {
          padding: 15px 25px !important;
          background: #f3f3f3;
        }
      }

      .manual-query-panel-header {
        display: flex;
        width: 100%;

        .ant-checkbox-wrapper {
          display: flex;
          flex-direction: row-reverse;

          .ant-checkbox + span {
            padding-right: 12px;
            padding-left: 0px;

            color: @accent-color;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
    }

    div.ant-collapse:nth-child(1) {
      overflow: hidden;
    }

    .manual-query-checkbox-group {
      width: 100%;

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background: #979797 !important;
        }
      }

      .ant-checkbox + span {
        font-size: 15px;
        padding-left: 10px;
        padding-right: 0px;
      }

      .ant-checkbox-disabled + span {
        color: @text-dark;
      }

      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-top: 12px;
      }

      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0px;
      }
    }
  }

  .manual-query-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 30px;
    padding: 0px 15px;

    .manual-query-btns {
      .ant-btn {
        padding: 0px 30px !important;
      }
    }
  }

  .manual-query-footer-with-flag {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-top: 30px;
    padding: 0px 15px;

    .manual-query-btns {
      .ant-btn {
        padding: 0px 30px !important;
      }
    }
  }
}
