@import '../../../../styles/variables.less';

#menu-notifications {
  width: 420px;

  .title-notifications {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px 15px;

    .ant-typography {
      font-size: 18px !important;
      margin-bottom: 0px !important;
    }

    .ant-spin {
      margin: 0px !important;
    }
    .form-filter {
      display: flex;
      padding: 0;
      justify-self: center;
      align-self: center;
      align-items: center;
      margin: 0;

      .ant-row {
        margin-bottom: 0;
      }
    }
  }
  .ant-menu-item {
    padding: 20px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-item {
      &:hover {
        color: @info-color;
      }
      display: flex;
      font-weight: bold;
      color: @text-dark;
    }
  }

  .scroll-component-list-container {
    max-height: 500px;
    overflow-y: scroll;

    margin-top: 0px !important;
  }

  .ant-spin {
    margin: 12px 0px !important;
  }

  .group-notifications {
    .ant-menu-item-group-title {
      display: flex;
      align-items: center;

      padding: 0px 15px !important;

      span {
        display: block;
        flex-shrink: 0;

        color: @gray-color-1;
        font-size: 11.5px;
        text-transform: uppercase;
      }

      > div {
        width: 100%;
        height: 0.25px;
        margin-left: 10px;
        background: @gray-color-4;
      }
    }
  }

  .empty-notifications {
    #empty-message-component .ant-empty {
      padding: 0px 15px !important;
    }
  }
}

.notification-btn {
  cursor: pointer;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    font-size: 24px;
  }
}

.ant-badge {
  margin: 0px !important;
}

#menu-item-notifications {
  height: 100%;

  margin: 5px 0px;
  padding: 10px 15px 0px;

  transition: background 0.2s linear;

  &.unread {
    background: #f2f2f250;
  }

  .notification-item {
    display: flex;
    flex-direction: column;

    padding-bottom: 10px;
    border-bottom: 1px solid @border-color;

    span,
    strong {
      white-space: normal;
      line-height: 1.4;
    }

    .notification-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: @text-dark;
        font-weight: 500;
        font-size: 14.5px;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        .loading-icon {
          font-size: 16px;
        }

        .caf-ic_eye {
          color: @gray-color-1;
        }

        .caf-ic_check_d {
          color: @primary-color;
        }

        &:hover {
          .caf-ic_eye {
            color: @text-dark;
          }

          .caf-ic_check_d {
            opacity: 0.7;
          }
        }
      }
    }

    .notification-description {
      display: block;
      margin: 5px 0px;
    }

    .notification-date {
      color: @gray-color-1;
      font-size: 12px;
      text-transform: lowercase;
    }

    .notification-download-file {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 8px;
      margin: 5px 0px 10px;

      color: @success-color;

      border-radius: 8px;
      background: @inverse-success-color;

      i {
        font-size: 21px;

        margin-right: 10px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .notification-expired-file {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 8px;
      margin: 5px 0px 10px;

      color: @danger-color;

      border-radius: 8px;
      background: @inverse-danger-color;

      i {
        margin-right: 6px;
        font-size: 18.5px;
      }
    }
  }
}

.notification-dropdown {
  width: 100%;
  height: 100%;
}