@import '../../../../../../styles/variables.less';

#independent-consultants-card {
  #column-title-value-container {
    .title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .value {
      color: @text-color;
      font-size: 14.5px;
    }
  }

  .custom-divider {
    margin: 20px 0 20px 2%;
    width: 98%;
    min-width: 98%;
  }
}
