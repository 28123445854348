@import '../../../../../../../../../styles/variables.less';
#key-information {
  font-size: 14px;
  .row-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    color: @text-dark;
  }
}
