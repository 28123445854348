@import '../../../../../styles/variables.less';

#validation-rules-step-card-container {
  .cols {
    section {
      &:nth-child(2) {
        width: 280px;
        margin-left: auto;
      }
    }
  }

  .validation-rules-list {
    padding: 16px;
    border: 1px solid @border-color;
    border-radius: 8px;

    > div {
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid @border-color;
      padding: 16px 0px;

      &:last-child {
        border-bottom: none;
      }

      span {
        font-weight: bold;
        font-size: 16px;
        color: @text-dark;
      }

      .status-action {
        width: 190px;
        margin-left: auto;

        section {
          font-weight: 500;
          font-size: 15px;
          color: @text-dark;
        }
      }
    }
  }
}
