@import '../../../../../../styles/variables.less';

.kyb-company-detailsv2-header-history {
  margin-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    color: @gray-color-1;
  }
}
.divisor-right {
  border-right: 1px solid #d1d1d1;
}
.divisor-left {
  border-left: 1px solid #d1d1d1;
}
.kyb-company-detailsV2-header-space {
  display: inline-block;
  margin: 0 4px;
}
.kyb-company-details-header-box {
  display: flex;
  margin: 0 0 40px;
  align-items: center;
  column-gap: 18px;

  div {
    display: flex;
    align-items: center;
    column-gap: 3px;
  }
}

.kyb-company-details-header-title {
  color: @header-text-color;
  font-weight: 700;
  font-size: 24px;
  margin: 0 0 5px;

  width: fit-content;
  word-break: break-word;
}

.kyb-company-details-header-icon {
  color: @gray-color-1;
}

.kyb-company-detailsv2-header-container {
  display: flex;

  .kyb-company-details-header-item {
    width: 100%;

    button {
      color: @black-color !important;
      background-color: white !important;
      border-width: 2px;
      border-color: @primary-color !important;

      svg {
        font-size: 20px;
      }

      span {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  .kyb-company-details-header-item:nth-child(2) {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  div:nth-child(2) {
    text-align: end;
  }
}
