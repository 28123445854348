@import '../../../../../../styles/variables.less';

.KYB-tab-title {
  display: flex;
  margin-top: 10px;
  align-items: center;

  .icon {
    width: 44px;
    height: 100%;
  }

  .wrapper-title-and-subtitle {
    display: flex;
    flex-direction: column;
    margin: 0px 15px;

    .subtitle {
      font-size: 12px;
      color: @text-dark;
      white-space: pre-wrap;
    }
  }
}
