@media print {
  @page {
    margin-top: 2mm;
    margin-bottom: 2mm;
  }
}

.invoice-pdf {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h1 {
      font-size: 28px;
      font-weight: 400;
      line-height: 31.2px;
      text-align: left;
    }
  }

  &__metadata {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;
      color: #323232;
    }

    &__company-details {
      width: 45%;

      h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 20.8px;
        text-align: left;
      }
    }

    &__details {
      margin-top: 20px;
      border: 1px solid #d1d1d1;
      border-radius: 5px;

      display: grid;
      grid-template-columns: 1fr;

      &__section {
        border-bottom: 1px solid #d1d1d1;

        padding: 10px;
        display: flex;
        justify-content: space-between;

        &_footer {
          background-color: #d1d1d1;

          p {
            font-weight: 700 !important;
          }
        }

        p {
          width: 50%;
        }
      }
    }
  }
}
