@import '../../../../../styles/variables.less';

.dossier-print-modal {
  #page-title-component {
    margin-bottom: 25px !important;

    .subtitle {
      display: block;
      margin-top: 5px;

      font-size: 16px;
    }
  }

  .tag-legacy {
    color: @warning-color;
    font-size: 12px;
    margin-left: 10px;
  }

  .dossier-print-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 45px;

    .dossier-print-show-details {
      span {
        display: inline-block;
        margin-left: 10px;

        color: @text-dark;
        font-size: 16px;
        font-weight: 500;
      }
      .tag-show-details {
        color: @info-color;
        font-size: 12px;
      }
    }

    .dossier-print-btns {
      .ant-btn-primary {
        padding-left: 35px;
        padding-right: 35px;
      }
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    padding: 8px 0;

    color: @text-dark;
    font-size: 17px;
    font-weight: 500;

    i {
      margin: 0px 5px;

      font-size: 18px;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox {
    top: -0.04em;
  }

  .ant-checkbox-inner {
    display: block;

    width: 20px;
    height: 20px;

    border-radius: 6px;
    background-color: transparent;

    &::after {
      font-family: 'caf-icons';
      content: '\e813';
      top: 1px;
      left: 2px;
      font-size: 15px;
      border: none;
      color: @gray-color-4;
      transform: scale(1);
    }
  }

  .ant-checkbox-group {
    margin-top: 10px;
    width: 100% !important;
  }
}
