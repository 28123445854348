#personal-finance-container {
  // #column-label-container {
  // .column-label-title {
  //   margin-bottom: 0px !important;
  // }

  // .column-label-description span:first-of-type {
  //   margin-top: 5px !important;
  // }
  // }

  .end {
    text-align: end;
  }
}
