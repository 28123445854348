#to-print-address {
  #basic-infos-card {
    .basic-infos-content {
      .address-formatted {
        font-size: 12px;
        margin-bottom: 10px;
      }

      iframe {
        display: none;
        // height: 275px;
      }
    }
  }
}
