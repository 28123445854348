#to-print-financial-information {
  .dossier-card-container {
    .dossier-card-content {
      #spc-row-infos {
        margin: 15px 0px;

        .spc-col-info {
          strong {
            font-size: 12px;
          }
        }
      }

      #spc-progress-container {
        .ant-progress-line .ant-progress-outer .ant-progress-bg {
          height: 9px !important;
        }

        .spc-progress-marker {
          top: -4.5px;

          width: 16.5px;
          height: 16.5px;
        }
      }
    }
  }

  #spc-items {
    margin-top: 10px;
    padding: 15px 30px;

    #page-title-component {
      margin-bottom: 20px !important;

      .title {
        font-size: 13.5px;
      }

      .subtitle {
        display: flex;
        margin-bottom: 0px;

        font-size: 10px;
      }
    }

    .spc-items-title {
      strong {
        font-size: 12px;
      }
    }

    .spc-items-data {
      margin-top: 5px;

      #column-label-container {
        margin-bottom: 15px !important;

        .custom-label {
          .column-label-description {
            span {
              font-size: 10.5px !important;
            }
          }
        }
      }

      .bold {
        font-size: 12px;
      }
    }

    .ant-divider-horizontal {
      margin: 15px 0px;
    }
  }

  #cadin-sp-items {
    margin-top: 10px;
    padding: 15px 30px;

    #page-title-component {
      margin-bottom: 20px !important;

      .title {
        font-size: 13.5px;
      }

      .subtitle {
        display: flex;
        margin-bottom: 0px;

        font-size: 10px;
      }
    }

    .cadin-sp-items-title {
      strong {
        font-size: 12px;
      }
    }

    .ant-divider-horizontal {
      margin: 15px 0px;
    }
  }

  #protests-items {
    margin-top: 10px;
    padding: 15px 30px;

    #page-title-component {
      margin-bottom: 20px !important;

      .title {
        font-size: 13.5px;
      }

      .subtitle {
        display: flex;
        margin-bottom: 0px;

        font-size: 10px;
      }
    }

    .protests-items-title {
      strong {
        font-size: 12px;
      }
    }

    .protests-items-data {
      #column-label-container {
        margin-bottom: 15px !important;

        .custom-label {
          .column-label-description {
            font-size: 11.5px !important;
          }
        }
      }

      .protest-item-col {
        font-size: 11.5px;

        &.end {
          .bold {
            font-size: 12px !important;
          }
        }
      }
    }

    .ant-divider-horizontal {
      margin: 15px 0px;
    }
  }

  #personal-labor-and-financial-information-items {
    padding: 15px 30px;

    #page-title-component {
      margin-bottom: 15px !important;

      .title {
        font-size: 13.5px !important;
      }
    }

    .personal-labor-and-financial-information-data {
      padding: 15px 0px !important;

      > .ant-col {
        strong {
          font-size: 11.5px;
        }

        span {
          margin-top: 5px !important;
          font-size: 10.5px;
        }
      }

      .personal-labor-and-financial-information-type-infos {
        > span {
          margin-top: 0px !important;
          margin-bottom: 5px !important;
        }

        .status-div {
          font-size: 11.5px !important;

          .div-dot {
            width: 5.5px;
            height: 5.5px;
            min-width: 5.5px;
          }
        }
      }
    }
  }
}
