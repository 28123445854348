@import '../../../../../../styles/variables.less';

.container-session-configuration {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);

  button {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.configuration-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-height: 45px;
}

.row-section {
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  align-items: center;

  svg {
    color: @primary-color;
    font-size: 1.3rem;
    margin-left: 1rem;
    margin-right: 0.7rem;
  }

  .tooltip-icon {
    color: @gray-color-2;
  }
  .tooltip-message {
    color: @text-dark;
    margin: 0;
  }

  .title {
    margin-bottom: 0px;
    font-weight: 400;
  }
}

.control-buttons {
  button[role='switch'] {
    margin-left: 1rem;
  }
  .ant-switch {
    background: #787878;
  }
}
