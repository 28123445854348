@import '../../../../../styles/variables.less';

#executions-all-processes-modal {
  .legal-information-processes-title {
    margin-top: 20px !important;
  }

  .page-title {
    margin-bottom: 30px !important;
  }
}
