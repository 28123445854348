@import '../../../../../../styles/variables.less';

.blocked-document-switch{
  &.ant-switch-checked{
    background-color: @danger-color !important;

    .ant-switch-loading-icon{
        color: @danger-color !important;
    }
  }
}
