@import '../../../styles/variables.less';

.export-download-dropdown {
  border: 1px solid @border-color-3;
}

.export-download-menu {
  .ant-menu-item-selected {
    background-color: transparent !important;
  }

  .ant-menu-item:hover {
    font-weight: bold;
    background-color: @menu-item-active-bg !important;
  }
}

.export-download-button {
  border-color: @primary-color;
  color: @primary-color;
  padding-left: 10px !important;
  padding-right: 10px !important;

  &.ant-dropdown-open {
    background-color: @primary-color;
    color: @white-color;
  }
}
