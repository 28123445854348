@import '../../../../styles/variables.less';
[data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 60px;
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
}

.ant-steps-item-icon {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  display: none;
}
.ant-steps-item-title {
  font-weight: bold;
}
.ant-steps-item-finish {
  .ant-steps-item-title{
    color: @primary-color !important;
  }
}
.ant-steps-item-finish::before{
  width: 100% !important;
  left: 0 !important;
}
.ant-steps-navigation .ant-steps-item::before {
  height: 4px;
}
.ant-steps-item-process {
  .ant-steps-item-title{
    color: @primary-color !important;
  }
}
.steps-content {
  min-height: 200px;
  margin-top: 16px;
  text-align: center;
  border-radius: 2px;
}
