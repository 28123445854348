.kyb-ubo-structure-container {
  .react-flow__controls {
    top: 10px;
    right: 10px;
    left: auto;
    position: absolute;
    z-index: 5;
  }
}

.kyb-company-ubo-structure-report-main {
  .KYB-tab-title {
    margin-bottom: 32px;
  }

  .kyb-ubo-structure-report-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
  }
}

.kyb-group-structure-node {
  width: 388px;
  height: 245px;
  padding: 10px;
  border-radius: 8px;
  background-color: #d6ebff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  .react-flow__handle {
    width: 15px !important;
    height: 15px !important;
    background-color: gray !important;
  }
  .ubo-tag {
    position: absolute;
    background-color: #dafee5;
    color: #023114;
    font-weight: 700;
    padding: 2px 8px;
    border-radius: 12px;
    bottom: -10px;
    pointer-events: none;
  }
}

.kyb-group-structure-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
}

.kyb-group-structure-flag-container {
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 4px;
  align-items: flex-start;
  margin-left: 10px;

  img {
    width: 30px;
    height: 30px;
  }

  .kyb-group-structure-has-shareholder {
    font-size: 10px;
    color: #555;
    margin-top: 4px; /* Small space between flag and text */
    text-align: center;
  }
}

.kyb-group-structure-company-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.kyb-group-structure-company-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.kyb-group-structure-info-section {
  width: 357px;
  height: 141px;
  background-color: #fff;
  border-radius: 8px;
  margin-left: 6px;
  padding: 16px;
  font-size: 12px;
  color: #555;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
}

.kyb-group-structure-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
}

.kyb-group-structure-field-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  text-align: left;
}

.kyb-group-structure-label {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 4px;
  color: #555;
}

.kyb-group-structure-value {
  font-size: 12px;
  color: #333;
}

.kyb-group-structure-status-badge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: bold;
}

.kyb-group-structure-status-badge-active {
  background-color: #d4edda;
  color: #155724;
}

.kyb-group-structure-status-badge-nonactive {
  background-color: #f8d7da;
  color: #721c24;
}

.kyb-groupstructure-container {
  width: 100%;
  height: 100%;
  background-color: #edf7ff;
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .react-flow__controls-button {
    width: 30px;
    height: 30px;
  }
}

.kyb-groupstructure-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.kyb-groupstructure-layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
