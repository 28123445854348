@import '../../../../../styles/variables.less';

#profile-model-confirmation-component {
  .ant-empty {
    padding-top: 0;
    padding-bottom: 0;
  }
  .title {
    color: @text-dark-secondary;
    margin-bottom: 0;
    font-size: 21px;
  }
}
