#empty-archives-component {
  display: flex;
  flex-wrap: nowrap;
  padding: 20px;

  .empty-archives-img {
    display: flex;
    justify-content: flex-end;

    height: 100%;

    &.custom-img {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }

  .send-archives-container {
    padding-top: 5px;
    padding-left: 30px;

    height: 100%;

    width: 100%;
    max-width: 425px;

    .send-archives-description {
      display: flex;
      max-width: 300px;

      font-size: 35px;
      line-height: 1.15 !important;
      color: #515265;
    }

    .send-archives-btn {
      width: 100%;
      max-width: 250px;
      margin-top: 25px;
    }
  }
}
