@import '../../../../../../styles/variables.less';

tr.ant-table-row {
  height: 81px;
}

.ant-table-thead .ant-table-cell {
  color: @text-dark;
  font-weight: bold;
}

.ant-table-cell:last-of-type {
  align-self: flex-end;
}

.dropdown-generate-link-container {
  margin-left: 75%;
}

.dropdown-generate-link {
  .ant-divider-horizontal {
    margin: 5px 0 !important;
    border-top-color: @gray-color-3;
  }
}

.profile-template-name {
  color: @text-dark;
  max-width: 293px;
  min-width: 293px;
  font-weight: bold;
}

.popover-tag-container .ant-popover-inner-content {
  padding: 12px 16px !important;
}

.profile-service-icon-container {
  display: inline-block !important;
}

.profile-services-icon-popover {
  background: @primary-color;
  color: @light-primary-color;
  padding: 0px 5px;
}

.ant-popover-inner-content span i {
  color: @text-dark !important;
  display: inline-block;
}

.profile-service-icon-container:not(:first-of-type),
.ant-popover-inner-content span:not(:first-of-type) {
  margin-left: 8px !important;
}

.ant-switch {
  background-color: #bbbbbb;
  margin-bottom: 0px !important;
}

.ant-switch-checked {
  background-color: @primary-color !important;
}