@import '../../styles/variables.less';

#create-new-register-modal-component {
  .ant-select-selection-item {
    color: @text-dark;
  }

  .ant-select-selection-placeholder {
    opacity: 0.8;
    color: @text-color;
  }

  .ant-form-item-label {
    > label {
      display: flex;
      width: 100%;
    }

    .custom-label-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      a:hover {
        border-bottom: 1px solid @primary-color;
      }
    }
  }
}

.ant-select-item-group {
  font-weight: bold;
}
