@import '../../../../../../styles/variables.less';

.row-name {
  margin-left: 5px;
  margin-bottom: 15px;

  .sentence-name {
    color: @text-dark;
    font-size: 15px;
  }
}

.row-fields {
  margin-left: 5px;
  margin-bottom: 3px;
  .sentence-fields {
    color: @text-dark;
  }
}
