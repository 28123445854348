#to-print-trust {
  .ant-collapse {
    border: none;
  }

  #profile-widget-collapse {
    border-radius: none;

    .ant-collapse-header {
      padding: 15px 10px;

      #profile-widget-title {
        font-size: 14px;

        i {
          margin-right: 8px;
          font-size: 15px;
        }
      }

      #profile-widget-subtitle {
        margin-top: 8px;
        margin-bottom: 0px;

        font-size: 13px;
      }
    }

    .ant-collapse-content {
      .compliance-group-title {
        padding: 5px 10px;

        span {
          font-size: 10px;
        }
      }

      .personal-data-info {
        padding: 10px;

        #trust-item {
          .title {
            padding-right: 5px;
            font-size: 13px !important;

            .status-div {
              font-size: 12px !important;

              .div-dot {
                width: 6px;
                height: 6px;
                min-width: 6px;
              }
            }
          }

          .squared-tag {
            padding: 0px 5px;
            font-size: 10px;
          }

          .trust-items-list {
            margin: 5px 0px !important;

            li {
              font-size: 12px;
            }

            li + li {
              margin-top: 4px !important;
            }
          }

          button {
            display: none;
          }
        }
      }

      #empty-message-component {
        .ant-empty {
          margin: 10px;
          padding: 10px 0px;

          .ant-empty-image {
            height: 35px;
          }

          .ant-empty-description {
            font-size: 13px;
          }
        }
      }

      footer {
        display: none;
      }
    }
  }
}
